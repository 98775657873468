import { media } from '../../themes';
import styled from 'styled-components';

export const MobileOnly = styled.div`
  display: block;
  ${media.large`
    display: none;
  `};
`;

export const DesktopOnly = styled.div`
  display: none;
  ${media.large`
    display: block;
  `};
`;
