import React, { useState, FunctionComponent } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { scrollTo } from '../../utils';
import styled from 'styled-components';
import { Submenu, SubmenuProps } from './Submenu';

export interface ScrollSubmenuProps extends SubmenuProps {
  children: React.ReactElement<ScrollSectionProps>[];
  visibilityOffsetBottom?: number;
  visibilityOffsetTop?: number;
  partialVisibility?: boolean;
}

export interface ScrollSectionProps {
  menuKey: string;
  children: React.ReactChild;
}

export const ScrollSection: FunctionComponent<ScrollSectionProps> = ({ children }) => {
  return <>{children}</>;
};

interface AllVisibleContainers {
  key: string;
  idx: number;
}

let allVisibleContainers: AllVisibleContainers[] = [];

export const ScrollSubmenu: FunctionComponent<ScrollSubmenuProps> = ({
  menu,
  children,
  visibilityOffsetBottom = 0,
  visibilityOffsetTop = 0,
  partialVisibility = false,
  ...otherProps
}) => {
  const [activeKey, setActiveKey] = useState<string>();

  const visibilityOptions = {
    scrollDelay: 200,
    offset: { bottom: visibilityOffsetBottom, top: visibilityOffsetTop },
    scrollCheck: true,
    partialVisibility,
  };

  const updateVisibleContainer = (isVisible: boolean, key: string, idx: number) => {
    /**
     * Sometimes we can have a case where two containers are visible at the same time.
     * This function solves this issue by storing the containers that are visible at the
     * same moment, and picking up the first one(the lowest index) to set as active.
     *
     * A cleanup is also made as soon as a container is not visible anymore to avoid creating a huge array.
     */
    if (isVisible) {
      allVisibleContainers.push({ key, idx });

      const lowestIndex = Math.min(
        ...allVisibleContainers.map(visibleContainer => visibleContainer.idx)
      );
      const keyToBeActivated = allVisibleContainers.filter(
        (visibleContainer: any) => visibleContainer.idx === lowestIndex
      )[0].key;

      setActiveKey(keyToBeActivated as string);
    } else {
      allVisibleContainers = allVisibleContainers.filter(
        (visibleContainer: any) => visibleContainer.idx !== idx
      );

      if (allVisibleContainers.length > 0) {
        const lowestIndex = Math.min(
          ...allVisibleContainers.map((visibleContainer: any) => visibleContainer.idx)
        );
        const keyToBeActivated = allVisibleContainers.filter(
          (visibleContainer: any) => visibleContainer.idx === lowestIndex
        )[0].key;

        setActiveKey(keyToBeActivated as string);
      }
    }
  };

  return (
    <Container>
      <Submenu
        menu={menu}
        {...otherProps}
        activeKey={activeKey}
        onClick={key => {
          scrollTo(key as string, 'start', true, 140);
        }}
      />
      {React.Children.map(children, (section, idx) => {
        return React.isValidElement(section) && section.type === ScrollSection ? (
          <>
            <VisibilitySensor
              {...visibilityOptions}
              onChange={isVisible => {
                updateVisibleContainer(isVisible, section.props.menuKey as string, idx);
              }}
            >
              <Anchor id={section.props.menuKey as string}>{section}</Anchor>
            </VisibilitySensor>
          </>
        ) : (
          section
        );
      })}
    </Container>
  );
};

const Anchor = styled.div``;

const Container = styled.div`
  width: 100%;
`;
