import React, {
  useEffect,
  useCallback,
  useMemo,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import { kebabCase } from 'lodash';

import { CloseButton } from '../newest-modal/components/CloseButton/CloseButton';
import { Overlay } from '../newest-modal/components/Overlay/Overlay';
import { useModalContext } from './hooks/useModalContext';
import { ModalProps } from './Modal.model';
import { Modal, Content, ModalWrapper } from './Modal.style';
import {
  MODAL_PORTAL,
  MODAL_OVERLAY,
  MODAL_BODY,
  MODAL_CONTENT,
  MODAL_CLOSE_BUTTON,
} from './Modal.const';

import { Portal } from '../portal';

export const NewModal = ({
  align,
  children,
  dataCy,
  disableCloseOnClickOutside = false,
  hideCloseButton = false,
  modalId,
  onClose = () => {},
}: ModalProps) => {
  const { currentModalId, passProps, hideModal } = useModalContext();
  const modalPortalId = `${modalId}-${MODAL_PORTAL}`;

  const visible = useMemo(
    () => kebabCase(modalId) === kebabCase(currentModalId),
    [modalId, currentModalId]
  );

  useEffect(() => {
    if (modalId && visible && window) {
      document?.getElementById(modalPortalId)?.scrollTo(0, 0);
    }
  }, [modalId, visible, modalPortalId]);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  const onCloseModal = useCallback(() => {
    onClose?.();
    hideModal();
  }, [onClose, hideModal]);

  const childrenWithProps = useMemo(
    () =>
      Children.map(children, child => {
        if (!currentModalId || !visible) {
          return null;
        }
        if (isValidElement(child)) {
          return cloneElement(child, { ...passProps });
        }
        return child;
      }),
    [currentModalId, passProps, children, visible]
  );

  const overlayDataCy = `${dataCy || modalId}-${MODAL_OVERLAY}`;
  const modalDataCy = `${dataCy || modalId}-${MODAL_BODY}`;
  const contentDataCy = `${dataCy || modalId}-${MODAL_CONTENT}`;
  const closeButtonDataCy = `${dataCy || modalId}-${MODAL_CLOSE_BUTTON}`;

  return (
    <Portal portalId={modalPortalId}>
      <ModalWrapper id={modalId || 'global-modal'} visible={visible} align={align}>
        <Overlay
          data-cy={overlayDataCy}
          onClick={disableCloseOnClickOutside ? undefined : onCloseModal}
        />
        <Modal data-cy={modalDataCy}>
          <Content data-cy={contentDataCy} className="modal-content">
            {childrenWithProps}
            {hideCloseButton ? null : (
              <CloseButton data-cy={closeButtonDataCy} onClick={onCloseModal} />
            )}
          </Content>
        </Modal>
      </ModalWrapper>
    </Portal>
  );
};
