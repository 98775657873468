import React, { useEffect, useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Submenu, SubmenuProps } from './Submenu';

export interface TabsSubmenuProps extends SubmenuProps {
  children: React.ReactElement<TabsSectionProps>[];
  activeKey: string;
  onChange?: (key: string) => void;
}

export interface TabsSectionProps {
  menuKey: string;
  children: React.ReactChild;
}

export const TabsSection: FunctionComponent<TabsSectionProps> = ({ children }) => {
  return <>{children}</>;
};

export const TabsSubmenu: FunctionComponent<TabsSubmenuProps> = ({
  menu,
  children,
  activeKey,
  onChange,
  ...otherProps
}) => {
  const [internalActiveKey, setInternalActiveKey] = useState<string>(activeKey || menu[0].key);

  useEffect(() => {
    setInternalActiveKey(activeKey);
  }, [setInternalActiveKey, activeKey]);

  return (
    <Container>
      <Submenu
        menu={menu}
        {...otherProps}
        activeKey={internalActiveKey}
        onClick={key => {
          setInternalActiveKey(key as string);
          onChange && onChange(key as string);
        }}
      />
      {React.Children.map(children, section => {
        return React.isValidElement(section)
          ? section.props.menuKey === internalActiveKey && <TabContainer>{section}</TabContainer>
          : { section };
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const TabContainer = styled.div``;
