import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { media } from '../../themes/media';
import {
  Heading,
  Paragraph,
  ParagraphBase,
  ParagraphRegularText,
  ParagraphProps,
} from '../../content/text';
import { PageSection } from '../../layout';
import { SectionContainer } from '../../layout/section-container';

interface Alignment {
  contentAlignment?: 'left' | 'center';
}

interface HeroBannerProps extends Alignment {
  title?: string | React.ReactNode;
  slogan?: React.ReactNode;
  tag?: string;
  tagElement?: React.ElementType;
  bannerSize?: 'extralarge' | 'large' | 'normal' | 'small';
  inverse?: boolean;
  video?: React.ReactNode;
  headingTagType?: 1 | 2 | 3 | 4 | 5;
  headingType?: 1 | 2 | 3 | 4 | 5;
  image?: React.ReactNode;
  sideContent?: React.ReactNode;
  hideMediaOnMobile?: boolean;
  reverse?: boolean;
  className?: string;
  testId?: string;
}

export const HeroBanner: FunctionComponent<HeroBannerProps> = ({
  title,
  bannerSize = 'normal',
  slogan,
  tag,
  tagElement = 'h2',
  headingTagType,
  headingType,
  children,
  inverse,
  contentAlignment = 'left',
  video,
  image,
  sideContent,
  hideMediaOnMobile,
  reverse,
  className,
  testId,
}) => {
  return (
    <OuterContainer className={className} slogan={slogan} data-cy={testId}>
      <PageSection>
        <SSectionContainer>
          <InnerContainer contentAlignment={contentAlignment}>
            <Container reverse={reverse} contentAlignment={contentAlignment}>
              <Middle
                bannerSize={bannerSize}
                contentAlignment={contentAlignment}
                className="hero-banner-content-wrapper"
              >
                <ContentHolder
                  className="hero-banner-content-holder"
                  contentAlignment={contentAlignment}
                >
                  {tag && (
                    <Tag
                      className="hero-banner-content-tag"
                      inverse={inverse}
                      semiBold
                      as={tagElement}
                    >
                      {tag}
                    </Tag>
                  )}
                  {title && (
                    <Headline
                      tagType={headingTagType}
                      contentAlignment={contentAlignment}
                      // eslint-disable-next-line no-nested-ternary
                      type={headingType ? headingType : bannerSize === 'small' ? 3 : 1}
                      inverse={inverse}
                      data-cy={`${testId}-title`}
                    >
                      {title}
                    </Headline>
                  )}

                  <Content inverse={inverse}>{children}</Content>
                </ContentHolder>
              </Middle>
              {sideContent && (
                <SideContent
                  contentAlignment={contentAlignment}
                  className="hero-banner-side-content"
                >
                  {sideContent}
                </SideContent>
              )}
              {video ? <Video hideMediaOnMobile={hideMediaOnMobile}>{video}</Video> : ''}
              {image ? (
                <Image hideMediaOnMobile={hideMediaOnMobile} className="hero-banner-image">
                  {image}
                </Image>
              ) : (
                ''
              )}
            </Container>
          </InnerContainer>
          {slogan && (
            <Slogan>
              <SloganText>{slogan}</SloganText>
            </Slogan>
          )}
        </SSectionContainer>
      </PageSection>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<HeroBannerProps>`
  ${props => (props.slogan ? `margin: 0 0 70px 0` : ``)};
  ${media.desktop<HeroBannerProps>`
    ${props => (props.slogan ? `margin: 0 0 50px 0` : ``)};
  `}
`;

const InnerContainer = styled.div<Alignment>`
  padding-top: 1px;
  box-sizing: border-box;
  text-align: ${props => props.contentAlignment};
`;

const SSectionContainer = styled(SectionContainer)`
  padding-top: 0;
  padding-bottom: 0;
  ${media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

const Container = styled.div<HeroBannerProps>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
  ${media.desktop<HeroBannerProps>`
    justify-content: space-between;
    flex-direction: ${props => (props.contentAlignment === 'center' ? `column` : `row`)};
  `}

  b {
    font-weight: inherit;
    text-decoration: none;
    display: inline;
    background-image: linear-gradient(
      to top,
      transparent 20%,
      ${props => rgba(props.theme.colors.accent_strong, 0.15)} 21%
    );
    background-position: 0 0.5em;
    background-repeat: no-repeat;
    background-size: 100% 20px;
  }
`;

const SideContent = styled.div<HeroBannerProps>`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.desktop<Alignment>`
    ${props => (props.contentAlignment === 'center' ? `margin: auto;` : ``)};
  `}

  ${media.large`
    width: 45%;
  `}
`;

const Middle = styled.div<HeroBannerProps>`
  width: 100%;
  display: flex;

  ${media.desktop<Alignment>`
    ${props => (props.contentAlignment === 'center' ? `margin: auto;` : ``)};
  `}

  ${props => {
    switch (props.bannerSize) {
      case 'extralarge':
        return css`
          min-height: 640px;
          ${media.desktop`
            min-height: 560px;
          `}
        `;
      case 'large':
        return css`
          min-height: 480px;
          ${media.desktop`
            min-height: 400px;
          `}
        `;
      case 'small':
        return css`
          min-height: 320px;
        `;
      default:
        return css`
          min-height: 360px;
        `;
    }
  }}

  ${media.desktop`
    width: 90%;
  `}

  ${media.large`
    width: 55%;
  `}
`;

const ContentHolder = styled.div<Alignment>`
  width: 100%;
  margin: auto 0;
  z-index: 1;
  ${media.tablet`
    width: 100%;
  `}
  ${media.desktop<Alignment>`
    ${props => (props.contentAlignment === 'center' ? `margin: auto;` : ``)};
  `}
  padding: 20px 0;
`;

const Tag = styled.h2<ParagraphProps>`
  padding: 0 0 20px 0;
  ${ParagraphBase}
  ${ParagraphRegularText}
`;

const Headline = styled(Heading)<Alignment>`
  ${props =>
    props.contentAlignment === 'center' ? `margin: 0 0 20px 0` : `margin: 0 20px 20px 0`};

  ${media.desktop<Alignment>`
    ${props => (props.contentAlignment === 'center' ? `margin: 0 0 20px 0` : `margin: 0 0 20px 0`)};
    `}
`;

const Content = styled.div<ParagraphProps>`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 20px;
  }
  ${ParagraphBase}
  ${ParagraphRegularText}
  margin: 0 0 20px 0;
  .search {
    margin-top: 20px;
    display: flex;
    width: 100%;
    ${media.desktop`
      width: 80%;
    `}
  }
`;

const Slogan = styled.div`
  background: ${props => props.theme.colors.background_page};
  position: absolute;
  text-align: center;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 10px 20px;
`;

const SloganText = styled(Paragraph)`
  line-height: 1.5;
  vertical-align: middle;
  display: inline-block;
`;

const Video = styled.div<Partial<HeroBannerProps>>`
  padding: 20px 20px 40px 20px;
  video {
    width: 100%;
  }
  ${props => (props.hideMediaOnMobile ? 'display:none;' : '')}
  ${media.desktop`
    padding: 40px 0;
    display: block;
  `};
`;

const Image = styled.div<Partial<HeroBannerProps>>`
  padding: 20px 20px 40px 20px;
  ${props => (props.hideMediaOnMobile ? 'display:none;' : '')}
  ${media.desktop`
    padding: 40px 0;
    display: block;
  `}
`;
