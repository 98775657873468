import { InvestorAccountActionCode, InvestorAccountStepVerificationStatus } from '../../enums';

import { InvestorAccountStatus } from './investor-account-status.model';

const investorAccount1 = {
  id: 349913,
  name: '20193 Wealth Tr Cody Wisozk IRA account',
  type: 'TRADITIONAL_IRA',
  typeDescription: 'Traditional YSIRA',
  status: {
    userId: 4,
    id: 349913,
  },
};

const investorAccount2 = {
  id: 1,
  name: 'Delpha Towne',
  type: 'INDIVIDUAL',
  typeDescription: 'Individual',
  status: {
    userId: 4,
    id: 1,
  },
};

const investorAccount3 = {
  id: 346822,
  name: 'Entity Tester',
  type: 'LLC',
  typeDescription: 'Limited Liability Company',
  status: {
    userId: 4,
    id: 346822,
  },
};

export const investorAccountStatus: InvestorAccountStatus = {
  userId: 365647,
  investorAccountId: 303150,
  investorAccountStatusId: null,
  investorAccountType: 'INDV',
  investorAccountEntityType: null,
  readyToInvest: false,
  setupComplete: false,
  accreditedSignUp: true,
  identity: {
    status: InvestorAccountStepVerificationStatus.MISSING,
    statusDisplay: 'Confirmed',
    pendingActions: [],
    message: null,
    entityType: null,
  },
  accreditation: {
    status: InvestorAccountStepVerificationStatus.MISSING,
    statusDisplay: 'Unverified',
    pendingActions: [],
    message: null,
    entityType: null,
  },
  bankAccounts: {
    status: InvestorAccountStepVerificationStatus.MISSING,
    statusDisplay: 'None',
    pendingActions: [],
    message: null,
    entityType: null,
  },
  lastUpdatedAt: 20,
  investorAccountAction: {
    investorAccountId: null,
    code: InvestorAccountActionCode.LINK_BANK_ACCOUNT,
    status: null,
    nextStep: 'Link a Bank Account',
    priority: 100,
  },
  id: 303150,
  nextStep: 'Link a Bank Account',
};

export const allInvestorAccountStatusApiResponse = {
  success: true,
  investorAccounts: [investorAccount1, investorAccount2, investorAccount3],
};

export const allInvestorAccountStatusTransformResult = {
  [investorAccount1.id]: {
    ...investorAccount1.status,
    id: investorAccount1.id,
    type: investorAccount1.type,
    typeDescription: investorAccount1.typeDescription,
  },
  [investorAccount2.id]: {
    ...investorAccount2.status,
    id: investorAccount2.id,
    type: investorAccount2.type,
    typeDescription: investorAccount2.typeDescription,
  },
  [investorAccount3.id]: {
    ...investorAccount3.status,
    id: investorAccount3.id,
    type: investorAccount3.type,
    typeDescription: investorAccount3.typeDescription,
  },
};
