import { restApi, Tags } from '../common';
import {
  GetInvestorBeneficialOwnersParams,
  GetInvestorBeneficialOwnersResponse,
  CreateInvestorBeneficialOwnerRequest,
  CreateInvestorBeneficialOwnerWithTokenRequest,
  CreateInvestorBeneficialOwnerResponse,
  UpdateInvestorBeneficialOwnerRequest,
  UpdateInvestorBeneficialOwnerResponse,
} from './investor-beneficial-owners.model';
import { HttpMethods } from '../../enums/http';

export const investorBeneficialOwnersApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.InvestorBeneficialOwners, Tags.InvestorAccountStatus],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getInvestorBeneficialOwners = builder.query<
        GetInvestorBeneficialOwnersResponse,
        GetInvestorBeneficialOwnersParams
      >({
        query: ({ investorEntityId }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/beneficial-owners`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.InvestorBeneficialOwners],
      });

      const createInvestorBeneficialOwner = builder.mutation<
        CreateInvestorBeneficialOwnerResponse,
        CreateInvestorBeneficialOwnerRequest
      >({
        query: ({ investorEntityId, ...data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/beneficial-owners`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.InvestorBeneficialOwners, Tags.InvestorAccountStatus],
      });

      const updateInvestorBeneficialOwner = builder.mutation<
        UpdateInvestorBeneficialOwnerResponse,
        UpdateInvestorBeneficialOwnerRequest
      >({
        query: ({ investorEntityId, beneficialOwnerId, ...data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/beneficial-owners/${beneficialOwnerId}`,
          method: HttpMethods.Put,
          data,
        }),
        invalidatesTags: [Tags.InvestorBeneficialOwners, Tags.InvestorAccountStatus],
      });

      const verifyBeneficialOwnerInviteToken = builder.query<
        CreateInvestorBeneficialOwnerResponse,
        CreateInvestorBeneficialOwnerWithTokenRequest
      >({
        query: ({ inviteToken }) => ({
          url: `/a/api/investor-entity/beneficial-owners/validate-token/${inviteToken}`,
        }),
      });

      const createInvestorBeneficialOwnerWithToken = builder.mutation<
        CreateInvestorBeneficialOwnerResponse,
        CreateInvestorBeneficialOwnerWithTokenRequest
      >({
        query: ({ inviteToken, ...data }) => ({
          url: `/a/api/investor-entity/beneficial-owners/submit/${inviteToken}`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.InvestorBeneficialOwners],
      });

      return {
        getInvestorBeneficialOwners,
        createInvestorBeneficialOwner,
        updateInvestorBeneficialOwner,
        verifyBeneficialOwnerInviteToken,
        createInvestorBeneficialOwnerWithToken,
      };
    },
  });
