import styled, { css } from 'styled-components';

import { media } from '../../../../themes';
import { ModalAlignment, ModalWrapperyStyleProps } from './Modal.model';

export const Center = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  z-index: 1000;

  ${media.phoneLandscape`
    padding-right: 50px;
  `}
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  min-height: 20vh;

  ${media.desktop`
    min-width: 375px;
  `}

  ${media.phoneLandscape`
    display: flex;
    justify-content: flex-end;
    border-radius: 8px;
    width: fit-content;
  `}
`;

export const ModalWrapper = styled.div<ModalWrapperyStyleProps>`
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease;
  z-index: 999;

  ${({ align }) =>
    align === ModalAlignment.centre ? Center : media.phoneLandscape`padding: 105px 0;`};
`;
