import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { SignupStepFlow } from './signup-quiz.model';

export const getSignupStepFlow = (): SignupStepFlow => {
  return {
    [SignupQuizSteps.EXPERIENCE_ALTS]: {
      nextStep: SignupQuizSteps.INVESTING_REASONS,
    },
    [SignupQuizSteps.INVESTING_REASONS]: {
      prevStep: SignupQuizSteps.EXPERIENCE_ALTS,
      nextStep: SignupQuizSteps.INTERESTED_ASSET_CLASSES,
    },
    [SignupQuizSteps.INTERESTED_ASSET_CLASSES]: {
      prevStep: SignupQuizSteps.INVESTING_REASONS,
      nextStep: SignupQuizSteps.PORTFOLIO,
    },
    [SignupQuizSteps.PORTFOLIO]: {
      prevStep: SignupQuizSteps.INTERESTED_ASSET_CLASSES,
      nextStep: SignupQuizSteps.USUAL_INVESTING_WAY,
    },
    [SignupQuizSteps.USUAL_INVESTING_WAY]: {
      prevStep: SignupQuizSteps.PORTFOLIO,
      redirect: SignupQuizSteps.SIGNUP,
    },
  };
};

export const isValidSignupStep = (step: string) => {
  const validList = Object.keys(getSignupStepFlow());

  return validList.includes(step);
};

export const getSignupStepCount = (step: string) => {
  const flow = getSignupStepFlow();
  const stepOrder = Object.keys(flow);
  const currentStep = stepOrder.findIndex(a => a === step) + 1;
  let maxStep = stepOrder.reduce((previousValue, step) => {
    // @ts-ignore
    return flow[step]?.informative ? previousValue : ++previousValue;
  }, 0);

  return { currentStep, maxStep };
};
