import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../themes/media';
import { themeAB } from '../../themes/themeAB';
import { Heading, Paragraph } from '../../content/text';
import { Button } from '../../content/button';
import { EmptyStateProps, mapToTestId } from './EmptyState.model';

export const EmptyState: FunctionComponent<EmptyStateProps> = ({
  title,
  subtitle,
  buttonText,
  onClick,
  trackingId,
  testId,
  dataCy,
}) => {
  const testIds = mapToTestId(dataCy || testId);
  return (
    <EmptyStateWrapper {...testIds.base.attr}>
      <Heading type={4} {...testIds.title.attr}>
        {title}
      </Heading>
      <ParagraphWrapper>
        <Paragraph small secondary {...testIds.subtitle.attr}>
          {subtitle}
        </Paragraph>
      </ParagraphWrapper>

      <ButtonContainer>
        <StyledButton trackingId={trackingId} onClick={onClick}>
          {buttonText}
        </StyledButton>
      </ButtonContainer>
    </EmptyStateWrapper>
  );
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: left;
  ${media.desktop`
    padding: 40px;
  `};
  padding: 20px;
  ${props =>
    themeAB(
      props.theme,
      css`
        background-color: ${props.theme.colors.white};
      `,
      css`
        background-color: ${props.theme.colors.background_card};
      `
    )}
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
  ${media.tablet`
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  `};
`;
const ParagraphWrapper = styled.div`
  margin-top: 10px;
`;
const ButtonContainer = styled.div``;
