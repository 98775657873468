import { HttpMethods } from '../../enums';
import { restApi } from '../common';
import {
  CreateFootprintTokenResponse,
  FootprintValidationParams,
  CreateFootprintTokenParams,
  FootprintValidationResponse,
  RefreshFootprintKYBVerificationStatusParams,
} from './identity.model';

const BASE_URL = '/api/identity';

export const identityApi = restApi.injectEndpoints({
  endpoints: builder => {
    const validateFootprintToken = builder.mutation<
      FootprintValidationResponse,
      FootprintValidationParams
    >({
      query: ({ investorEntityId, token }) => ({
        url: `${BASE_URL}/public/providers/footprint/validate`,
        method: 'POST',
        data: {
          investorEntityId,
          token,
        },
      }),
    });

    const createFootprintToken = builder.mutation<
      CreateFootprintTokenResponse,
      CreateFootprintTokenParams
    >({
      query: ({ investorEntityId }) => ({
        url: `${BASE_URL}/public/investor-entity/${investorEntityId}/footprint/session`,
        method: HttpMethods.Post,
      }),
    });

    const refreshFootprintKYBVerificationStatus = builder.mutation<
      {},
      RefreshFootprintKYBVerificationStatusParams
    >({
      query: ({ investorEntityId }) => ({
        url: `${BASE_URL}/public/investor-entity/${investorEntityId}/footprint/refresh-status`,
        method: HttpMethods.Post,
      }),
    });

    return {
      validateFootprintToken,
      createFootprintToken,
      refreshFootprintKYBVerificationStatus,
    };
  },
});
