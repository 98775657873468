import { Components, CssVarsTheme } from '@mui/material';

export const MuiLoadingButton: Components<CssVarsTheme>['MuiLoadingButton'] = {
  defaultProps: {
    variant: 'contained',
    size: 'large',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      textTransform: 'none',
      borderRadius: '15rem',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      padding: '10px',
      ...(ownerState.color === 'primary' && {
        color: theme.palette.Button?.contained?.primary?.color,
        '&:hover': {
          backgroundColor: theme.palette.Button?.contained?.primary?.hover,
        },
        backgroundColor: theme.palette.Button?.contained?.primary?.background,
      }),
      ...(ownerState.size === 'small' && {
        fontSize: '14px',
      }),
    }),
  },
};
