import React, { useMemo } from 'react';

import { Label } from '../../content';
import { NavigationItem, NavigationItemProps } from '../../types';
import { getNavItemSubMenu } from '../common';
import { HeaderSubMenuItem } from './HeaderItemLoggedOut';
import { SubMenuSection, SubMenuSectionItems } from './SubmenuSection.styles';

export interface SubmenuSectionProps {
  inverse?: boolean;
  item: NavigationItem;
  navProps: NavigationItemProps;
}

export const SubmenuSection: React.FunctionComponent<SubmenuSectionProps> = ({
  inverse,
  item,
  navProps,
}) => {
  const items = useMemo(() => {
    return getNavItemSubMenu(item, navProps) || [];
  }, [item]);

  return (
    <SubMenuSection data-cy={`submenu-section-${item.key}`}>
      <Label inverse={inverse} small>
        {item.title}
      </Label>
      <SubMenuSectionItems grid={items.length > 3}>
        {items.map(subItem => (
          <HeaderSubMenuItem key={`subItem-${subItem.key}`} menuItem={subItem} inverse={inverse} />
        ))}
      </SubMenuSectionItems>
    </SubMenuSection>
  );
};
