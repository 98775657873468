import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading } from '../../content/text';
import { PageSection } from '../../layout';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import CloseIconWhite from '../../assets/graphics/icons/close_white.svg';

interface Image {
  imageBorder?: boolean;
}

interface ImagePosition {
  imagePosition?: 'left' | 'right';
  small?: boolean;
  removePaddingSide?: boolean;
  removeImageMargin?: boolean;
  reverseMobile?: boolean;
}

interface SidePhotoContentProps extends Image, ImagePosition {
  title?: string;
  inverse?: boolean;
  mainImage?: React.ReactNode;
  splashImage?: React.ReactNode;
  removeFooterSpacing?: boolean;
  removeHeaderSpacing?: boolean;
  removePaddingSide?: boolean;
  removeImageMargin?: boolean;
  backgroundImage?: string | boolean;
  small?: boolean;
  dismiss?: () => void;
}

export const SidePhotoContent: FunctionComponent<SidePhotoContentProps> = props => {
  const {
    title,
    inverse,
    children,
    mainImage,
    splashImage,
    imagePosition,
    reverseMobile,
    imageBorder,
    removeFooterSpacing,
    removeHeaderSpacing,
    removePaddingSide,
    removeImageMargin,
    backgroundImage,
    small,
    dismiss,
  } = props;

  return (
    <PageSection>
      <Container
        reverseMobile={reverseMobile}
        imagePosition={imagePosition}
        removeFooterSpacing={removeFooterSpacing}
        removeHeaderSpacing={removeHeaderSpacing}
        removePaddingSide={removePaddingSide}
        backgroundImage={backgroundImage}
      >
        <TextContainer
          removePaddingSide={removePaddingSide}
          small={small}
          imagePosition={imagePosition}
        >
          <Headline type={small ? 4 : 3} inverse={inverse}>
            {title}
          </Headline>
          <Content>{children}</Content>
        </TextContainer>
        <RightContainer removeImageMargin={removeImageMargin} small={small}>
          <ImageContainer>
            {!backgroundImage && (
              <>
                <MainImage imageBorder={imageBorder}>{mainImage}</MainImage>
                {splashImage && (
                  <SplashImage imagePosition={imagePosition}>{splashImage}</SplashImage>
                )}
              </>
            )}
          </ImageContainer>
        </RightContainer>
        {dismiss && <Close src={inverse ? CloseIconWhite : CloseIcon} onClick={dismiss} />}
      </Container>
    </PageSection>
  );
};

const Container = styled.div<SidePhotoContentProps>`
  position: relative;
  margin: 20px 0;
  ${props => (props.removeFooterSpacing ? 'margin-bottom: 0;' : '')}
  ${props => (props.removeHeaderSpacing ? 'margin-top: 0;' : '')}
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.reverseMobile ? 'column-reverse' : 'column')};
  ${media.desktop<SidePhotoContentProps>`
    ${props => props.removePaddingSide && 'padding: 0 20px;'}
    margin: 40px auto;
    ${props => (props.removeFooterSpacing ? 'margin-bottom: 0;' : '')}
    ${props => (props.removeHeaderSpacing ? 'margin-top: 0;' : '')}
    flex-direction: ${props => (props.imagePosition === 'left' ? 'row-reverse' : 'row')};
  `}
  ${media.larger<SidePhotoContentProps>`
    ${props => props.removePaddingSide && 'max-width: 1180px'}
  `}
  ${props =>
    props.backgroundImage &&
    `
    background-image: url(${props.backgroundImage});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 300px;
  `}
`;

const TextContainer = styled.div<ImagePosition>`
  width: 100%;
  ${media.desktop<SidePhotoContentProps>`
    width: 50%;
    padding: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.small || props.removePaddingSide
        ? '0'
        : props.imagePosition === 'left'
        ? '0 110px 0 40px'
        : '0 0 0 110px'};
  `}
`;

const Headline = styled(Heading)`
  margin: 20px;
  ${media.desktop`
    margin: 20px 0 20px 20px;
  `}
`;

const Content = styled.div`
  margin: 0 20px 20px 20px;
  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RightContainer = styled.div`
  text-align: center;
  width: 100%;
  ${media.desktop<SidePhotoContentProps>`
    margin: ${props => (props.removeImageMargin ? '0' : '20px 0 0 0')};
    width: 50%;
    padding: 0 60px;
    ${props =>
      props.small &&
      `
        display: flex;
        align-items: flex-end;
        padding: 0 20px;
    `}
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  margin: 0 20px;
  ${media.desktop`
    margin: 0;
  `}
`;

const MainImage = styled.div<Image>`
  video {
    width: 100%;
  }
  img {
    width: 100%;
    ${props =>
      props.imageBorder ? `border-bottom: 10px solid ${props.theme.colors.accent_strong};` : ''};
  }
`;

const SplashImage = styled.div<ImagePosition>`
  width: 100%;
  margin-top: 20px;
  ${media.desktop<ImagePosition>`
      position: absolute;
      bottom: -30px;
      width: auto;
      ${props => (props.imagePosition === 'left' ? 'right: -60px;' : 'left: -60px;')}
  `}
`;

const Close = styled.img`
  cursor: pointer;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
`;
