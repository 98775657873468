import styled, { css } from 'styled-components';
import { media } from '../../../themes';

export const NAV_HEIGHT = 68;

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 998;
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.background_card};
  height: ${() => `${NAV_HEIGHT}px`};
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  transition: background 0.5s;

  &.transparent {
    background: rgba(255, 255, 255, 0);
    border-bottom: none;
  }
  box-sizing: border-box;
  ${media.large`
    height: 64px;
    padding: 0;
  `};
  ${media.print`
    display: none;
  `}
`;

export const Flex = styled.div`
  display: flex;
`;

export const LogoWrap = styled.div`
  display: flex;
  padding-left: 20px;

  a {
    display: flex;
  }

  img {
    width: 120px;
    min-height: 39px;
    ${media.large`
      width: 140px;
      height: 100%;
      min-height: 50px;
    `};
  }
`;

export const MenuContainer = styled.div<{ sideMenuOpen: boolean }>`
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  transition: opacity 0.5s;
  z-index: 997;
  right: ${props => (props.sideMenuOpen ? 0 : '-100%')};
  opacity: ${props => (props.sideMenuOpen ? 1 : 0)};
  ${media.large`
    position: static;
    opacity: 1;
    display: flex;
    align-self: center;
  `};
`;

export const Menu = styled.div<{ sideMenuOpen: boolean }>`
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 998;
  background: ${props => props.theme.colors.background_card};
  overflow-y: auto;
  right: ${props => (props.sideMenuOpen ? 0 : '-100%')};
  transition: right 0.5s;
  padding: ${props => props.theme.spacing.m};
  ${media.tablet`
    width: 320px;
    `};
  ${media.large`
    padding: 0 ${props => props.theme.spacing.m};
    position: static;
    bottom: 0;
    top: auto;
    width: auto;
    overflow-y: visible;
  `};
`;

export const MenuItemsContainer = styled.div`
  padding-bottom: 100px;
  ${media.large`
    flex-grow: 1;
    display: flex;
    padding: 0 20px 0 20px;
    align-items: baseline;
  `};
`;

export const HamburgerWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
  ${media.tablet`
  	padding-left: 20px;
  `};
  ${media.large`
    display: none;
  `};
`;

export const SignButtons = styled.div`
  display: flex;
  flex-direction: column;
  > a {
    flex: 1;
    display: inline-flex;
  }
  ${media.large`
    display: none;
  `};
`;

export const Backdrop = styled.div<{ visible: boolean }>`
  background: rgba(94, 132, 188, 0.2);
  position: fixed;
  top: 64px;
  width: 100vw;
  transition: height 0ms 400ms, opacity 400ms 0ms;
  opacity: 0;
  height: 0;
  overflow: hidden;

  ${props =>
    props.visible &&
    css`
      height: 100vh;
      opacity: 1;
      transition: height 0ms 0ms, opacity 600ms 0ms;
    `}
`;

export const MobileMenuFooter = styled.div`
  background: ${props => props.theme.colors.background_default};
  position: fixed;
  width: calc(100% - 50px);
  padding: 24px 0;
  bottom: 0;
  ${media.tablet`
    max-width: 270px;
  `};
`;

export const MobileMenuFooterHolder = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.s};
  margin-top: 20px;
`;

export const Separator = styled.hr`
  border-color: ${props => props.theme.colors.border_strong};
  opacity: 0.3;
  &:last-child {
    display: none;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const MobileLogoWrap = styled(LogoWrap)`
  padding-left: 0;
  ${media.tablet`
    .nav-logo { 
      display: none
    }
  `}
`;
