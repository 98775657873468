import styled from 'styled-components';

export const ExternalProviderIcon = styled.img<{ backgroundColor?: string }>`
  padding: 10px;
  width: 80px;
  height: 80px;
  box-shadow: ${props => props.theme.shadows.shadow_hover};
  border-radius: 10px;
  background-color: ${props => props.backgroundColor || props.theme.colors.background_inverse};
`;
