import React, { useMemo, FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlattenInterpolation, ThemeProps } from 'styled-components';

import { Button, ExternalLink, Heading } from '../../content';
import { media } from '../../themes/media';
import { PromoBanner } from '../promo-banner';
import { customThemeObj } from './config';

export interface FeatureBannerProps {
  title?: string;
  content?: React.ReactNode;
  ctaText?: string;
  type?: number;
  onActionClick?: () => void;
  onCloseClick?: () => void;
}

export const FeatureBanner: FunctionComponent<FeatureBannerProps> = ({
  title,
  content,
  ctaText,
  type = 1,
  onActionClick,
  onCloseClick,
}) => {
  const customTheme = customThemeObj[type];

  const renderButton = useMemo(
    () =>
      customTheme?.buttonType === 'link' ? (
        <ExternalLink inverse={customTheme?.isInverse} onClick={onActionClick}>
          {ctaText}
        </ExternalLink>
      ) : (
        <Button
          buttonType={customTheme?.buttonType}
          onClick={onActionClick}
          inverse={customTheme?.isInverse}
        >
          {ctaText}
        </Button>
      ),
    [type]
  );

  switch (type) {
    case 4:
      return (
        <PromoBanner
          image={customTheme?.image}
          imageFull
          imageFullBasis={60}
          title={title}
          content={content}
          cta={renderButton}
          inverse={customTheme?.isInverse}
          onCloseClick={onCloseClick}
        />
      );
    case 1:
    case 2:
    case 3:
    default:
      return (
        <Container customTheme={customTheme?.container}>
          <CloseButton
            customTheme={customTheme?.iconStyle}
            src={customTheme?.closeIcon}
            onClick={onCloseClick}
          />
          <TextWrapper customTheme={customTheme?.textWrapper}>
            {title && (
              <Title type={customTheme?.titleType || 3} inverse={customTheme?.isInverse}>
                {title}
              </Title>
            )}
            {content}
          </TextWrapper>
          <ButtonWrapper customTheme={customTheme?.buttonWrapper}>{renderButton}</ButtonWrapper>
        </Container>
      );
  }
};

const Container = styled.div<{
  customTheme?: FlattenInterpolation<ThemeProps<any>>;
}>`
  position: relative;
  display: flex;
  background: green;
  ${props => props.customTheme};
`;

const TextWrapper = styled.div<{
  customTheme?: FlattenInterpolation<ThemeProps<any>>;
}>`
  text-align: left;
  ${media.desktop`
    max-width: 70%;
  `}
  ${props => props.customTheme};
`;

const Title = styled(Heading)`
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div<{
  customTheme?: FlattenInterpolation<ThemeProps<any>>;
}>`
  ${props => props.customTheme};
`;

const CloseButton = styled.img<{
  customTheme?: FlattenInterpolation<ThemeProps<any>>;
}>`
  cursor: pointer;
  position: absolute;
  width: 15px;
  top: 15px;
  right: 15px;
  ${props => props.customTheme};
`;
