import React, { MouseEvent } from 'react';
import { useTheme } from 'styled-components';

import { genTestIds } from '@yieldstreet/tool-kit';
import { Theme } from '../../types';
import { ArrowIcon } from './assets/ArrowIcon';
import { SuccessIcon } from './assets/SuccessIcon';

export const { useTestIds } = genTestIds(['component']);

export enum ActionType {
  ActionRequired = 'ACTION_REQUIRED',
  ActionAvailable = 'ACTION_AVAILABLE',
  ActionNavigate = 'ACTION_NAVIGATE',
  ActionSuccess = 'ACTION_SUCCESS',
}

export interface ActionCellProps {
  testId: string;
  label: string;
  type: ActionType;
  showIcon?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
}

export interface ButtonProps {
  type: ActionType;
  theme: Theme;
}

export const ActionIcon = (type: ActionType) => {
  const theme = useTheme() as Theme;

  switch (type) {
    case ActionType.ActionRequired:
      return <ArrowIcon color={theme.colors.text_error_main} />;
    case ActionType.ActionSuccess:
      return <SuccessIcon color={theme.colors.text_success_main} />;
    case ActionType.ActionAvailable:
    default:
      return <ArrowIcon color={theme.colors.text_paragraph} />;
  }
};
