import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isString from 'lodash/isString';
import styled, { css } from 'styled-components';

import { Button } from '../../content/button';
import { Paragraph } from '../../content/text';
import { media } from '../../themes/media';
import { NavigationConfig, NavigationItem } from '../../types/components/navigation';
import {
  getNavItemSubMenu,
  getNavItemTitle,
  getNavItemVisible,
  NavigationInputProps,
  NavigationLink,
  useNavItemProps,
} from '../common';

import { getNavigationValue, getSizeVariation } from './common';
import { SubmenuSection } from './SubmenuSection';
import whiteArrow from './assets/whiteArrow.svg';
import whiteArrowMobile from './assets/whiteArrowMobile.svg';

interface HeaderItemProps extends NavigationInputProps {
  menuItem: NavigationItem;
  activeItem?: NavigationItem;
  activeSubItem?: NavigationItem;
  isMobile?: boolean;
  isSidebarItem?: boolean;
  desktopAlignment?: 'left' | 'right';
  notificationGroup?: Array<{ menu: string; subMenu: string; value: number }>;
  seperator?: boolean;
}

interface SubmenuItemProps {
  seperator?: boolean;
}

interface MenuItemProps
  extends Pick<HeaderItemProps, 'isSidebarItem' | 'desktopAlignment' | 'seperator'> {}

interface ArrowProps {
  isSidebarItem?: boolean;
  menuOpen?: boolean;
}

export const HeaderItem: React.FunctionComponent<HeaderItemProps> = props => {
  const {
    menuItem,
    activeItem,
    isMobile,
    isSidebarItem,
    desktopAlignment = 'left',
    notificationGroup,
  } = props;

  const menuLinkElement = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(e => {
    if (menuLinkElement.current && e.target) {
      if (!menuLinkElement.current.contains(e.target as Node)) {
        setMenuOpen(false);
      }
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
    }
  }, [onClickOutside]);

  const [menuOpen, setMenuOpen] = useState(false);

  const navProps = useNavItemProps(props);

  const itemSelected = menuItem.key === activeItem?.key;

  const itemInteractive = useMemo(() => {
    const renderTitle = getSizeVariation(
      getNavigationValue(menuItem.title, navProps, menuItem),
      isMobile
    );
    return isString(renderTitle);
  }, [isMobile, menuItem, navProps]);

  const itemTitle = useMemo(() => {
    return getNavItemTitle(menuItem, navProps);
  }, [menuItem, navProps]);

  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);

  const itemSubMenu = useMemo(() => {
    return getNavItemSubMenu(menuItem, navProps) as NavigationConfig;
  }, [menuItem, navProps]);

  if (!itemVisible) {
    return null;
  }

  return (
    <MenuItem
      key={menuItem.key}
      data-js-header-item-menu-item
      isSidebarItem={isSidebarItem}
      seperator={menuItem.seperator}
    >
      <Title
        onMouseEnter={() => !isMobile && itemSubMenu && menuItem.itemsDropdown && setMenuOpen(true)}
        onMouseLeave={() =>
          !isMobile && itemSubMenu && menuItem.itemsDropdown && setMenuOpen(false)
        }
        isSidebarItem={isSidebarItem}
      >
        <MenuLinkContainer
          ref={menuLinkElement}
          isSidebarItem={isSidebarItem}
          active={menuOpen}
          isButton={menuItem?.type === 'button'}
          selected={itemSelected}
          onClick={e => {
            if (isMobile && itemSubMenu) {
              setMenuOpen(!menuOpen);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          interactive={itemInteractive}
        >
          {itemSubMenu ? (
            <a
              href="#/"
              onClick={e => e.preventDefault()}
              data-cy={`header-${menuItem?.key}-mobile`}
            >
              {itemTitle}
            </a>
          ) : (
            <NavigationLink navItem={{ ...menuItem, id: `header-${menuItem?.key}` }} raw {...props}>
              {menuItem?.type === 'button' ? (
                <SButton small buttonType="secondary">
                  {itemTitle}
                </SButton>
              ) : (
                <>{itemTitle}</>
              )}
            </NavigationLink>
          )}
          {itemSubMenu && (isMobile || menuItem.itemsDropdown) && (
            <ArrowIcon isSidebarItem={isSidebarItem} menuOpen={menuOpen} />
          )}
          <NotificationIndicator>
            {notificationGroup?.map(group =>
              group.menu === menuItem.key && group.value ? <NotificationIcon /> : null
            )}
          </NotificationIndicator>
        </MenuLinkContainer>
        {menuOpen && itemSubMenu && (
          <SubMenu isSidebarItem={isSidebarItem} desktopAlignment={desktopAlignment}>
            {itemSubMenu.map(submenu => {
              if (submenu.items) {
                return (
                  <SubmenuSection
                    item={submenu}
                    key={`submenu-section-${submenu.key}`}
                    navProps={navProps}
                    inverse
                  />
                );
              }

              return (
                <HeaderSubMenuItem
                  {...props}
                  key={submenu.key}
                  menuItem={submenu}
                  notificationGroup={notificationGroup}
                  seperator={submenu.seperator}
                />
              );
            })}
          </SubMenu>
        )}
      </Title>
    </MenuItem>
  );
};

export const HeaderSubMenuItem: React.FunctionComponent<HeaderItemProps> = props => {
  const { menuItem, activeSubItem, notificationGroup, isSidebarItem, seperator } = props;

  const navProps = useNavItemProps(props);

  const itemSelected = menuItem.key === activeSubItem?.key;
  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);

  if (!itemVisible) {
    return null;
  }

  return (
    <SubMenuItem seperator={seperator} key={`submenu-${menuItem.key}`}>
      <MenuLinkContainer
        isSubItem
        isSidebarItem={isSidebarItem}
        selected={itemSelected}
        interactive
      >
        <NavigationLink navItem={{ ...menuItem, id: `header-${menuItem?.key}` }} raw {...props} />
        <NotificationIndicator>
          {notificationGroup?.map(group =>
            group.subMenu === menuItem.key && group.value ? (
              <NotificationIcon subMenu isBigNumber={group.value > 99}>
                <SParagraph>{group.value > 99 ? '99+' : group.value}</SParagraph>
              </NotificationIcon>
            ) : null
          )}
        </NotificationIndicator>
      </MenuLinkContainer>
    </SubMenuItem>
  );
};

const NotificationIndicator = styled.sup`
  display: inline-block;
`;

const MenuItem = styled.div<MenuItemProps>`
  margin: 0 20px;
  position: relative;
  ${props =>
    props.seperator
      ? css`
          &::after {
            content: ' ';
            position: absolute;
            border-right: 1px solid ${props.theme.colors.border_strong};
            right: -20px;
            margin: 30px 0;
            height: calc(100% - 60px);
          }
        `
      : ''}
  ${props => css`
    ${props.isSidebarItem &&
    css`
      border-top: 1px solid ${props.theme.colors.text_secondary_paragraph};
      &:last-child {
        border-bottom: 1px solid ${props.theme.colors.text_secondary_paragraph};
      }
    `}
  `}

  ${media.desktop`
    margin: 0 14px;
  `}

  ${media.larger`
    margin: 0 20px;
  `}
`;

export interface MenuLinkProps {
  selected?: boolean;
  active?: boolean;
  interactive?: boolean;
  isSubItem?: boolean;
  isSidebarItem?: boolean;
  isButton?: boolean;
}

export const MenuLinkContainer = styled.div<MenuLinkProps>`
  ${props =>
    !props.isButton
      ? css`
          cursor: pointer;
          a {
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
          }
          > a {
            font-family: ${props.theme.typography.secondary};
            padding: 2px 0;
            color: ${props.theme.colors.text_link_inverse};

            text-transform: none;
            display: inline-flex;
            align-items: center;
            font-size: 16px;
            height: inherit;
          }

          ${props.isSidebarItem
            ? css`
                display: block;
                height: auto;
              `
            : !props.isSubItem &&
              css`
                height: 66px;
                display: flex;
                align-items: center;
                ${media.large`
                  height: 74px;
                `};
              `};

          ${props.interactive &&
          css`
            > a {
              transition: background-size 0.2s ease-in-out;
              background-repeat: no-repeat;
              background-size: 225% 0;
              background-position: 100% 100%;
              background-image: linear-gradient(
                to right,
                ${props.theme.colors.active} 0%,
                ${props.theme.colors.active} 100%
              );
            }

            &:hover,
            &:active {
              > a {
                background-size: 225% ${!props.isSubItem && !props.isSidebarItem ? 5 : 2}px;
              }
            }

            ${(props.selected || props.active) &&
            css`
              > a {
                background-size: 225% ${!props.isSubItem && !props.isSidebarItem ? 5 : 2}px;
              }
            `}
          `}
        `
      : ''}
`;

const Title = styled.span<MenuItemProps>`
  font-size: 16px;
  padding: 0;
  display: inline-block;
  position: relative;
  width: 100%;

  ${props => `
    ${
      props.isSidebarItem &&
      css`
        padding: 16px 0;
        ${media.large`
          padding: 20px 0;
        `};
      `
    }
  `}

  ${media.large`
    white-space: nowrap;
  `};
`;

const ArrowIcon = styled.span<ArrowProps>`
  width: 14px;
  height: 8px;
  background-image: url(${whiteArrowMobile});
  ${media.large`
    background-image: url(${whiteArrow});
  `};
  background-repeat: no-repeat;
  display: inline-block;
  margin-left: 6px;
  margin-top: 3px;

  transition: transform 0.2s linear;
  ${props =>
    props.isSidebarItem && props.menuOpen
      ? `transform: rotate(180deg);`
      : `transform: rotate(0deg);`}

  ${props => `
    ${
      props.isSidebarItem &&
      `
      position: absolute;
      right: 10px;
      top: 26px;
    `
    }
  `}
`;

const SubMenu = styled.ul<MenuItemProps>`
  background: ${props => props.theme.colors.background_inverse};
  padding: 10px 0;
  display: inline-block;
  position: relative;
  margin: 0 0 0 0;
  width: 100%;
  li {
    padding: 10px 15px;
    position: relative;
    ${media.large`
      border: none;
    `};
    a {
      display: inline-block;
      white-space: nowrap;
    }
  }

  ${props => css`
    ${!props.isSidebarItem &&
    css`
      z-index: 999;
      position: absolute;
      width: unset;
      right: unset;
      top: unset;
      box-shadow: none;
      ${props.desktopAlignment === 'left' ? 'left: 0' : 'right: -20px'};
      top: 25px;
      min-width: 140px;
      margin-top: 30px;
      margin-left: -1px;
      &::before {
        content: '';
        width: 100%;
        top: -25px;
        height: 25px;
        position: absolute;
      }
    `};
  `};

  ${media.large<MenuItemProps>`
    ${props => css`
      ${!props.isSidebarItem &&
      css`
        top: 45px;
        &::before {
          height: 35px;
          top: -35px;
        }
      `}
    `}
  `};
`;

const SubMenuItem = styled.li<SubmenuItemProps>`
  list-style-type: none;
  &::before {
    ${media.large<SubmenuItemProps>`
      ${props =>
        props.seperator
          ? css`
              border-top: 1px solid ${props.theme.colors.border_extra_strong};
            `
          : ''};
    `};
    content: ' ';
    position: absolute;
    top: 0;
    padding: 0 10px;
    width: calc(100% - 50px);
  }
  ${media.large<SubmenuItemProps>`
    ${props =>
      props.seperator
        ? css`
            padding-top: 15px !important;
            margin-top: 5px;
          `
        : ''};
  `};

  a {
    display: block;
    list-style: none;
  }
`;

export interface NotificationIconProps {
  subMenu?: boolean;
  isBigNumber?: boolean;
}

const NotificationIcon = styled.div<NotificationIconProps>`
  width: ${props => (props.subMenu ? '18px' : '6px')};
  height: ${props => (props.subMenu ? '18px' : '6px')};
  padding: ${props => (props.subMenu ? '5px' : 0)};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.accent_strong};
  position: inherit;
  display: inline-block;
  margin-left: 6px;
  text-align: center;
  ${props =>
    props.isBigNumber &&
    `
    width: auto;
    border-radius: 40px;
  `}
`;

const SParagraph = styled(Paragraph)`
  vertical-align: super;
  font-size: 10px;
  line-height: 15px;
  margin-top: -3px;
`;

const SButton = styled(Button)`
  margin-top: 23px;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  background: ${props => props.theme.colors.input_button_nav_background};
  border: 1px solid ${props => props.theme.colors.input_button_nav_background};
  color: ${props => props.theme.colors.text_header_inverse} !important;
  &:hover {
    background: ${props => props.theme.colors.input_button_nav_background_hover};
  }
  &:active {
    background: ${props => props.theme.colors.input_button_nav_background_active};
    border: 1px solid ${props => props.theme.colors.border_extra_strong};
  }
`;
