import styled from 'styled-components';
import { media } from '../../themes';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: default;
  ${media.desktop`
    flex-direction: row-reverse;
  `}
`;

export const Count = styled.span`
  color: ${props => props.theme.colors.text_label_inverse};
  font-size: 12px;
  margin-right: ${props => props.theme.spacing.xxs};
  ${media.desktop`
    margin-left: ${props => props.theme.spacing.xxs};
  `}
`;

export const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Bar = styled.div<{ $complete: Boolean }>`
  background-color: ${props => props.theme.colors.text_label_inverse};
  height: 1px;
  margin-right: ${props => props.theme.spacing.xxs};
  width: 13px;
  ${props => props.$complete && `background-color:  ${props.theme.colors.background_inverse}`}
`;
