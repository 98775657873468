import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../themes';
import { MaybePartialSizeMap, sizeCss } from '../../../utils';
import { Heading, Paragraph } from '../../../content/text';
import { ButtonContainer } from '../../../content/button/ButtonContainer';

interface ProductCardProps {
  title?: string;
  accreditationRequired?: boolean;
  minInvestment: string;
  image?: string;
  imageAlt?: string;
  imageWidth?: number;
  className?: string;
  cta?: React.ReactNode;
  sideImage?: MaybePartialSizeMap<boolean>;
  sideCTA?: MaybePartialSizeMap<boolean>;
  noMargin?: boolean;
}

export const ProductCard: FunctionComponent<ProductCardProps> = props => {
  const {
    title,
    accreditationRequired,
    image,
    imageAlt,
    imageWidth,
    children,
    cta,
    minInvestment,
  } = props;
  return (
    <OuterContainer {...props}>
      <ImageHolder {...props}>
        {image && (
          <ImageWrapper>
            <Image alt={imageAlt} src={image} width={imageWidth}></Image>
          </ImageWrapper>
        )}
      </ImageHolder>
      <ContentHolder {...props}>
        <TextHolder>
          {title && <Headline type={5}>{title}</Headline>}
          {children}
        </TextHolder>
        <InfoWrapper>
          <Paragraph small>Accreditation</Paragraph>
          <Paragraph semiBold small>
            {accreditationRequired ? 'Required' : 'Not Required'}
          </Paragraph>
        </InfoWrapper>
        <InfoWrapper>
          <Paragraph small>Min. Investment</Paragraph>
          <Paragraph semiBold small>
            {minInvestment}
          </Paragraph>
        </InfoWrapper>
        {cta && <CTAHolder {...props}>{cta}</CTAHolder>}
      </ContentHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<ProductCardProps>`
  display: flex;
  flex: 1;
  text-align: left;
  background: ${props => props.theme.colors.background_default};
  box-shadow: ${props => props.theme.shadows.shadow_default};
  ${props =>
    !props.noMargin &&
    css`
      margin: 10px 0;
      ${media.desktop`
        margin: 0 10px;
      `};
    `};

  ${({ sideImage, sideCTA }) =>
    sizeCss(
      ({ sideImage, sideCTA }) => css`
        padding: ${sideImage || sideCTA ? 45 : 30}px;
        flex-direction: ${sideImage ? 'row' : 'column'};
      `,
      { sideImage, sideCTA }
    )}
`;

const Headline = styled(Heading)`
  font-weight: 400;
  padding: 0;
  margin-bottom: 10px;
  ${media.desktop`
    min-height: 50px;
  `};
`;

const TextHolder = styled.div`
  margin-bottom: 10px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  ${media.larger`
    flex-direction: row;
  `}
`;

const ImageHolder = styled.div<ProductCardProps>`
  ${({ sideImage }) =>
    sizeCss(
      ({ sideImage }) => css`
        margin-right: ${sideImage ? 45 : 0}px;
        margin-bottom: ${sideImage ? 0 : 20}px;
      `,
      { sideImage }
    )}
`;

const ContentHolder = styled.div<ProductCardProps>`
  color: ${props => props.theme.colors.text_paragraph};
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ sideCTA }) =>
    sizeCss(
      ({ sideCTA }) => css`
        flex-direction: ${sideCTA ? 'row' : 'column'};
      `,
      { sideCTA }
    )}
  justify-content: space-between;
`;

export const CTAHolder = styled(ButtonContainer)<ProductCardProps>`
  ${({ sideImage, sideCTA }) =>
    sizeCss(
      ({ sideImage, sideCTA }) => css`
        margin: 0;
        margin-top: ${sideCTA ? 0 : 40}px;
        margin-left: ${sideCTA ? 45 : 0}px;
        align-items: ${sideCTA ? 'center' : 'flex-start'};
        a,
        button {
          white-space: nowrap;
          width: ${sideImage || sideCTA ? 'auto' : '100%'};
        }
      `,
      { sideImage, sideCTA }
    )}
`;

const ImageWrapper = styled.div``;

const Image = styled.img`
  height: 60px;
`;
