import { Components, CssVarsTheme } from '@mui/material';

export const MuiTypography: Components<CssVarsTheme>['MuiTypography'] = {
  defaultProps: {},
  styleOverrides: {
    h1: ({ theme }) => ({
      fontSize: 38,
      [theme.breakpoints.up('sm')]: {
        fontSize: 46,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 52,
      },
    }),
    h2: ({ theme }) => ({
      fontSize: 28,
      [theme.breakpoints.up('sm')]: {
        fontSize: 36,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 42,
      },
    }),
    h3: ({ theme }) => ({
      fontSize: 24,
      [theme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 34,
      },
    }),
    h4: ({ theme }) => ({
      fontSize: 20,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 24,
      },
    }),
    h5: ({ theme }) => ({
      fontSize: 18,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
    }),
    h6: () => ({
      fontSize: 18,
    }),
  },
};
