import { RTKQAll, Tags } from '../common';
import {
  CreateInvestorEntityApiRequest,
  GetInvestorEntityAiqResponse,
  GetInvestorEntityApiParams,
  GetInvestorEntityApiResponse,
  InvestorEntityIdApiParams,
  InvestorEntityApiResponse,
  SubmitInvestorEntityAiqRequest,
  SubmitInvestorEntityAiqResponse,
  SubmitVerificationDocsApiRequest,
  SubmitVerificationDocsApiResponse,
  UpdateInvestorEntityApiRequest,
  CreateProviderInvestorEntityApiRequest,
  CreateManagedAccountApiResponse,
  CreateManagedAccountApiRequest,
  GetInvestorEntityBankDetailsApiParams,
  GetInvestorEntityBankDetailsApiResponse,
  GetIdentityProviderInvestorEntityRequest,
  UpdateIdentityProviderInvestorEntityRequest,
  GetEtcInvestorEntityResponse,
  UpdateEtcInvestorEntityResponse,
} from './investor-entity.model';
import { HttpMethods } from '../../enums/http';
import { AIQ, ManagementStrategy } from '../../types';
import { userApi } from '../user';

export const investorEntityApi = userApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.InvestorEntity,
      Tags.InvestorEntityBankDetails,
      Tags.InvestorEntityDocuments,
      Tags.InvestorEntityStatus,
      Tags.UserContact,
      Tags.ManagedPortfolioEligibility,
      Tags.InvestorEntityProvider,
    ],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => {
      const getInvestorEntity = builder.query<
        GetInvestorEntityApiResponse,
        GetInvestorEntityApiParams | RTKQAll
      >({
        query: params => ({
          url: `/a/api/investor-entity`,
          method: HttpMethods.Get,
          params,
        }),
        providesTags: [Tags.InvestorEntity],
      });

      const createInvestorEntity = builder.mutation<
        InvestorEntityApiResponse,
        CreateInvestorEntityApiRequest
      >({
        query: data => ({
          url: `/a/api/investor-entity`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.InvestorEntity, Tags.InvestorEntityStatus],
      });

      const updateInvestorEntity = builder.mutation<
        InvestorEntityApiResponse,
        UpdateInvestorEntityApiRequest
      >({
        query: ({ investorEntityId, ...data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}`,
          method: HttpMethods.Patch,
          data,
        }),
        invalidatesTags: [
          Tags.InvestorEntity,
          Tags.InvestorEntityStatus,
          Tags.UserContact,
          Tags.ManagedPortfolioEligibility,
        ],
      });

      const submitVerificationDocs = builder.mutation<
        SubmitVerificationDocsApiResponse,
        SubmitVerificationDocsApiRequest
      >({
        query: ({ investorEntityId, ...data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/add-kyc-document`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [
          Tags.InvestorEntityStatus,
          Tags.InvestorEntityDocuments,
          Tags.ManagedPortfolioEligibility,
        ],
      });

      const getInvestorEntityAiq = builder.query<AIQ[], InvestorEntityIdApiParams>({
        query: ({ investorEntityId }) => ({
          url: `/a/api/investor-entity/aiq/${investorEntityId}`,
          method: HttpMethods.Get,
        }),
        transformResponse: (response: GetInvestorEntityAiqResponse) => response.aiqs || [],
        providesTags: [Tags.InvestorEntityDocuments],
      });

      const submitInvestorEntityAiq = builder.mutation<
        SubmitInvestorEntityAiqResponse,
        SubmitInvestorEntityAiqRequest
      >({
        query: ({ investorEntityId, data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/aiq`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [
          Tags.InvestorEntityStatus,
          Tags.InvestorEntity,
          Tags.InvestorEntityDocuments,
          Tags.ManagedPortfolioEligibility,
        ],
      });

      const createManagedAccount = builder.mutation<
        CreateManagedAccountApiResponse,
        CreateManagedAccountApiRequest
      >({
        query: ({ investorEntityId }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/investor-account`,
          method: HttpMethods.Post,
          data: {
            managementStrategy: ManagementStrategy.Discretionary,
          },
        }),
        invalidatesTags: [
          Tags.InvestorEntityStatus,
          Tags.InvestorEntity,
          Tags.ManagedPortfolioEligibility,
        ],
      });

      const getEtcProviderInvestorEntity = builder.query<
        GetEtcInvestorEntityResponse,
        GetIdentityProviderInvestorEntityRequest
      >({
        query: ({ investorEntityId, ...data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/provider/etc`,
          method: HttpMethods.Get,
          data,
        }),
        providesTags: [Tags.InvestorEntity, Tags.InvestorEntityProvider],
      });

      const updateEtcProviderInvestorEntity = builder.mutation<
        UpdateEtcInvestorEntityResponse,
        UpdateIdentityProviderInvestorEntityRequest
      >({
        query: ({ investorEntityId, ...data }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/provider/etc`,
          method: HttpMethods.Patch,
          data,
        }),
        invalidatesTags: [Tags.InvestorEntity, Tags.InvestorEntityProvider],
      });

      const createProviderInvestorEntity = builder.mutation<
        InvestorEntityApiResponse,
        CreateProviderInvestorEntityApiRequest
      >({
        query: ({ provider, ...data }) => ({
          url: `/a/api/investor-entity/provider/${provider}`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.InvestorEntity, Tags.ManagedPortfolioEligibility],
      });

      const getInvestorEntityBankDetails = builder.query<
        GetInvestorEntityBankDetailsApiResponse,
        GetInvestorEntityBankDetailsApiParams
      >({
        query: ({ investorEntityId, bankAccountId }) => {
          return {
            url: `/a/api/investor-entity/${investorEntityId}/bank-account/${bankAccountId}/detail`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.InvestorEntityBankDetails],
      });

      return {
        getInvestorEntity,
        createInvestorEntity,
        updateInvestorEntity,
        submitVerificationDocs,
        getInvestorEntityAiq,
        submitInvestorEntityAiq,
        createProviderInvestorEntity,
        createManagedAccount,
        getInvestorEntityBankDetails,
        getEtcProviderInvestorEntity,
        updateEtcProviderInvestorEntity,
      };
    },
  });
