import { ReactNode } from 'react';

export const MODAL_BODY = 'modal-body';
export const MODAL_CLOSE_BUTTON = 'modal-close-button';
export const MODAL_CONTENT = 'modal-content';
export const MODAL_OVERLAY = 'modal-overlay';
export const MODAL_PORTAL = 'modal-portal';

export enum ModalAlignment {
  centre = 'CENTRE',
}

export interface ModalWrapperyStyleProps {
  align?: ModalAlignment;
  visible: boolean;
}

export interface ModalProps {
  align?: ModalAlignment;
  children: ReactNode;
  dataCy?: string;
  disableCloseOnClickOutside?: boolean;
  hideCloseButton?: boolean;
  modalId: string;
  onClose?: () => void;
  onSuccess?: () => void;
}
