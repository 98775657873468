import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { media } from '../../themes/media';

export const SubMenuSection = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.m};
    padding-bottom: ${theme.spacing.sm};
    ${media.large`
      padding-left: ${theme.spacing.l};
      padding-right: ${theme.spacing.l};
    `}
  `}
  ${media.large`
    &:not(:last-child) {
      border-bottom: solid 1px
        ${props => rgba(props.theme.colors.border_strong, 0.5)};
      }
  `}
`;

export const SubMenuSectionItems = styled.ul`
  margin-top: 10px;
  ${media.large<{ grid?: boolean }>`
    ${props => `
      column-count: ${props.grid ? 2 : 1};
      margin-left: -15px;
      margin-top: 8px;
    `}
  `}
`;
