import { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { Tracer } from './Tracer';
import * as api from '@opentelemetry/api';
import isFunction from 'lodash/isFunction';

interface TracedAxiosRequestConfig extends AxiosRequestConfig {
  span?: api.Span;
}

export const traceAxios = (
  axios: AxiosInstance,
  tracer: Tracer,
  spanName: string | ((config: AxiosRequestConfig) => string) = ({ method }) =>
    `HTTP ${method?.toUpperCase() || ''}`
) => {
  const originalAdapter = axios.defaults.adapter;
  const tracedAdapter = ({
    span: parentSpan,
    ...config
  }: TracedAxiosRequestConfig): AxiosPromise<any> => {
    const name = isFunction(spanName) ? spanName(config) : spanName;
    const { url, headers = {}, method } = config;

    const urlRegex = /^(?:https?:)?\/\/(?:[a-z]+.)?([a-z]+)\.(?:[a-z]+)/g;
    const urlTest = url && urlRegex.exec(url);
    const traceRequest = (urlTest && urlTest[1] === 'yieldstreet') || !urlTest;

    if (traceRequest) {
      return tracer.traceAsync(
        span => {
          // api.propagation.inject(headers);
          config.headers = headers;

          return originalAdapter!(config).then(
            response => {
              const status = response?.status;
              const statusText = response?.statusText;
              span.setAttributes({
                status: status,
                statusText: statusText,
                // responseHeaders: serializeObject(response?.headers),
                // responseBody: response.data,
              });
              return response;
            },
            (error: AxiosError) => {
              const status = error.response?.status;
              const statusText = error.response?.statusText;
              span.setAttributes({
                status: status,
                statusText: statusText,
              });
              throw error;
            }
          );
        },
        name,
        {
          url,
          method: method?.toUpperCase(),
          // requestBody: data,
          // requestHeaders: serializeObject(headers),
        },
        parentSpan
      ) as AxiosPromise<any>;
    } else {
      return originalAdapter!(config);
    }
  };
  axios.defaults.adapter = tracedAdapter;
};
