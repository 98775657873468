import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums';
import {
  GetRecurringPaymentsApiRequest,
  GetRecurringPaymentsApiResponse,
  PostRecurringPaymentsApiRequest,
  PostRecurringPaymentsApiResponse,
  DeleteRecurringPaymentsRequest,
  DeleteRecurringPaymentsResponse,
} from './recurring-payments.model';

export const recurringPaymentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.RecurringPayments,
      Tags.BankAccount,
      Tags.PortfolioTransactionPayments,
      Tags.PortfolioInvestmentsByStatus,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getRecurringPayments = builder.query<
        GetRecurringPaymentsApiResponse,
        GetRecurringPaymentsApiRequest
      >({
        query: ({ investorAccountId }) => ({
          url: `/a/api/recurring-payments/list/${investorAccountId}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.RecurringPayments],
      });

      const addRecurringPayment = builder.mutation<
        PostRecurringPaymentsApiResponse,
        PostRecurringPaymentsApiRequest
      >({
        query: data => ({
          url: `/a/api/recurring-payments/create-recurring-payment`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: (_, error) =>
          error
            ? []
            : [
                Tags.RecurringPayments,
                Tags.BankAccount,
                Tags.PortfolioTransactionPayments,
                Tags.PortfolioInvestmentsByStatus,
              ],
      });

      const deleteRecurringPayments = builder.mutation<
        DeleteRecurringPaymentsResponse,
        DeleteRecurringPaymentsRequest
      >({
        query: ({ investorAccountId, recurringPaymentId }) => ({
          url: `/a/api/recurring-payments/delete/${investorAccountId}/${recurringPaymentId}`,
          method: HttpMethods.Delete,
        }),
        invalidatesTags: (_, error) => (error ? [] : [Tags.RecurringPayments]),
      });

      return {
        getRecurringPayments,
        addRecurringPayment,
        deleteRecurringPayments,
      };
    },
  });
