import { ColorSystemOptions } from '@mui/material';
import { light } from './light.scheme';

export const dark: ColorSystemOptions = {
  ...light,
  palette: {
    ...light.palette,
    mode: 'dark',
  },
};
