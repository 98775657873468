import React, { FunctionComponent } from 'react';
import styled, { withTheme, ThemeProps } from 'styled-components';

import { Heading, Label } from '../../content/text';
import { ButtonLink } from '../../content';
import { Theme } from '../../types';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import CloseIconWhite from '../../assets/graphics/icons/close_white.svg';
import { media } from '../../themes';

interface OfferingAnnouncementProps {
  title?: string;
  offeringTitle?: string;
  interest?: string;
  terms?: string;
  assetClass?: string;
  backgroundColor?: string;
  dismissible?: boolean;
  dismiss?: () => {};
  small?: boolean;
  path?: string;
  buttonText?: boolean;
  orientation?: 'row' | 'column';
  inverse?: boolean;
  onClick?: () => {};
}

interface OfferingVisualProps {
  orientation?: 'row' | 'column';
  small?: boolean;
  backgroundColor?: string;
  inverse?: boolean;
}

export const OfferingAnnouncementInner: FunctionComponent<
  OfferingAnnouncementProps & ThemeProps<Theme>
> = ({
  title,
  offeringTitle,
  interest,
  terms,
  assetClass,
  backgroundColor,
  dismissible,
  dismiss,
  small,
  path,
  buttonText,
  orientation = 'row',
  inverse,
  onClick,
  theme,
}) => {
  const mapBgColor = () => {
    switch (backgroundColor) {
      case 'darkBlue':
        return theme.colors.background_inverse_secondary;
      case 'black':
        return theme.colors.background_inverse;
      default:
        return theme.colors.background_default;
    }
  };

  return (
    <Wrapper small={small} backgroundColor={mapBgColor()}>
      <Container small={small} backgroundColor={mapBgColor()} orientation={orientation}>
        <Details>
          <Title inverse={inverse} type={5}>
            {title}
          </Title>
          {assetClass && <AssetClass inverse={inverse}>{assetClass}</AssetClass>}
          <OfferingTitle inverse={inverse} type={small ? 4 : 3}>
            {offeringTitle}
          </OfferingTitle>
          <SidedFields>
            {interest && (
              <OfferingDetail small={small}>
                <Label inverse={inverse} small={small}>
                  Annual return:
                </Label>{' '}
                {small ? (
                  <Label inverse={inverse} small={small} semiBold>
                    {interest}
                  </Label>
                ) : (
                  <Heading type={4} inverse={inverse}>
                    <b>{interest}</b>
                  </Heading>
                )}
              </OfferingDetail>
            )}
            {terms && (
              <OfferingDetail small={small}>
                <Label inverse={inverse} small={small}>
                  Term:
                </Label>{' '}
                {small ? (
                  <Label inverse={inverse} small={small} semiBold>
                    {terms}
                  </Label>
                ) : (
                  <Heading type={4} inverse={inverse}>
                    <b>{terms}</b>
                  </Heading>
                )}
              </OfferingDetail>
            )}
          </SidedFields>
        </Details>
        <ButtonContainer orientation={orientation}>
          <ButtonLink buttonProps={{ fullWidth: true }} linkProps={{ href: path, onClick }}>
            {buttonText}
          </ButtonLink>
        </ButtonContainer>
        {dismissible && <Close src={inverse ? CloseIconWhite : CloseIcon} onClick={dismiss} />}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div<OfferingVisualProps>`
  background: ${props => props.backgroundColor};
`;

const Container = styled.div<OfferingVisualProps>`
  max-width: ${props => (props.small ? '1150px' : '1180px')};
  position: relative;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  ${media.desktop<OfferingVisualProps>`
    flex-direction: ${props => props.orientation};
    padding: ${props => (props.small ? '20px' : '35px')};
  `}
`;

const Details = styled.div``;

const ButtonContainer = styled.div`
  margin-top: 20px;
  ${media.desktop<OfferingVisualProps>`
  min-width: 20%;
  margin: ${props => (props.orientation === 'column' ? '20px 0 0 0' : 'auto 0')};
  `}
`;

const Close = styled.img`
  cursor: pointer;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Title = styled(Heading)`
  margin-bottom: 20px;
`;

const OfferingTitle = styled(Heading)``;

const AssetClass = styled(Label)`
  margin-bottom: 10px;
`;

const SidedFields = styled.div`
  display: flex;
  flex-direction: row;
`;

const OfferingDetail = styled.div<OfferingVisualProps>`
  display: flex;
  flex-direction: ${props => (props.small ? 'row' : 'column')};
  margin-top: 20px;
  margin-right: 30px;
`;

export const OfferingAnnouncement = withTheme(OfferingAnnouncementInner);
