import {
  marketplaceApi,
  GetPaginatedOfferingsApiResponse,
  GetPaginatedOfferingsApiRequest,
} from '@yieldstreet/platform-kit';

const { useGetPaginatedOfferingsQuery } = marketplaceApi;

export type UseMarketplaceOfferingsProps = GetPaginatedOfferingsApiRequest &
  UseMarketplaceOfferingsExtraProps;

export type UseMarketplaceOfferingsExtraProps = {
  refetchOnMountOrArgChange?: boolean;
  includeFundingProgress?: boolean;
};

export type UseMarketplaceOfferingsTypes = {
  isLoading: boolean;
  isFetching: boolean;
  isUninitialized: boolean;
  isError: boolean;
  currentPage?: GetPaginatedOfferingsApiResponse['currentPage'];
  hasMore?: GetPaginatedOfferingsApiResponse['hasMore'];
  offerings: GetPaginatedOfferingsApiResponse['offerings'];
  totalPages?: GetPaginatedOfferingsApiResponse['totalPages'];
  dataAttributes?: GetPaginatedOfferingsApiResponse['attributes'];
};

export const useMarketplaceOfferings = ({
  statuses,
  page,
  pageSize,
  investmentStrategy,
  minInvestmentHigh,
  minInvestmentLow,
  attributes,
  maxTerm,
  minTerm,
  order,
  sortBy,
  assetClasses,
  assetTypes,
  preventIraFromInvesting,
  timestampForPulldownRefetch,
  refetchOnMountOrArgChange,
  includeFundingProgress,
  rolloverFunding,
  accreditationEligibility,
}: UseMarketplaceOfferingsProps): UseMarketplaceOfferingsTypes => {
  const {
    offerings,
    isLoading,
    totalPages,
    hasMore,
    dataAttributes,
    isError,
    isFetching,
    currentPage,
    isUninitialized,
  } = useGetPaginatedOfferingsQuery(
    {
      statuses,
      page,
      pageSize,
      investmentStrategy,
      minInvestmentHigh,
      minInvestmentLow,
      attributes,
      maxTerm,
      minTerm,
      order,
      sortBy,
      assetClasses,
      assetTypes,
      preventIraFromInvesting,
      timestampForPulldownRefetch,
      includeFundingProgress,
      rolloverFunding,
      accreditationEligibility,
    },
    {
      refetchOnMountOrArgChange,
      selectFromResult: ({ data, isLoading, isUninitialized, isError, isFetching }) => ({
        offerings: data?.offerings || [],
        dataAttributes: data?.attributes,
        totalPages: data?.totalPages,
        currentPage: data?.currentPage,
        isLoading: isLoading,
        isUninitialized,
        isError,
        hasMore: data?.hasMore,
        isFetching,
      }),
    }
  );

  return {
    hasMore,
    isFetching,
    isLoading,
    isError,
    offerings,
    totalPages,
    dataAttributes,
    currentPage,
    isUninitialized,
  };
};
