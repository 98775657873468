import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';

import { transformAllInvestorAccountsStatusResponse } from './investor-account-status.util';
import {
  InvestorAccountStatus,
  InvestorAccountStatusAPIResponse,
} from './investor-account-status.model';

export const investorAccountStatusApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.InvestorAccountStatus],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getAllInvestorAccountsStatus = builder.query<InvestorAccountStatusAPIResponse, {}>({
        query: () => {
          return {
            url: '/a/api/investor/account/all/status',
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.InvestorAccountStatus],
        transformResponse: transformAllInvestorAccountsStatusResponse,
      });

      const getInvestorAccountStatus = builder.query<
        InvestorAccountStatus,
        { investorAccountId: string }
      >({
        query: ({ investorAccountId }) => ({
          url: `/a/api/investor/account/${investorAccountId}/status`,
          method: HttpMethods.Get,
        }),
        transformResponse: (response: InvestorAccountStatusAPIResponse) => {
          return response.status;
        },
        providesTags: [Tags.InvestorAccountStatus],
      });

      return {
        getAllInvestorAccountsStatus,
        getInvestorAccountStatus,
      };
    },
  });
