import { kebabCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';

import Arrow from '../assets/arrow-round.svg';
import { Label, Paragraph } from '../../../content';
import { DesktopOnly, MobileOnly } from '../../../utils';
import { SliderInput } from '../../../form';
import { media } from '../../../themes';

interface AmountSelectorProps {
  title: string;
  annualizedYield: number;
  term: string;
  amount: number;
  step?: number;
  min?: number;
  max?: number;
  onChange?: (value: number | number[]) => void;
  onTouchMove?: () => void;
  onTouchEnd?: () => void;
  color?: string;
}
const AmountSelector = ({
  title,
  annualizedYield,
  term,
  min = 0,
  max = 100000,
  step = 1000,
  amount,
  onChange,
  onTouchMove,
  onTouchEnd,
  color,
}: AmountSelectorProps) => {
  return (
    <AmountSelectorHolder>
      <AmountTitle>
        <Paragraph semiBold>{title}</Paragraph>
        <AmountValue>{numeral(amount).format('$0,0')}</AmountValue>
      </AmountTitle>
      <LineValue>
        <Label>Target annualized yield</Label>
        <Label>
          <>{annualizedYield}%</>
        </Label>
      </LineValue>
      <LineValue>
        <Label>Target term</Label>
        <Label>{term}</Label>
      </LineValue>
      <SliderContainer onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        <MobileOnly>
          <SSliderInput
            label={kebabCase(title)}
            name="slider"
            min={min}
            max={max}
            value={amount}
            fullWidth
            onChange={value => onChange && onChange(value)}
            formatValue={() => ''}
            step={step}
            trackColor={color}
          />
        </MobileOnly>
        <DesktopOnly>
          <SSliderInput
            label={kebabCase(title)}
            name="slider"
            min={min}
            max={max}
            value={amount}
            fullWidth
            onChange={value => onChange && onChange(value)}
            formatValue={value => numeral(value).format('$0,0')}
            step={step}
            trackColor={color}
          />
        </DesktopOnly>
      </SliderContainer>
    </AmountSelectorHolder>
  );
};

const AmountSelectorHolder = styled.div`
  background-color: ${props => props.theme.colors.background_card};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 15px 15px 0;

  ${media.large`
    min-height: 208px;
    margin-bottom: 0;
    padding: 20px 20px 10px 20px;
    width: 280px;
  `}
`;

const AmountTitle = styled.div`
  display: flex;
  margin-bottom: 5px;
  text-align: left;
  ${media.desktop`
    margin-bottom: 15px;
    text-align: center;
  `}
`;

const AmountValue = styled(Paragraph)`
  margin-left: auto;
  ${media.desktop`
    display: none;
  `}
`;

const LineValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4px 0;
`;

const SliderContainer = styled.div``;

const SSliderInput = styled(SliderInput)<{ trackColor?: string }>`
  margin-bottom: 0 !important;
  ${media.desktop`
    margin-top: auto !important;
  `}

  > span {
    display: none;
  }

  p {
    background-color: ${props => props.theme.colors.background_page};
    border-radius: 4px;
    padding: 6px 10px;
    ${media.desktop`
      display: block;
    `}
  }

  span {
    color: ${props => (props.trackColor ? props.trackColor : props.theme.colors.chart_10)};
  }

  .MuiSlider-root {
    margin-bottom: 0;

    .MuiSlider-track {
      transition: unset;
    }
    .MuiSlider-thumb {
      background: ${props => (props.trackColor ? props.trackColor : props.theme.colors.chart_10)};
      border-radius: 4px;
      height: 18px;
      position: relative;
      transition-property: height, width, box-shadow;
      transition-duration: 0.3s;

      width: 34px;

      &:hover {
        height: 23px;
        width: 39px;
      }

      &:before,
      &:after {
        background-image: url('${Arrow}');
        background-size: cover;
        content: '';
        height: 8px;
        position: absolute;
        bottom: auto;
        width: 5px;
      }

      &:before {
        left: 5px;
        transform: scaleX(-1);
      }

      &:after {
        left: auto;
        right: 5px;
      }
    }
  }
`;

export default AmountSelector;
