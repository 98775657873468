import { isString } from 'lodash';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Heading } from '../../content/text/Heading';
import DiagonalLines from '../../assets/graphics/backgrounds/diagonal-lines-pattern.svg';
import { media } from '../../themes/media';
import { Paragraph } from '../../content/text/Paragraph';
import { LabelStyleSmall } from '../../content/text/Label';
import { ContainerPadding } from '../container/Container';

import { LogoHolderProps, PageSplitProps } from './PageSplit.model';

const maxWidth = 1250;
const ratioSideSplit = 5 / 12;
const ratioMiddleSplit = 6 / 12;

export const SPageSplit = styled.div<Partial<PageSplitProps>>`
  display: flex;
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  ${media.desktop`
    min-height: 100vh;
    flex-direction: row;
    justify-content: center;
  `};
`;

export const LeftPageContentWidth = css<PageSplitProps>`
  width: calc(
    ((100vw - ${maxWidth}px) / 2) +
      (${maxWidth}px * ${props => (props.middleSplit ? ratioMiddleSplit : ratioSideSplit)})
  );
`;

export const LeftPageContent = styled.div<Partial<PageSplitProps>>`
  background: ${props => props.theme.colors.background_default};
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px 0;
  width: 100%;
  ${media.desktop`
    justify-content: space-between;
    background: ${props =>
      props.theme.colors.background_default} url(${DiagonalLines}) bottom left no-repeat;
    padding: 40px 100px 100px calc(((100vw - ${maxWidth}px) / 2));
    ${LeftPageContentWidth};
  `};
`;

export const Background = css<PageSplitProps>`
  background-image: ${props =>
    isString(props.backgroundImage) ? `url(${props.backgroundImage})` : `none`};
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ContentHolder = styled.div`
  width: 100%;
`;

export const RightPageContent = styled.div<Partial<PageSplitProps>>`
  padding: 30px 0;
  width: 100%;
  ${props =>
    !props.hideBackgroundImageMobile
      ? css`
          ${Background};
          min-height: 70vh;
        `
      : ``};
  ${media.desktop<Partial<PageSplitProps>>`
    padding: calc(60px + ${props =>
      props.headerHeight}px) calc(((100vw - ${maxWidth}px) / 2)) 100px 100px;
    width: calc(((100vw - ${maxWidth}px) / 2) + ((${maxWidth}px * ${props =>
    props.middleSplit ? 1 - ratioMiddleSplit : 1 - ratioSideSplit})));
    ${Background};
  `};
`;

export const ContentHeader = styled.div`
  ${ContainerPadding};
  width: 100%;
  height: fit-content;
`;

export const ContentLeft = styled.div`
  ${ContainerPadding};
  width: 100%;
  padding-top: 10px;
`;

export const ContentRight = styled.div`
  ${ContainerPadding};
  width: 100%;
  animation: slideIn ease 0.25s;

  @keyframes slideIn {
    0% {
      margin-left: 50px;
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-left: 0;
    }
  }
`;

export const FooterContentLeft = styled.div`
  ${LabelStyleSmall};
  ${ContainerPadding};
  display: none;
  margin-top: 50px;
  ${media.desktop`
    display: block;
  `};
`;

export const FooterContentRight = styled(FooterContentLeft)`
  display: block;
  padding: 20px;
  margin: 0;
  ${media.desktop`
    display: none;
  `};
`;

export const Title = styled(Heading)`
  margin-bottom: 20px;
`;

export const Label = styled(Paragraph)`
  margin-bottom: 20px;
`;

export const LogosHolder = styled.div<LogoHolderProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  justify-content: ${props => (props.center ? 'center' : 'left')};
  ${media.desktop`
    justify-content: left;
  `};
`;

export const PlusHolder = styled.div`
  margin: 0 10px;
`;

export const Logo = styled.div`
  img {
    max-width: 100%;
    max-height: 47px;
  }
`;

export const LogoSecondary = styled(Logo)`
  flex-shrink: 1;
  flex-grow: 0;
`;

export const BackNavigation = styled.div`
  cursor: pointer;

  ${media.desktop`
    padding: 25px 0 20px 0;
  `}
`;

export const Arrow = styled(FontAwesomeIcon)`
  margin: 0 10px 0 0;
  width: 10px;
`;
