import axios from 'axios';
import forEach from 'lodash/forEach';

import { restApi, RTKQAll, Tags } from '../common';
import {
  InvestorAccountNoteConfigApiResponse,
  InvestorAccountNoteConfigRequest,
  CreateInvestorAccountApiRequest,
  CreateProviderInvestorAccountApiRequest,
  UpdateInvestorAccountApiRequest,
  GetInvestorAccountsApiParams,
  GetInvestorAccountsApiResponse,
  InvestorAccountApiResponse,
  CreateWalletTransactionApiRequest,
  CreateWalletTransactionApiResponse,
  CreateWalletTransactionRequest,
} from './investor-account.model';
import { HttpMethods } from '../../enums/http';

export const investorAccountsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.BankAccount,
      Tags.InvestorAccount,
      Tags.InvestorAccountNoteConfig,
      Tags.DistributionReinvestment,
      Tags.InvestorAccountWallet,
      Tags.AdviceManagedPortfolio,
      Tags.PortfolioTransactionPayments,
      Tags.PortfolioInvestmentsByStatus,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getInvestorAccounts = builder.query<
        GetInvestorAccountsApiResponse,
        GetInvestorAccountsApiParams | RTKQAll
      >({
        query: params => ({
          url: `/a/api/investor/account`,
          method: HttpMethods.Get,
          params,
        }),
        providesTags: [Tags.InvestorAccount],
        transformResponse: (response: GetInvestorAccountsApiResponse) => {
          const sortedInvestorAccounts = [...response.accounts].sort(
            (a, b) => a.createDate - b.createDate
          );

          return {
            ...response,
            accounts: sortedInvestorAccounts,
          };
        },
      });

      const createInvestorAccount = builder.mutation<
        InvestorAccountApiResponse,
        CreateInvestorAccountApiRequest
      >({
        query: data => ({
          url: `/a/api/investor-account`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.InvestorAccount],
      });

      const createProviderInvestorAccount = builder.mutation<
        InvestorAccountApiResponse,
        CreateProviderInvestorAccountApiRequest
      >({
        query: ({ provider }) => ({
          url: `/a/api/investor-account/provider/${provider}`,
          method: HttpMethods.Post,
        }),
        invalidatesTags: [Tags.InvestorAccount],
      });

      const updateInvestorAccount = builder.mutation<
        InvestorAccountApiResponse,
        UpdateInvestorAccountApiRequest
      >({
        query: ({ investorAccountId, ...data }) => ({
          url: `/a/api/investor-account/${investorAccountId}`,
          method: HttpMethods.Put,
          data,
        }),
        invalidatesTags: [Tags.InvestorAccount],
      });

      const createWalletTransaction = builder.mutation<
        CreateWalletTransactionApiResponse,
        CreateWalletTransactionApiRequest
      >({
        query: ({ investorId, ...data }: CreateWalletTransactionRequest) => ({
          url: `/a/api/investor/account/${investorId}/wallet-transaction`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: (_, error) =>
          error
            ? []
            : [
                Tags.InvestorAccountWallet,
                Tags.BankAccount,
                Tags.AdviceManagedPortfolio,
                Tags.PortfolioTransactionPayments,
                Tags.PortfolioInvestmentsByStatus,
              ],
      });

      const getInvestorAccountNoteConfig = builder.query<
        InvestorAccountNoteConfigApiResponse,
        InvestorAccountNoteConfigRequest
      >({
        query: params => ({
          url: `/a/api/investor/account/note/config`,
          method: HttpMethods.Get,
          body: params,
        }),
        providesTags: [Tags.InvestorAccountNoteConfig],
      });

      const getDistributionReinvestment = builder.query<any, any>({
        queryFn: async ({ investorAccountId, urlHashes }) => {
          const apis: any = [];
          forEach(urlHashes, urlHash => {
            apis.push(
              axios.get(`/a/api/investor/account/${investorAccountId}/note/${urlHash}/config`, {
                params: { urlHash },
              })
            );
          });

          return await axios
            .all(apis)
            .then(
              axios.spread((...responses: any[]) => {
                const data = {};
                forEach(responses, x => {
                  Object.assign(data, { [x.config?.params?.urlHash]: x.data });
                });
                return { data };
              })
            )
            .catch(errors => {
              return { data: {}, error: errors };
            });
        },
        providesTags: [Tags.DistributionReinvestment],
      });

      return {
        createWalletTransaction,
        getInvestorAccounts,
        createInvestorAccount,
        createProviderInvestorAccount,
        updateInvestorAccount,
        getInvestorAccountNoteConfig,
        getDistributionReinvestment,
      };
    },
  });
