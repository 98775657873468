import { HttpMethods } from '../../enums/http';

import { restApi, Tags } from '../common';
import {
  CreatePlaidKYCLinkTokenParams,
  CreatePlaidKYCLinkTokenResponse,
  GetPlaidKYCVerificationStatusResponse,
  GetPlaidKYCVerificationStatusParams,
  RefreshPlaidKYCVerificationStatusParams,
} from './plaid.model';

const BASE_URL = '/api/plaid';

export const plaidApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PlaidKYCVerificationStatus, Tags.InvestorEntityStatus, Tags.BankAccount],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getPlaidKYCVerificationStatus = builder.query<
        GetPlaidKYCVerificationStatusResponse,
        GetPlaidKYCVerificationStatusParams
      >({
        query: ({ investorEntityId }) => ({
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/verification-status`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.PlaidKYCVerificationStatus],
      });

      const createPlaidKYCLinkToken = builder.mutation<
        CreatePlaidKYCLinkTokenResponse,
        CreatePlaidKYCLinkTokenParams
      >({
        query: ({ investorEntityId }) => ({
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/link-token`,
          method: HttpMethods.Post,
        }),
      });

      const refreshPlaidKYCVerificationStatus = builder.mutation<
        {},
        RefreshPlaidKYCVerificationStatusParams
      >({
        query: ({ investorEntityId }) => ({
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/refresh-status`,
          method: HttpMethods.Post,
        }),
      });

      return {
        getPlaidKYCVerificationStatus,
        createPlaidKYCLinkToken,
        refreshPlaidKYCVerificationStatus,
      };
    },
  });
