import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import blackLogoImg from '../../assets/logos/black_ys_logo_mobile.svg';
import logoImg from '../../assets/logos/white_ys_logo_mobile.svg';
import { media } from '../../themes/media';

type LogoProps = {
  inverse?: boolean;
};

export const Logo: FunctionComponent<LogoProps> = ({ inverse }) => (
  <LogoImage tabIndex={-1} inverse={inverse} />
);

const LogoImage = styled.div<{ inverse?: boolean }>`
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${props => (props.inverse ? logoImg : blackLogoImg)});
  &:active,
  &:focus {
    outline: 0;
    border: none;
  }

  width: 145px;
  ${media.large`
    width: 185px;
  `};
`;
