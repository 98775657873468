import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import isString from 'lodash/isString';

import {
  NavigationLink,
  getNavItemSubMenu,
  getNavItemVisible,
  useNavItemProps,
  getNavItemTitle,
} from '../../common';
import { getSizeVariation, getNavigationValue } from '../common';
import { SubmenuSection } from '../SubmenuSection';

import { HeaderItemProps } from './HeaderItemLoggedOut.model';
import {
  ArrowIcon,
  MenuItem,
  MenuLinkContainer,
  NotificationIcon,
  NotificationIndicator,
  SButton,
  SParagraph,
  SubMenu,
  SubMenuItem,
  Title,
} from './HeaderItemLoggedOut.style';

export const HeaderItemLoggedOut: React.FunctionComponent<HeaderItemProps> = props => {
  const {
    menuItem,
    activeItem,
    isMobile,
    isSidebarItem,
    desktopAlignment = 'left',
    notificationGroup,
    setDropdownOpen,
    setSideMenuOpen,
  } = props;

  const menuLinkElement = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(e => {
    if (e.target && !menuLinkElement?.current?.contains(e.target as Node)) {
      setMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
    }
  }, [onClickOutside]);

  const [menuOpen, setMenuOpen] = useState(false);

  const navProps = useNavItemProps(props);

  const itemSelected = menuItem.key === activeItem?.key;

  const itemInteractive = useMemo(() => {
    const renderTitle = getSizeVariation(
      getNavigationValue(menuItem.title, navProps, menuItem),
      isMobile
    );
    return isString(renderTitle);
  }, [isMobile, menuItem, navProps]);

  const itemTitle = useMemo(() => {
    return getNavItemTitle(menuItem, navProps);
  }, [menuItem, navProps]);

  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);

  const itemSubMenu = useMemo(() => {
    return getNavItemSubMenu(menuItem, navProps);
  }, [menuItem, navProps]);

  return itemVisible ? (
    <MenuItem
      key={`menu-item-${menuItem.key}`}
      data-js-header-item-menu-item
      isSidebarItem={isSidebarItem}
      seperator={menuItem.seperator}
    >
      <Title
        onClick={() => {
          let dropdownOpen = false;
          if (itemSubMenu) {
            setMenuOpen(!menuOpen);
            dropdownOpen = !menuOpen;
          }
          setDropdownOpen && setDropdownOpen(dropdownOpen);
        }}
        isSidebarItem={isSidebarItem}
      >
        <MenuLinkContainer
          ref={menuLinkElement}
          isSidebarItem={isSidebarItem}
          active={menuOpen}
          isButton={menuItem?.type === 'button'}
          selected={itemSelected}
          interactive={itemInteractive}
        >
          {itemSubMenu ? (
            <a
              href="#/"
              onClick={e => e.preventDefault()}
              data-cy={`header-${menuItem?.key}-mobile`}
            >
              {itemTitle}
            </a>
          ) : (
            <NavigationLink
              navItem={{
                ...menuItem,
                id: `header-${menuItem?.key}`,
                callback: () => {
                  setSideMenuOpen && setSideMenuOpen(false);
                },
              }}
              raw
              {...props}
            >
              {menuItem?.type === 'button' ? (
                <SButton small buttonType="secondary">
                  {itemTitle}
                </SButton>
              ) : (
                <>{itemTitle}</>
              )}
            </NavigationLink>
          )}
          {itemSubMenu && (isMobile || menuItem.itemsDropdown) && (
            <ArrowIcon isSidebarItem={isSidebarItem} menuOpen={menuOpen} />
          )}
          <NotificationIndicator>
            {notificationGroup?.map(group =>
              group.menu === menuItem.key && group.value ? <NotificationIcon /> : null
            )}
          </NotificationIndicator>
        </MenuLinkContainer>
        {itemSubMenu && (
          <SubMenu
            isSidebarItem={isSidebarItem}
            desktopAlignment={desktopAlignment}
            menuOpen={menuOpen}
          >
            {itemSubMenu.map(submenu => {
              if (submenu.items) {
                return (
                  <SubmenuSection
                    item={submenu}
                    key={`submenu-section-${submenu.key}`}
                    navProps={navProps}
                  />
                );
              }
              return (
                <HeaderSubMenuItem
                  {...props}
                  key={`subItem-${submenu.key}`}
                  menuItem={submenu}
                  notificationGroup={notificationGroup}
                  seperator={submenu.seperator}
                />
              );
            })}
          </SubMenu>
        )}
      </Title>
    </MenuItem>
  ) : null;
};

export const HeaderSubMenuItem: React.FunctionComponent<HeaderItemProps> = props => {
  const { menuItem, activeSubItem, notificationGroup, isSidebarItem, seperator, inverse } = props;

  const navProps = useNavItemProps(props);

  const itemSelected = menuItem.key === activeSubItem?.key;
  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);

  return itemVisible ? (
    <SubMenuItem seperator={seperator} key={`submenu-${menuItem.key}`}>
      <MenuLinkContainer
        isSubItem
        isSidebarItem={isSidebarItem}
        selected={itemSelected}
        interactive
        inverse={inverse}
      >
        <NavigationLink
          navItem={{ ...menuItem, id: `header-${menuItem?.key}` }}
          raw
          inverse
          {...props}
        />
        <NotificationIndicator>
          {notificationGroup?.map(group =>
            group.subMenu === menuItem.key && group.value ? (
              <NotificationIcon subMenu isBigNumber={group.value > 99}>
                <SParagraph>{group.value > 99 ? '99+' : group.value}</SParagraph>
              </NotificationIcon>
            ) : null
          )}
        </NotificationIndicator>
      </MenuLinkContainer>
    </SubMenuItem>
  ) : null;
};
