import styled from 'styled-components';
import React, { useState } from 'react';
import { Investment } from '@yieldstreet/tool-kit';

import { media } from '../../themes/media';
import { Label, Heading, Paragraph } from '../../content';
import ExpandedAmountSelector from './Widgets/ExpandedAmountSelector';
import Chart from './Widgets/Chart';

interface ChartProps {
  investment: Investment;
  initAmount: number;
}

interface DealSimulatorProps {
  investment: Investment;
  initAmount?: number;
}

const PortfolioChart = ({ investment, initAmount }: ChartProps) => {
  const [showTotalInvestment, setShowTotalInvestment] = useState(false);
  const [amount, setAmount] = useState<number>(initAmount);

  return (
    <>
      <ChartContainer>
        <Chart
          investments={[investment]}
          amounts={[amount]}
          showTotalInvestment={showTotalInvestment}
        />
      </ChartContainer>
      <ControlsHolder>
        <ExpandedAmountSelector
          title={investment.name}
          annualizedYield={investment.rate}
          term={`${investment.term} ${investment.termSuffix} `}
          min={investment.min}
          max={investment.max}
          amount={amount}
          onChange={newAmount => setAmount(+newAmount)}
          onTouchMove={() => setShowTotalInvestment(true)}
          onTouchEnd={() => setShowTotalInvestment(false)}
        />
      </ControlsHolder>
    </>
  );
};

export const DealSimulator = ({ investment, initAmount = 5000 }: DealSimulatorProps) => {
  return (
    <>
      <Heading type={3}>Deal simulator </Heading>
      <Paragraph marginBottom marginTop>
        See how you can benefit from building a portfolio of alternative investments.
      </Paragraph>
      <Background data-cy="deal-simulator-chart">
        <PortfolioChart investment={investment} initAmount={initAmount} />
        <Disclaimer small>
          All investments involve risk, including loss of principal.{' '}
          <a href="/simulator-disclosures/">View Disclosures</a>
        </Disclaimer>
      </Background>
    </>
  );
};

const Background = styled.div`
  background: ${props => props.theme.colors.background_page};
  margin: 0 -16px -16px -16px;
  padding: 16px;
`;

const ChartContainer = styled.div`
  background: ${props => props.theme.colors.background_card};
  border-radius: 8px;
  margin-top: 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  ${media.large`
    position: static;
  `}
`;

const ControlsHolder = styled.div`
  margin-top: 20px;
  background: ${props => props.theme.colors.background_page};

  ${media.large`
    padding: 0;
  `}
`;

const Disclaimer = styled(Label)`
  background: ${props => props.theme.colors.background_page};
  display: block;
  padding: 15px 15px 30px;
  ${media.large`
    padding: 20px 0 0 0;
  `}
`;
