import styled from 'styled-components';
import { Snackbar } from '@mui/material';
import { SnackbarTypes } from '@yieldstreet/tool-kit';

import { SnackbarStylesProps, SnackbarColorPickerProps } from './Snackbar.model';
import { media } from '../../themes';

const snackbarColorPicker = ({ theme, type }: SnackbarColorPickerProps) => {
  switch (type) {
    case SnackbarTypes.Success:
      return { containerBackground: theme.colors.background_success };
    case SnackbarTypes.Error:
      return { containerBackground: theme.colors.background_error };
    case SnackbarTypes.Warning:
      return { containerBackground: theme.colors.background_warning };
    case SnackbarTypes.Info:
    default:
      return { containerBackground: theme.colors.background_info };
  }
};

export const SnackbarItem = styled(Snackbar)<SnackbarStylesProps>`
  position: relative !important;

  &:not(:first-child) {
    margin-top: 10px !important;
  }

  .MuiSnackbar-root {
    position: relative !important;
  }
  .MuiSnackbarContent-root {
    width: 100%;
    ${media.phoneLandscape`
      width: 375px;
    `}
    background: ${({ theme, type }) => snackbarColorPicker({ theme, type }).containerBackground};
    border-radius: 0;
    padding: 20px;
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
  .MuiPaper-elevation6 {
    box-shadow: none;
  }

  animation: fadeIn ease 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SnackbarItemWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 20px;
  position: fixed;
  z-index: 100;
  ${media.phoneLandscape`
    left: unset;
    top: 115px;
    right: 34px !important;
    bottom: unset;
  `}
`;

export const ActionClose = styled.img`
  cursor: pointer;
  width: 9px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const MessageDescriptionWrapper = styled.div`
  padding-top: 5px;
`;

export const LinkWrapper = styled.div`
  padding-top: 10px;
`;
