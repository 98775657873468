import { restApi, Tags } from '../common';
import {
  CreateBenefialOwnerRequest,
  CreateBenefialOwnerResponse,
  GetBeneficialOwnersRequest,
  GetBeneficialOwners,
} from './investor-entity-beneficial-owners.model';
import { HttpMethods } from '../../enums/http';

export const investorEntityBeneficialOwnersApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.InvestorEntityBeneficialOwners],
  })
  .injectEndpoints({
    endpoints: builder => {
      const createBeneficialOwner = builder.mutation<
        CreateBenefialOwnerResponse,
        CreateBenefialOwnerRequest
      >({
        query: ({ id, ...data }) => ({
          url: `/a/api/investor-entity/${id}/beneficial-owners`,
          method: HttpMethods.Post,
          data,
        }),
        transformResponse: res => res.beneficialOwner,
        invalidatesTags: [Tags.InvestorEntityBeneficialOwners],
      });

      const getBeneficialOwners = builder.query<GetBeneficialOwners[], GetBeneficialOwnersRequest>({
        query: id => ({
          url: `/a/api/investor-entity/${id}/beneficial-owners`,
          method: HttpMethods.Get,
        }),
        transformResponse: res => res.beneficialOwners,
        providesTags: [Tags.InvestorEntityBeneficialOwners],
      });

      const getRequiredDocumentsForBeneficialById = builder.query<any, any>({
        query: id => ({
          url: `/a/api/investor-entity/${id}/required-documents`,
          method: HttpMethods.Get,
        }),
        transformResponse: res => res.beneficialOwner,
        providesTags: [Tags.InvestorEntityBeneficialOwners],
      });

      const getDocumentStatusForBeneficial = builder.query<any, any>({
        query: id => ({
          url: `/a/api/investor-entity/beneficial-owners/${id}/documents`,
          method: HttpMethods.Get,
        }),
        transformResponse: res => res.documents,
        providesTags: [Tags.InvestorEntityBeneficialOwners],
      });

      const sendInviteBeneficialOwner = builder.mutation<any, any>({
        query: ({ id, ...data }) => ({
          url: `/a/api/investor-entity/${id}/beneficial-owners/invite`,
          method: HttpMethods.Post,
          data,
        }),
        transformResponse: res => res.beneficialOwner,
        invalidatesTags: [Tags.InvestorEntityBeneficialOwners],
      });

      return {
        createBeneficialOwner,
        getBeneficialOwners,
        getRequiredDocumentsForBeneficialById,
        getDocumentStatusForBeneficial,
        sendInviteBeneficialOwner,
      };
    },
  });
