import { AdvisoryRelationship } from '@yieldstreet/tool-kit';

import { InvestorEntityIdentityProvider } from '../../types';
import {
  EntityType,
  IdentityDocumentType,
  InvestorEntityProvider,
  InvestorEntityTypeName,
  InvestorEntityTypeValue,
  IraAccountType,
} from '../../enums';
import { AIQ } from '../accreditation';
import { InvestorEntityProviderDetails } from './investor-entity-provider';

export enum InvestorEntityFlow {
  YS = 'YS',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum ManagementStrategy {
  Discretionary = 'DISCRETIONARY',
  Self = 'SELF',
}

export type InvestorEntity<Provider extends InvestorEntityProvider = InvestorEntityProvider> = {
  active: boolean;
  aiqs: AIQ[];
  address: InvestorEntityAddress;
  affiliationDetails: AffiliationDetails | null;
  advisoryRelationship: AdvisoryRelationship;
  createdDate: string | null;
  dateOfRecord: string | null;
  documentNumberProvided: boolean;
  employerName: string | null;
  hasMultipleOwners: boolean;
  id: number;
  investorAccounts: EntityInvestorAccount[];
  meta: InvestorEntityMeta | null;
  name: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  provider: InvestorEntityProviderDetails<Provider>;
  retirementAccount: boolean;
  signerTitle: string | null;
  type: InvestorEntityType;
  userId: number;
  walletEnabled: boolean;
  wealthSource: string | null;
  locked: boolean;
  kycProvider: InvestorEntityIdentityProvider;
};

export type EntityInvestorAccount = {
  complete: boolean;
  hasMultipleOwners: boolean;
  id: number;
  managementStrategy: `${ManagementStrategy}`;
  retirementAccount: boolean;
  walletEnabled: boolean;
  investorEntityId: string;
};

export type InvestorEntityType = {
  entityType: EntityType | null;
  identityDocumentType: IdentityDocumentType;
  iraAccountType: IraAccountType | null;
  name: InvestorEntityTypeName;
  value: InvestorEntityTypeValue;
};

export type InvestorEntityAddress = {
  addressComplete?: boolean;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryCode: string;
  postalCode: string;
  state: string;
  addressType?: string;
  primary?: boolean;
};

export type InvestorEntityMeta = {
  CIP_TAG: number;
  FINGERPRINT: string;
  STATUS: InvestorEntityMetaStatus;
  SYNAPSE_ID: string;
  TRUST_LEVEL: string;
  TRUST_SCORE: number;
};

type AffiliationDetails = {
  isPoliticallyExposed: boolean | null;
  isFamilyPoliticallyExposed: boolean | null;
  isFinraAffiliated: boolean | null;
  controlCorporations: Array<string> | null;
  affiliatedExchange: Array<string> | null;
};

export enum InvestorEntityMetaStatus {
  MISSING = 'MISSING',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED_RECEIVE = 'VERIFIED_RECEIVE',
  VERIFIED_SEND_AND_RECEIVE = 'VERIFIED_SEND_AND_RECEIVE',
  LOCKED = 'LOCKED',
  UNKNOWN = 'UNKNOWN',
}
