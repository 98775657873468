import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export const PageSection: FunctionComponent = ({ children }) => {
  return (
    <Container>
      <ContentHolder>{children}</ContentHolder>
    </Container>
  );
};

const Container = styled.div``;

const ContentHolder = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;
