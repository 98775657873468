import React, { useMemo } from 'react';

import { BaseProps } from '../../types';

import { Wrapper, Title, Subtitle, Icon, Copy, IconWrapper } from './OfferingTitle.style';
import { OfferingTitleProps, useTestIds } from './OfferingTitle.model';

import marketplaceOfferingDefault from './assets/marketplaceOfferingDefault.png';

export const OfferingTitle: React.FC<OfferingTitleProps & BaseProps> = ({
  title: titleProp,
  subtitle: subtitleProp,
  icon,
  inverse,
  compact,
  ...props
}) => {
  const testIds = useTestIds(props);

  const thumbnail = icon?.src || marketplaceOfferingDefault;

  const subtitle = useMemo(() => {
    if (!subtitleProp) {
      return null;
    }

    if (typeof subtitleProp === 'string') {
      return (
        <Subtitle inverse={inverse} small {...testIds.contentSubtitle.attr}>
          {subtitleProp}
        </Subtitle>
      );
    }

    return subtitleProp;
  }, [inverse, testIds, subtitleProp]);

  const title = useMemo(() => {
    if (!titleProp) {
      return null;
    }

    if (typeof titleProp === 'string') {
      return (
        <Title type={compact ? 5 : 4} inverse={inverse} {...testIds.contentTitle.attr}>
          {titleProp}
        </Title>
      );
    }

    return titleProp;
  }, [compact, inverse, testIds, titleProp]);

  return (
    <Wrapper {...testIds.base.attr}>
      <IconWrapper>
        <Icon src={thumbnail} compact={compact} {...testIds.contentIcon.attr} />
      </IconWrapper>
      {subtitle || title ? (
        <Copy>
          {subtitle}
          {title}
        </Copy>
      ) : null}
    </Wrapper>
  );
};
