import { ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from './hook/usePortal';

interface PortalProps {
  children: ReactElement;
  portalId: string;
}

export const Portal = ({ children, portalId }: PortalProps) => {
  const element = usePortal(portalId);

  return element && createPortal(children, element);
};
