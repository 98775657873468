import { media } from '../../themes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 998;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.background_inverse};
  height: 68px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  transition: background 0.5s;

  &.transparent {
    background: rgba(255, 255, 255, 0);
    border-bottom: none;
  }
  box-sizing: border-box;
  ${media.large`
    height: 74px;
    padding: 0;
  `};
  ${media.print`
    display: none;
  `}
`;

export const Flex = styled.div`
  display: flex;
`;

export const LogoWrap = styled.div`
  display: flex;
  padding-left: 20px;

  a {
    display: flex;
  }

  img {
    width: 145px;
    min-height: 39px;
    ${media.large`
      width: 185px;
      height: 100%;
      min-height: 50px;
    `};
  }
`;

export const MenuContainer = styled.div<{ sideMenuOpen: boolean }>`
  position: fixed;
  bottom: 0;
  top: 67px;
  width: 100%;
  transition: opacity 0.5s;
  z-index: 997;
  left: ${props => (props.sideMenuOpen ? 0 : '-100%')};
  opacity: ${props => (props.sideMenuOpen ? 1 : 0)};
  ${media.large`
    position: static;
    opacity: 1;
    display: flex;
    align-self: center;
  `};
`;

export const Menu = styled.div<{ sideMenuOpen: boolean }>`
  position: fixed;
  bottom: 0;
  top: 67px;
  width: 100%;
  z-index: 998;
  background: ${props => props.theme.colors.background_inverse};
  overflow-y: auto;
  left: ${props => (props.sideMenuOpen ? 0 : '-100%')};
  transition: left 0.5s;
  ${media.tablet`
    width: 320px;
  `};
  ${media.large`
    position: static;
    bottom: 0;
    top: auto;
    width: auto;
    overflow-y: visible;
  `};
`;

export const MenuItemsContainer = styled.div`
  ${media.large`
    flex-grow: 1;
    display: flex;
    padding: 0 15px 0 15px;
    align-items: baseline;
  `};
`;

export const HamburgerWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
  ${media.tablet`
  	padding-left: 20px;
  `};
  ${media.large`
    display: none;
  `};
`;

export const SignButtons = styled.div`
  display: flex;
  flex-direction: column;
  > a {
    flex: 1;
    display: inline-flex;
  }
  ${media.large`
    display: none;
  `};
`;
