import { ColorSystemOptions } from '@mui/material';
import { ACTIVE } from '../palette/active';
import { BRAND } from '../palette/brand';
import { NEUTRALS } from '../palette/neutrals';
import { SEMANTIC } from '../palette/semantic';

export const light: ColorSystemOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: BRAND.midnightGreen['500'],
      dark: BRAND.midnightGreen['700'],
      light: BRAND.midnightGreen['400'],
      contrastText: NEUTRALS.white['50'],
    },
    secondary: {
      main: ACTIVE.mint['500'],
      dark: ACTIVE.mint['700'],
      light: ACTIVE.mint['300'],
      contrastText: BRAND.midnightGreen['500'],
    },
    info: {
      main: SEMANTIC.info['600'],
      dark: SEMANTIC.info['700'],
      light: SEMANTIC.info['400'],
      contrastText: NEUTRALS.white['50'],
    },
    error: {
      main: SEMANTIC.error['600'],
      dark: SEMANTIC.error['700'],
      light: SEMANTIC.error['300'],
      contrastText: NEUTRALS.white['50'],
    },
    success: {
      main: SEMANTIC.success['500'],
      dark: SEMANTIC.success['700'],
      light: SEMANTIC.success['300'],
      contrastText: NEUTRALS.white['50'],
    },
    warning: {
      main: SEMANTIC.warning['600'],
      dark: SEMANTIC.warning['700'],
      light: SEMANTIC.warning['500'],
      contrastText: NEUTRALS.white['50'],
    },
    FilledInput: {
      bg: NEUTRALS.white['50'],
      hoverBg: NEUTRALS.white['200'],
    },
    Button: {
      contained: {
        primary: {
          background: ACTIVE.mint['500'],
          color: BRAND.parkGreen['900'],
          colorInverse: NEUTRALS.white[50],
          hover: ACTIVE.mint['600'],
        },
        secondary: {
          background: BRAND.midnightGreen['500'],
          color: NEUTRALS.white['200'],
          hover: BRAND.midnightGreen['600'],
        },
      },
      marketing: {
        primary: {
          background: ACTIVE.chartreuse['400'],
        },
      },
    },
    Alert: {
      standard: {
        warning: {
          background: SEMANTIC.warning['100'],
          color: SEMANTIC.warning['900'],
        },
        error: {
          background: SEMANTIC.error['100'],
          color: SEMANTIC.error['900'],
        },
        info: {
          background: SEMANTIC.info['100'],
          color: SEMANTIC.info['900'],
        },
        success: {
          background: SEMANTIC.success['100'],
          color: SEMANTIC.success['900'],
        },
      },
      filled: {
        success: {
          color: SEMANTIC.success['900'],
        },
      },
    },
    common: {
      onBackgroundChannel: NEUTRALS.black['900'],
    },
  },
};
