import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { uniqueId } from 'lodash';

interface CollapsedNoteProps {
  fullNote: React.ReactChild;
}

const buttonId = uniqueId('btn_');
const containerId = uniqueId('acc_');

export const CollapsedNote: FunctionComponent<CollapsedNoteProps> = ({ fullNote, children }) => {
  const [showFullNote, setShowFullNote] = useState(false);

  return !showFullNote ? (
    <Container aria-labelledby={buttonId} id={containerId} aria-expanded="false">
      {children}
      <ReadMore
        as="span"
        id={buttonId}
        aria-controls={containerId}
        tabIndex={0}
        onClick={() => setShowFullNote(true)}
      >
        [read more]
      </ReadMore>
    </Container>
  ) : (
    <Container aria-labelledby={buttonId} id={containerId} aria-expanded="true">
      {fullNote}
    </Container>
  );
};

const Container = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.theme.colors.text_label_inverse};
  text-align: justify;
  padding: 5px 0;
  ${media.desktop`
    grid-column: 1/span 3;
  `};
`;

const ReadMore = styled.a`
  cursor: pointer;
  margin: 0 0 0 5px;
  color: ${props => props.theme.colors.text_label_inverse};
`;
