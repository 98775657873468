import { isNumber, sum, last } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import styled from 'styled-components';
import { DesktopOnly, MobileOnly } from '../../../utils';
import SwitchSelector from './SwitchSelector';
import { portfolioCalculator, totalAnnualizedReturn, Investment } from '@yieldstreet/tool-kit';

import EarningIcon from '../assets/earning-icon.svg';
import EarningPaymentsIcon from '../assets/earning-payments-icon.svg';
import PaymentsIcon from '../assets/payments-icon.svg';
import { rgba } from 'polished';
import { media } from '../../../themes';
import { Currency } from '../../multi-item-banner/sub-components/common/currency/Currency';
import { Heading, Label, Paragraph } from '../../../content';
import { theme2 as theme } from '../../../themes/theme2';

interface PortfolioSimulatorProps {
  investments: Investment[];
  amounts: number[];
  showTotalInvestment: boolean;
}

enum ChartDisplay {
  PAYMENTS,
  EARNINGS,
  BOTH,
}

const TabsMobileDesc = {
  [ChartDisplay.EARNINGS]: 'Earnings',
  [ChartDisplay.BOTH]: 'Earnings and Payments',
  [ChartDisplay.PAYMENTS]: 'Payments',
};

const TabsMobile = [
  {
    key: ChartDisplay.EARNINGS,
    icon: <img alt="Earning Icon" src={EarningIcon} />,
  },
  {
    key: ChartDisplay.BOTH,
    icon: <img alt="Earning / Payments Icon" src={EarningPaymentsIcon} />,
  },
  {
    key: ChartDisplay.PAYMENTS,
    icon: <img alt="Payments Icon" src={PaymentsIcon} />,
  },
];

const TabsDesktop = [
  {
    title: 'Earnings',
    key: ChartDisplay.EARNINGS,
  },
  {
    title: 'Both',
    key: ChartDisplay.BOTH,
  },
  {
    title: 'Payments',
    key: ChartDisplay.PAYMENTS,
  },
];

export const ColumnColors = [theme.colors.chart_3, theme.colors.chart_5, theme.colors.chart_10];
const ChartColors = [theme.colors.chart_1, theme.colors.chart_14];

const Chart = ({ investments, amounts, showTotalInvestment }: PortfolioSimulatorProps) => {
  const chartComponentRef = useRef(null);

  const [chartDisplay, setChartDisplay] = useState(ChartDisplay.BOTH);

  const calculatedAmounts = useMemo(
    () =>
      investments.map((investment, index) =>
        amounts && isNumber(amounts[index]) ? amounts[index] : investment.defaultAmount
      ),
    [investments, amounts]
  );

  const { investmentsData, globalEarnings } = useMemo(
    () => portfolioCalculator(investments, calculatedAmounts),
    [investments, calculatedAmounts]
  );

  const totals = useMemo(() => {
    return {
      earnings: last(globalEarnings) || 0,
      investment: sum(calculatedAmounts),
      targetAnnualized: totalAnnualizedReturn(investments, calculatedAmounts),
    };
  }, [investments, calculatedAmounts]);

  const options = useMemo(() => {
    let series: SeriesOptionsType[] = [];

    if (chartDisplay === ChartDisplay.PAYMENTS || chartDisplay === ChartDisplay.BOTH) {
      series = investmentsData.map((investment, index) => {
        return {
          type: 'column',
          name: investment.name,
          data: investment.payments,
          color: ColumnColors[index],
        };
      });
    }

    const categoriesSize = Math.max(globalEarnings.length - 2, 0);

    const categories: string[] = ['Today', ...Array(categoriesSize).fill('')];

    investments.forEach(investment => {
      categories[investment.term - 1] = `${investment.term} ${investment.termSuffix}`;
    });

    if (chartDisplay === ChartDisplay.EARNINGS || chartDisplay === ChartDisplay.BOTH) {
      series = [
        ...series,
        {
          lineColor: ChartColors[0],
          fillOpacity: 0.5,
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, rgba(ChartColors[0], 0.7)],
              [1, rgba(ChartColors[1], 0)],
            ],
          },
          threshold: null,
          name: 'Earnings',
          type: 'area',
          data: globalEarnings,
        },
      ];
    }

    return {
      credits: {
        enabled: false,
      },
      chart: {
        height: 208,
        marginRight: 15,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        valueDecimals: 0,
        valuePrefix: '$',
      },
      yAxis: {
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${value}',
        },
        gridLineDashStyle: 'dash',
        min: 0,
        title: {
          text: '',
        },
        stackLabels: {
          enabled: false,
        },
      },
      xAxis: {
        labels: {
          autoRotation: [0],
          allowOverlap: false,
          overflow: 'allow',
          padding: 10,
          align: 'center',
          y: 25,
        },
        categories: categories,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
      },
      series,
    };
  }, [investmentsData, chartDisplay]);

  return (
    <>
      <OuterContainer>
        <DetailsContainer>
          <EarningsContainer>
            <Label>Total earnings</Label>
            <Heading type={4}>
              <Currency doNotRound forcePlaces={2} value={totals.earnings} />
            </Heading>
            {investments.length > 1 ? (
              <MobileOnly>
                <MobileTargetContainer>
                  <Label small>Target annualized return {`  `}</Label>
                  <LabelTargetAnnualized small semiBold>
                    {totals.targetAnnualized}%
                  </LabelTargetAnnualized>
                </MobileTargetContainer>
              </MobileOnly>
            ) : (
              ''
            )}
          </EarningsContainer>
          {investments.length > 1 ? (
            <>
              <TargetContainer>
                <DesktopOnly>
                  <Label>Total investment</Label>
                  <Heading type={5}>
                    <Currency doNotRound forcePlaces={4} value={totals.investment} />
                  </Heading>
                </DesktopOnly>
              </TargetContainer>
              <TargetContainer>
                <DesktopOnly>
                  <Label>Target annualized return</Label>
                  <Heading type={5}>{totals.targetAnnualized}%</Heading>
                </DesktopOnly>
              </TargetContainer>
            </>
          ) : (
            ''
          )}
          <ButtonContainer>
            <MobileOnly>
              <SwitchSelector
                tabs={TabsMobile}
                onClick={tab => setChartDisplay(+tab.key)}
                activeKey={chartDisplay}
              />
              <Label small>{TabsMobileDesc[chartDisplay]}</Label>
            </MobileOnly>
            <DesktopOnly>
              <SwitchSelector
                tabs={TabsDesktop}
                onClick={tab => setChartDisplay(+tab.key)}
                activeKey={chartDisplay}
              />
            </DesktopOnly>
          </ButtonContainer>
        </DetailsContainer>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
      </OuterContainer>
      {showTotalInvestment && (
        <TotalInvestment>
          <Paragraph semiBold>Total investment</Paragraph>
          <Paragraph semiBold>
            <Currency doNotRound forcePlaces={4} value={totals.investment} />
          </Paragraph>
        </TotalInvestment>
      )}
    </>
  );
};

const OuterContainer = styled.div`
  padding: 16px;
  ${media.desktop`
    padding: 32px;
  `}
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  ${media.large`
    padding: 0 10px 30px 10px;
    flex-direction: row;
    align-items: center;
  `}
`;

const EarningsContainer = styled.div`
  flex: 1 0;
  max-width: 280px;
  ${media.large`
    border-right: 1px solid ${props => props.theme.colors.border_default};
  `}
`;

const TargetContainer = styled.div`
  ${media.desktop`
    padding: 0 20px;
  `}
`;

const MobileTargetContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  flex: 1 0;
  max-width: 284px;
  text-align: center;
  margin-left: auto;
`;

const LabelTargetAnnualized = styled(Label)`
  padding-left: 5px;
`;

const TotalInvestment = styled.div`
  background: ${props => props.theme.colors.background_card};
  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.25), 0px 13px 27px -5px rgba(50, 50, 93, 0.2);
  border-radius: 4px;
  bottom: -30px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  position: absolute;
  width: 100%;
  /* top: 70px; */
  /* display: none; */
`;

export default Chart;
