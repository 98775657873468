import { OfferingV5 } from '../../types';
import {
  AccreditationEligibility,
  AssetClassType,
  AssetTypes,
  InvestmentStrategyType,
  OfferingStatus,
} from '../../enums';

export enum MarketplaceFilterQuerySortKey {
  Published = 'RECENTLY_UPDATED',
  Term = 'TERM',
  MinInvestment = 'MINIMUM_INVESTMENT',
}

export enum RolloverFundingEnum {
  Any = 'ANY',
  Accept = 'ACCEPT',
  Deny = 'DENY',
}

export enum MarketplaceFilterQuerySortDirection {
  Descending = 'DESC',
  Ascending = 'ASC',
}

export enum MarketplaceFilterQueryParameters {
  AccreditationEligibility = 'accreditationEligibility',
  AssetClass = 'assetClasses',
  AssetType = 'assetTypes',
  Attributes = 'attributes',
  InvestmentStrategy = 'investmentStrategy',
  IraEligible = 'preventIraFromInvesting',
  MaxTerm = 'maxTerm',
  MinInvestmentHigh = 'minInvestmentHigh',
  MinInvestmentLow = 'minInvestmentLow',
  MinTerm = 'minTerm',
  Order = 'order',
  Page = 'page',
  PageSize = 'pageSize',
  Sort = 'sortBy',
  Statuses = 'statuses',
  TimestampForPulldownRefetch = 'timestampForPulldownRefetch',
  IncludeFundingProgress = 'includeFundingProgress',
  RolloverFunding = 'rolloverFunding',
}

export interface OfferingsAttributesType {
  weightedAverageReturn: number;
}

export enum GetRecommendededOffersSourceType {
  Advice = 'ADVICE',
  Goals = 'GOALS',
  WalletBalance = 'WALLET_BALANCE',
}

export interface GetRecommendededOffersRequest {
  userId: number;
  source?: GetRecommendededOffersSourceType;
  limit?: number;
}

export interface GetRecommendedOffersApiResponse {
  success: boolean;
  offerings: OfferingV5[];
}

export interface GetOfferingsApiRequest {
  accreditation?: AccreditationEligibility;
  offeringType: 'open' | 'coming-soon';
  noteUrlHash?: string[];
}

export interface GetProgressApiRequest {
  key: string;
  noteUrlHashes?: string[];
}

export interface MarketplaceFilterAttributes {
  weightedAverageReturn: boolean;
}

export interface GetPaginatedOfferingsApiRequest {
  [MarketplaceFilterQueryParameters.AccreditationEligibility]?: `${AccreditationEligibility}`[];
  [MarketplaceFilterQueryParameters.AssetClass]?: `${AssetClassType}`[];
  [MarketplaceFilterQueryParameters.AssetType]?: `${AssetTypes}`;
  [MarketplaceFilterQueryParameters.Attributes]?: MarketplaceFilterAttributes;
  [MarketplaceFilterQueryParameters.InvestmentStrategy]?: `${InvestmentStrategyType}`;
  [MarketplaceFilterQueryParameters.IraEligible]?: boolean;
  [MarketplaceFilterQueryParameters.MaxTerm]?: number;
  [MarketplaceFilterQueryParameters.MinInvestmentHigh]?: number;
  [MarketplaceFilterQueryParameters.MinInvestmentLow]?: number;
  [MarketplaceFilterQueryParameters.MinTerm]?: number;
  [MarketplaceFilterQueryParameters.Order]?: `${MarketplaceFilterQuerySortDirection}`;
  [MarketplaceFilterQueryParameters.Page]: number;
  [MarketplaceFilterQueryParameters.PageSize]: number;
  [MarketplaceFilterQueryParameters.Sort]?: `${MarketplaceFilterQuerySortKey}`;
  [MarketplaceFilterQueryParameters.Statuses]: `${OfferingStatus}`[];
  [MarketplaceFilterQueryParameters.TimestampForPulldownRefetch]?: number;
  [MarketplaceFilterQueryParameters.IncludeFundingProgress]?: boolean;
  [MarketplaceFilterQueryParameters.RolloverFunding]?: `${RolloverFundingEnum}`;
}

export interface GetPaginatedOfferingsApiResponse {
  attributes: OfferingsAttributesType;
  currentPage: number;
  hasMore: boolean;
  offerings: OfferingV5[];
  success: boolean;
  totalCount: number;
  totalPages: number;
}
