import type { Components, CssVarsTheme } from '@mui/material';

export const MuiAlert: Components<CssVarsTheme>['MuiAlert'] = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: () => ({
      fontWeight: '400',
    }),
  },
  variants: [
    {
      props: { severity: 'success', variant: 'standard' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.Alert?.standard?.success?.background,
        color: theme.palette.Alert?.standard?.success?.color,
      }),
    },
    {
      props: { severity: 'info', variant: 'standard' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.Alert?.standard?.info?.background,
        color: theme.palette.Alert?.standard?.info?.color,
      }),
    },
    {
      props: { severity: 'warning', variant: 'standard' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.Alert?.standard?.warning?.background,
        color: theme.palette.Alert?.standard?.warning?.color,
      }),
    },
    {
      props: { severity: 'error', variant: 'standard' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.Alert?.standard?.error?.background,
        color: theme.palette.Alert?.standard?.error?.color,
      }),
    },
    {
      props: { severity: 'success', variant: 'filled' },
      style: ({ theme }) => ({
        color: theme.palette.Alert?.filled?.success?.color,
      }),
    },
  ],
};
