import styled from 'styled-components';

import { media } from '../../themes/media';
import { Container } from '../container';

export const SectionContainer = styled(Container)`
  padding-top: var(--sectionContainerPaddingVertical, 20px);
  padding-bottom: var(--sectionContainerPaddingVertical, 20px);

  padding-left: var(--sectionContainerPaddingHorizontal, 20px);
  padding-right: var(--sectionContainerPaddingHorizontal, 20px);

  ${media.tablet`
    padding-top: var(--sectionContainerPaddingVertical, 40px);
    padding-bottom: var(--sectionContainerPaddingVertical, 40px);

    padding-left: var(--sectionContainerPaddingHorizontal, 35px);
    padding-right: var(--sectionContainerPaddingHorizontal, 35px);
  `}
`;
