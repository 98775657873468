import React, { FunctionComponent } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';
import { TextInput, TextInputProps } from './TextInput';
import { withFormikWeb } from '../common';

export interface MaskFormatOwnProps
  extends Omit<NumberFormatProps, 'onClick' | 'onChange' | 'onBlur' | 'suffix' | 'prefix'> {
  useFormattedValue?: boolean;
}

const MaskFormatInput: FunctionComponent<MaskFormatOwnProps & InputBaseComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, defaultValue, format, useFormattedValue, allowLeadingZeros, ...otherProps } =
    props;

  return (
    <NumberFormat
      {...otherProps}
      allowLeadingZeros={allowLeadingZeros}
      onValueChange={({ value, formattedValue }) => {
        onChange &&
          onChange({
            target: { value: useFormattedValue ? formattedValue : value },
          } as unknown as React.FormEvent<HTMLInputElement>);
      }}
      format={format}
    />
  );
};

export const MaskTextInput: FunctionComponent<MaskFormatOwnProps & TextInputProps> = props => {
  return (
    <TextInput
      {...props}
      maskClassName={
        props.value ? 'MuiInputLabel-shrink MuiInputLabel-filled Mui-focused Mui-focused' : ''
      }
      inputComponent={MaskFormatInput as React.ElementType<InputBaseComponentProps>}
    />
  );
};

export const FormMaskTextInput = withFormikWeb(MaskTextInput);
