import React, { useEffect, useRef, useState, FunctionComponent, useCallback } from 'react';
import styled, { css } from 'styled-components';
import type { Dictionary } from 'lodash';
import isEmpty from 'lodash/isEmpty';

import { media } from '../../themes/media';
import { ParagraphProps, ParagraphRegular } from '../../content/text/Paragraph';
import { Container, ContainerPadding } from '../../layout/container';
import {
  getNavItemVisible,
  NavigationInputProps,
  NavigationLink,
  useNavItemProps,
} from '../common';
import { DesktopOnly } from '../../utils';
import { NavigationConfig } from '../../types';

interface MenuItemProps extends ParagraphProps {
  isActive: boolean;
  isTab?: boolean;
}

interface LineProps {
  width: string;
  left: string;
  isTab?: boolean;
}
export interface SubmenuProps extends NavigationInputProps {
  menu: NavigationConfig;
  inverse?: boolean;
  sticky?: boolean;
  useTabs?: boolean;
  desktopStickyPx?: number;
  mobileStickyPx?: number;
  activeKey?: string;
  onClick?: (key: string) => void;
  className?: string;
  extra?: React.ReactNode;
  addTransition?: boolean;
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
}

export const Submenu: FunctionComponent<SubmenuProps> = props => {
  const {
    menu,
    inverse,
    sticky,
    desktopStickyPx = 74,
    mobileStickyPx = 66,
    activeKey,
    useTabs,
    onClick,
    className,
    extra = null,
    addTransition = false,
    preContent,
    postContent,
  } = props;
  const [activeItemProps, setActiveItemProps] = useState<LineProps | undefined>();
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  const refs = useRef<Dictionary<HTMLLIElement>>({});
  const menuRef = useRef<HTMLUListElement>(null);

  const menuUpdate = useCallback(() => {
    if (!activeKey || isEmpty(refs.current)) {
      setActiveItemProps(undefined);
      return;
    }

    const menuWidth = menuRef.current?.offsetWidth;
    const element = refs.current[activeKey];
    if (!element) {
      return;
    }

    const boundingClientRect = element.getBoundingClientRect();
    const width = boundingClientRect.width;
    const left = element.offsetLeft;
    const clientLeft = Math.round(boundingClientRect.left);

    if ((menuWidth && clientLeft + width >= menuWidth) || clientLeft < 0) {
      if (menuWidth && menuRef.current) {
        const sum =
          menuWidth / 2 - (menuWidth - clientLeft) + menuRef.current?.scrollLeft + width / 2;
        menuRef.current?.scrollTo({
          left: sum,
          behavior: 'smooth',
        });
      }
    }

    setActiveItemProps({ width: `${width}px`, left: `${left}px` });
  }, [activeKey]);

  // Fixes font slow loading issue with initial width calculation
  useEffect(() => {
    if (!fontsLoaded && activeKey) {
      setTimeout(() => {
        menuUpdate();
        setFontsLoaded(true);
      }, 350);
    }
  }, [activeKey, fontsLoaded, menuUpdate]);

  useEffect(() => {
    menuUpdate();
  }, [menuUpdate]);

  const navProps = useNavItemProps(props);

  const items = menu.map(item => {
    const { key } = item;
    const itemVisible = getNavItemVisible(item, navProps);
    return itemVisible ? (
      <MenuItem
        ref={element => {
          if (!element) return;
          refs.current[`${element.getAttribute('data-key')}`] = element;
        }}
        key={key}
        data-key={key}
        isActive={activeKey === key}
        isTab={useTabs}
        semiBold
        inverse={!inverse ? inverse : !(inverse && activeKey === key && useTabs)}
      >
        <NavigationLink
          navItem={{ ...item, callback: () => onClick && onClick(key) }}
          raw
          {...props}
        />
      </MenuItem>
    ) : null;
  });

  return (
    <Wrapper
      data-cy="submenu"
      sticky={sticky}
      desktopStickyPx={desktopStickyPx}
      mobileStickyPx={mobileStickyPx}
      inverse={inverse}
      addTransition={addTransition}
    >
      {preContent}
      <SContainer>
        <ScrollContainer>
          <MenuContainer className={className}>
            <MenuList useTabs={useTabs} ref={menuRef}>
              {items}
              {activeKey && (
                <Line data-cy="submenu-highlight" isTab={useTabs} {...activeItemProps} />
              )}
            </MenuList>
            {extra && (
              <ExtraContainer>
                <DesktopOnly>{extra}</DesktopOnly>
              </ExtraContainer>
            )}
          </MenuContainer>
        </ScrollContainer>
      </SContainer>
      {postContent}
    </Wrapper>
  );
};

const Wrapper = styled.div<Partial<SubmenuProps>>`
  background: ${props =>
    props.inverse ? props.theme.colors.background_inverse : props.theme.colors.background_default};
  padding: 0;
  width: 100%;
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  top: ${props => (props.sticky ? `${props.mobileStickyPx}px` : 'auto')};
  transition: ${props => props.addTransition && 'top 0.3s ease-out'};
  z-index: 100;
  overflow: hidden;
  ${media.large<Partial<SubmenuProps>>`
    top: ${props => (props.sticky ? `${props.desktopStickyPx}px` : 'auto')};
  `};
  ${media.print`
    display: none;
  `}
`;

const SContainer = styled(Container)`
  overflow-x: visible;
  ${media.tablet`
    padding: 0;
  `};
  padding: 0;
`;

const ScrollContainer = styled.div`
  height: 44px;
  ${media.large`
    height: 62px;
  `};
  overflow: hidden;
`;

const MenuContainer = styled.div`
  display: flex;
  height: calc(100% + 20px);
  overflow-y: hidden;
  justify-content: space-between;
  ${media.large`
    height: 100%;
  `};
`;

const MenuList = styled.ul<Partial<SubmenuProps>>`
  display: flex;
  flex-direction: row;
  padding: 0 0 20px 0;
  position: relative;
  ${props => css`
    margin: ${props.useTabs ? 0 : `0 20px`};
    ${media.large`
      margin: 0 35px;
    `};
  `};
  ${media.large`
    padding: 0;
  `};
`;

const MenuItem = styled.li<MenuItemProps>`
  display: block;
  box-sizing: content-box;
  ${props =>
    props.isTab
      ? css`
          margin: 0;
          padding: 0 10px;
          ${media.large`
            margin: 0 10px;
          `}
        `
      : css`
          margin: 0 10px;
          ${media.large`
            margin: 0 20px;
          `}
        `}
  &:first-child {
    margin-left: 0;
  }
  &:last-of-type {
    ${props =>
      props.isTab
        ? css`
            margin-right: 0;
          `
        : css`
            &::after {
              content: '';
              flex: 0 0 20px;
              height: 100%;
            }
          `}
  }
  height: 100%;
  display: flex;
  flex: 0 0;
  align-items: center;
  white-space: nowrap;
  a {
    ${ParagraphRegular}
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
`;

const Line = styled.div<Partial<LineProps>>`
  position: absolute;
  ${props =>
    props.isTab
      ? `height: 100%; top:0; background: ${props.theme.colors.background_highlight};`
      : `height: 5px; bottom: 20px; background: ${props.theme.colors.accent_strong};`};
  ${media.large<Partial<LineProps>>`
    bottom: ${props => (props.isTab ? '100%' : '0')};
  `};
  width: ${props => props.width};
  left: ${props => props.left};
  transition: all 0.3s ease;
  z-index: -1;
`;

const ExtraContainer = styled.div`
  ${ContainerPadding}
  align-self: center;
`;
