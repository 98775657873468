import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';

import { ModalTrigger, Triggers } from '../../utils';

import { Paragraph, Button, ButtonContainer } from '../../content';

import trustLevelIcon from './icons/trust-level.svg';

export interface PromoPopOver {
  testId?: string | number;
  title: string;
  description: string;
  icon: string;
  buttonText: string;
  storageId: string;
  onClick: Function;
  onClose: () => void;
}

interface Icon {
  [key: string]: string | undefined;
}

const ICONS: Icon = {
  trustLevelLock: trustLevelIcon,
};

export const PromoPopOver: FunctionComponent<PromoPopOver> = ({
  title,
  description,
  icon,
  buttonText,
  onClick,
  storageId,
  onClose,
}) => {
  return (
    <ModalTrigger
      triggerType={Triggers.LOAD}
      height={'auto'}
      width={'auto'}
      storageId={storageId}
      onClose={onClose}
    >
      <Container>
        <Icon src={ICONS[icon]} />
        <ModalTitle>{title}</ModalTitle>
        <Paragraph>{description}</Paragraph>

        <ButtonContainer>
          <Button onClick={() => onClick()}>{buttonText}</Button>
        </ButtonContainer>
      </Container>
    </ModalTrigger>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 335px;
  padding: 0 20px;

  ${media.tablet`
    max-width: 790px;
  `}

  ${media.desktop`
    max-width: 790px;
  `};
`;

const Icon = styled.img`
  max-width: 110px;
  margin-bottom: 30px;
`;

const ModalTitle = styled.h3`
  font-family: ${props => props.theme.typography.primary};
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 26px;

  ${media.tablet`
    font-size: 30px;
    line-height: 38px;
  `}

  ${media.desktop`
    font-size: 30px;
    line-height: 38px;
  `};
`;
