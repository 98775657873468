import { MuiAlert } from './muiAlert';
import { MuiButton } from './muiButton';
import { MuiLoadingButton } from './muiLoadingButton';
import { MuiTextField } from './muiTextField';
import { MuiFilledInput } from './muiFilledInput';
import { MuiLink } from './muiLink';
import { MuiTypography } from './muiTypography';

export const components = {
  MuiAlert,
  MuiButton,
  MuiLoadingButton,
  MuiTextField,
  MuiFilledInput,
  MuiLink,
  MuiTypography,
};
