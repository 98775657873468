import React from 'react';
import {
  NavigationItem,
  NavigationItemProps,
  NavigationValue,
  MaybeNavigationValue,
  SizeVariation,
  MaybeSizeVariation,
} from '../../types/components/navigation';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import { MobileOnly, DesktopOnly } from '../../utils';

export const isSizeVariation = <T extends any>(
  value: MaybeSizeVariation<T>
): value is SizeVariation<T> => {
  return isPlainObject(value);
};

export const normalizeSizeVariation = <T extends any>(
  value: MaybeSizeVariation<T>
): SizeVariation<T> => {
  if (isSizeVariation(value)) {
    return value;
  } else {
    return {
      mobile: value,
      desktop: value,
    };
  }
};

export const getSizeVariation = <T extends any>(
  value: MaybeSizeVariation<T>,
  isMobile?: boolean
): T => {
  if (isSizeVariation(value)) {
    if (isMobile) {
      return value.mobile;
    } else {
      return value.desktop;
    }
  } else {
    return value;
  }
};

export const renderSizeVariation = <T extends any>(
  value: MaybeSizeVariation<T>,
  renderValue: (value: T) => React.ReactNode
): React.ReactNode => {
  if (isSizeVariation(value)) {
    const mobileRender = renderValue(value.mobile);
    const desktopRender = renderValue(value.desktop);
    return (
      <>
        {desktopRender && <DesktopOnly>{desktopRender}</DesktopOnly>}
        {mobileRender && <MobileOnly>{mobileRender}</MobileOnly>}
      </>
    );
  } else {
    return renderValue(value);
  }
};

export const isNavigationValue = <T extends any>(
  value: MaybeNavigationValue<T>
): value is NavigationValue<T> => {
  return isFunction(value);
};

export const getNavigationValue = <T extends any>(
  value: MaybeNavigationValue<T>,
  props: NavigationItemProps,
  item: NavigationItem
): T => {
  if (isNavigationValue(value)) {
    return value(props, item);
  } else {
    return value;
  }
};
