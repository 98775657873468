import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Paragraph } from '../../content/text';
import { media } from '../../themes';
import { ExternalLink } from '../../content';
import WarningIcon from '../../assets/graphics/icons/warning.svg';
import CloseIcon from '../../assets/graphics/icons/close.svg';

interface TopNotificationProps {
  icon?: boolean;
  title?: React.ReactNode;
  description?: React.ReactNode;
  linkText?: string;
  path?: string;
  externalUrl?: string;
  backgroundColor?: string;
  dismissible?: boolean;
  dismissAction?: () => void;
}

export const TopNotification: FunctionComponent<TopNotificationProps> = ({
  icon = true,
  title,
  description,
  linkText,
  path,
  externalUrl,
  backgroundColor,
  dismissible,
  dismissAction,
}) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <TextWrapper>
        <Title>
          {icon && <Icon src={WarningIcon} />}
          {title && <Paragraph semiBold>{title}</Paragraph>}
        </Title>
        <Description>{description}</Description>
        {linkText && (
          <SExternalLink
            href={path || externalUrl}
            target={externalUrl ? '_blank' : ''}
            rel="noopener noreferrer"
          >
            {linkText}
          </SExternalLink>
        )}
      </TextWrapper>
      {dismissible && <Close src={CloseIcon} onClick={dismissAction} />}
    </Container>
  );
};

interface ContainerProps {
  backgroundColor?: string;
}

const Container = styled.div<ContainerProps>`
  background: ${props =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.background_default};
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  ${media.tablet`
    flex-direction: row;
    justify-content: center;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
    margin-right: 50px;
  `}
`;

const Icon = styled.img`
  padding: 0 0 2px;
  height: 23px;
  margin-right: 5px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
`;

const Description = styled(Paragraph)`
  margin: 5px 0;
  ${media.tablet`
    margin: 0 5px 0 5px;
  `}
`;

const SExternalLink = styled(ExternalLink)`
  width: fit-content;
`;

const Close = styled.img`
  cursor: pointer;
  height: 16px;
  ${media.tablet`
    align-self: center;
  `}
`;
