import { restApi, Tags } from '../../common';

import { HttpMethods } from '../../../enums';
import {
  PortfolioPaymentActivityRequest,
  PortfolioPaymentActivityResponse,
  PortfolioTransactionsPaymentsRequest,
  PortfolioTransactionsPaymentsResponse,
  PortfolioPaymentsYearsApiResponse,
  PortfolioPaymentsYearsRequest,
  PortfolioPaymentsYearsResponse,
} from './payments.model';

export const paymentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.PortfolioPayments,
      Tags.PortfolioPaymentsYears,
      Tags.PortfolioTransactionPayments,
    ],
  })
  .injectEndpoints({
    endpoints: build => {
      const getPaymentActivity = build.query<
        PortfolioPaymentActivityResponse,
        PortfolioPaymentActivityRequest
      >({
        query: ({
          end,
          investorId,
          noteUrlHash,
          start,
          userId,
          managementStrategy,
        }: PortfolioPaymentActivityRequest) => ({
          url: `/api/portfolio/${userId}/payment-activity`,
          method: HttpMethods.Get,
          params: {
            end,
            investorAccountId: investorId,
            noteUrlHash,
            start,
            managementStrategy,
          },
        }),
        providesTags: [Tags.PortfolioPayments],
      });

      const getPayments = build.query<
        PortfolioTransactionsPaymentsResponse,
        PortfolioTransactionsPaymentsRequest
      >({
        query: ({
          investorId,
          loanId,
          noteUrlHash,
          page,
          pageSize,
          paymentType,
          status,
          userId,
          year,
          returnsOnly,
          managementStrategy,
        }: PortfolioTransactionsPaymentsRequest) => ({
          url: `/a/api/portfolio/payments/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            loanId,
            noteUrlHash,
            page,
            pageSize,
            paymentType,
            status,
            year,
            returnsOnly,
            managementStrategy,
          },
        }),
        providesTags: [Tags.PortfolioTransactionPayments],
      });

      const getPaymentsYears = build.query<
        PortfolioPaymentsYearsResponse,
        PortfolioPaymentsYearsRequest
      >({
        query: ({
          investorId,
          loanId,
          noteUrlHash,
          page,
          pageSize,
          paymentType,
          returnsOnly,
          status,
          userId,
          managementStrategy,
        }: PortfolioPaymentsYearsRequest) => ({
          url: `/a/api/portfolio/payments/${userId}/available-years`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            loanId,
            noteUrlHash,
            page,
            pageSize,
            paymentType,
            returnsOnly,
            status,
            managementStrategy,
          },
        }),
        providesTags: [Tags.PortfolioPaymentsYears],
        transformResponse: ({ years }: PortfolioPaymentsYearsApiResponse) => ({
          years,
        }),
      });

      return {
        getPaymentActivity,
        getPayments,
        getPaymentsYears,
      };
    },
  });
