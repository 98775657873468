import styled from 'styled-components';
import { SectionContainer } from './../../layout/section-container/SectionContainer';
import { media } from '../../themes/media';
import { ExternalLink, Heading } from '../../content';
import { NAV_HEIGHT } from '../../navigation/header/HeaderViewLoggedOut/HeaderViewLoggedOut.styles';

export const Wrapper = styled(SectionContainer)`
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 0;

  ${media.desktop`
    flex-direction: row-reverse;
    padding: 49px 0;
    overflow: hidden;
  `}
`;

export const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background_default};
  position: sticky;
  overflow: hidden;
  top: ${() => `${NAV_HEIGHT}px`};

  ${media.desktop`
    flex-shrink: 0;
    max-width: 576px;
    position: relative;
    top: 0;
  `}

  img {
    display: block;
    max-width: 100%;
  }
`;

export const Image = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.colors.background_default};
  position: ${props => (props.visible ? 'static' : 'absolute')};
  top: 0;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
  width: 100%;
`;

export const TabsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.m};
  ${media.desktop`
    display:flex;
    flex-direction: column;
    margin-right: ${({ theme }) => theme.spacing.xl};
    margin-top: 0;
  `}
`;

export const Tab = styled.div<{ active: boolean }>`
  cursor: pointer;
  &:hover {
    background: rgba(223, 236, 255, 0.12); // not in theme, request by design
  }
  border-left: solid 4px
    ${({ theme, active }) => (active ? theme.colors.border_outline : theme.colors.border_default)};
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.ml}`};
  user-select: none;
  text-align: left;
`;

export const IconWrapper = styled.div``;

export const Title = styled(Heading)`
  margin: ${({ theme }) => `${theme.spacing.sm} 0 ${theme.spacing.xs}`};
`;

export const Button = styled(ExternalLink)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing.sm};
`;
