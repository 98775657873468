import styled from 'styled-components';

import { GetSizePropsParams, GetTypePropsParams, SurfaceProps, SurfaceType } from './Surface.model';

const getSizeProps = ({ small, theme, withPadding }: GetSizePropsParams) => {
  if (!withPadding) {
    return 'padding: 0';
  }

  if (small) {
    return `padding: ${theme.spacing.sm}`;
  }

  return `padding: ${theme.spacing.m}`;
};

const getTypeProps = ({ theme, type }: GetTypePropsParams) => {
  switch (type) {
    case SurfaceType.Default:
      return `
        background-color: ${theme.colors.background_light};
      `;

    case SurfaceType.Highlight:
      return `
        background-color: ${theme.colors.background_light};
        border-color: ${theme.colors.border_default};
      `;

    case SurfaceType.Outline:
      return `
          background-color: ${theme.colors.background_default};
          border-color: ${theme.colors.border_default};
        `;

    case SurfaceType.Skeleton:
      return `
          border: 1px dashed ${theme.colors.border_strong};
        `;
  }
};

export const SurfaceWrapper = styled.div<Omit<SurfaceProps, 'children'>>`
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radius.lg};
  ${({ small, theme, withPadding }) => getSizeProps({ small, theme, withPadding })};
  ${({ theme, type }) => getTypeProps({ theme, type })};
`;
