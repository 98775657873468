import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Paragraph } from '../../content/text';
import { ExternalLink } from '../../content/link';
import CloseIcon from '../../assets/graphics/icons/close.svg';

interface SurveyBoxProps {
  text?: string;
  linkText?: string;
  path?: string;
  dismiss?: () => void;
  onClick?: () => void;
}

export const SurveyBox: FunctionComponent<SurveyBoxProps> = ({
  text,
  linkText,
  path,
  dismiss,
  onClick,
}) => (
  <Wrapper>
    <Container>
      <Paragraph inverse>{text}</Paragraph>
      <ExternalLink inverse href={path} onClick={onClick}>
        {linkText}
      </ExternalLink>
      <CloseImg src={CloseIcon} onClick={dismiss} />
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
  position: fixed;
  width: 360px;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 5%;
  z-index: 4;

  ${media.tablet`
    width: 700px;
    bottom: 10%;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.colors.background_inverse};
  padding: 24px 48px;
  border-radius: 50px;
  position: relative;
  ${media.desktop`
    flex-direction: row;
    justify-content: space-between;

  `}
`;

const CloseImg = styled.img`
  position: absolute;
  top: -10px;
  right: 10px;
  cursor: pointer;
  height: 28px;
  background: ${props => props.theme.colors.background_default};
  padding: 5px;
  border-radius: 50px;
  border: 1px solid ${props => props.theme.colors.border_default};
`;
