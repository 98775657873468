import React from 'react';
import styled, { css } from 'styled-components';
import { Paragraph, Label } from '../../../content';
import { media } from '../../../themes';

interface Option {
  title: string;
  desc: string;
  key: string | number;
}

interface SwitchSelectorProps {
  options: Option[];
  activeKey?: string | number;
  onClick?: (tab: Option) => void;
}

const OptionSelector = ({ options, activeKey, onClick }: SwitchSelectorProps) => {
  return (
    <OuterContainer>
      <StrategySelector>
        {options?.map((option, key) => (
          <Strategy
            key={key}
            active={option.key === activeKey}
            onClick={() => {
              onClick && onClick(option);
            }}
          >
            <Paragraph semiBold>{option.title}</Paragraph>
            <Label semiBold>{option.desc}</Label>
          </Strategy>
        ))}
      </StrategySelector>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  height: 210px;
  justify-content: space-between;
  flex-direction: column;
  ${media.desktop`
    height: 100%;
    flex-direction: row;
  `}
`;

const StrategySelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
  width: 280px;
`;

const Strategy = styled.div<{ active?: boolean }>`
  background-color: ${props => props.theme.colors.background_card};
  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.colors.background_inverse};
      p {
        color: ${props => props.theme.colors.text_paragraph_inverse};
      }
      span {
        color: ${props => props.theme.colors.text_label_inverse} !important;
      }
    `}
  &:hover {
    background-color: ${props => props.theme.colors.background_inverse};
    p {
      color: ${props => props.theme.colors.text_paragraph_inverse};
    }
    span {
      color: ${props => props.theme.colors.text_label_inverse}!important;
    }
  }
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 15px;
`;

export default OptionSelector;
