import React, { useCallback } from 'react';
import styled from 'styled-components';

interface HamburgerProps {
  opened?: boolean;
  inverse?: boolean;
  onChange: (opened?: boolean) => void;
}

export const Hamburger: React.FunctionComponent<HamburgerProps> = ({
  opened,
  inverse,
  onChange,
}) => {
  const toggleHamburger = useCallback(() => {
    onChange && onChange(!opened);
  }, [opened, onChange]);

  return (
    <Bun
      tabIndex={0}
      onClick={toggleHamburger}
      clicked={opened}
      aria-label="Main Menu"
      aria-expanded={opened}
      aria-haspopup={true}
      inverse={inverse}
    >
      <Meat inverse={inverse} />
    </Bun>
  );
};

const Meat = styled.span<{ inverse?: boolean }>`
  display: block;
  width: 30px;
  height: 3px;
  border-radius: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s;
  background: ${props =>
    props.inverse ? props.theme.colors.icon_inverse : props.theme.colors.icon_default};
`;

interface BunProps {
  clicked?: boolean;
  inverse?: boolean;
}

const Bun = styled.button<BunProps>`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  position: relative;
  width: 30px;
  vertical-align: top;
  &:before,
  &:after {
    transition: transform 0.5s;
    content: '';
    background: ${props =>
      props.inverse ? props.theme.colors.icon_inverse : props.theme.colors.icon_default};
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 1px;
    position: absolute;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  ${props =>
    props.clicked &&
    `
    &:before, &:after {
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    span {
      opacity: 0;
    }
  `};
`;
