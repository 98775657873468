import styled from 'styled-components';
import { friendlyMedia } from '../../themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${friendlyMedia.tablet`
    align-items: center;
    text-align: center;
  `};
`;

export const ContentWrapper = styled.div``;

export const IconWrapper = styled.div<{ centered?: boolean }>`
  margin-top: ${props => props.theme.spacing.m};
  margin-bottom: ${props => props.theme.spacing.l};
  ${props => props.centered && `text-align: center;`};
  ${friendlyMedia.tablet`
    margin-top: 0;
    text-align: center;
  `};
`;

export const Icon = styled.img``;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing.l};
  gap: ${props => props.theme.spacing.s};
  flex-direction: column-reverse;
  width: 100%;

  ${friendlyMedia.tablet`
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    width: auto;
  `};
`;
