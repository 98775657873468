import type { Components, CssVarsTheme } from '@mui/material';

export const MuiButton: Components<CssVarsTheme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    size: 'large',
  },
  variants: [
    {
      props: { size: 'large' },
      style: {
        padding: '10px',
      },
    },
    {
      props: { variant: 'contained', color: 'primary' },
      style: ({ theme }) => ({
        color: theme.palette.Button?.contained.primary?.color,
        '&:hover': {
          backgroundColor: theme.palette.Button?.contained?.primary?.hover,
        },
        backgroundColor: theme.palette.Button?.contained?.primary?.background,
      }),
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: ({ theme }) => ({
        color: theme.palette.Button?.contained.secondary?.color,
        '&:hover': {
          backgroundColor: theme.palette.Button?.contained?.secondary?.hover,
        },
        backgroundColor: theme.palette.Button?.contained?.secondary?.background,
      }),
    },
    {
      props: { variant: 'marketing' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.Button?.marketing?.primary?.background,
      }),
    },
  ],
  styleOverrides: {
    root: () => ({
      textTransform: 'none',
      borderRadius: '15rem',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
  },
};
