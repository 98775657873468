import React from 'react';

import { PageContainerContent, PageContainerWrapper } from './PageContainer.style';
import { PageContainerProps } from './PageContainer.model';

export const PageContainer = ({
  children,
  id,
  light = false,
  ...containerProps
}: PageContainerProps) => {
  return (
    <PageContainerWrapper id={id} light={light}>
      <PageContainerContent {...containerProps}>{children}</PageContainerContent>
    </PageContainerWrapper>
  );
};
