import axios, { Method, ResponseType } from 'axios';

type HttpMethod = Method;

export type HttpRequestParams = {
  url: string;
  method?: HttpMethod;
  data?: any;
  headers?: any;
  params?: any;
  paramsSerializer?: (params: any) => string;
  responseType?: ResponseType;
};

export const httpRequest = async (params: HttpRequestParams) => await axios(params);
