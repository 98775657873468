import { SignupPortfolioValues } from '@yieldstreet/platform-kit';

export const SignupPortfolioOptions = [
  {
    value: SignupPortfolioValues.UNDER_50K,
    label: 'Less than $50k',
  },
  {
    value: SignupPortfolioValues.BETWEEN_50_250,
    label: '$50k-$250k',
  },
  {
    value: SignupPortfolioValues.BETWEEN_250_1M,
    label: '$250k-$1M',
  },
  {
    value: SignupPortfolioValues.BETWEEN_1M_5M,
    label: '$1M-$5M',
  },
  {
    value: SignupPortfolioValues.MORE_5M,
    label: '$5M+',
  },
];

export const SignupPortfolioLabels = {
  title: 'What is the approximate size of your portfolio?',
  subtitle:
    'Investments on Yieldstreet have a variety of yields, terms, and minimums. Understanding the size of your overall investment portfolio helps us assess which products may best meet your objectives.',
};
