import styled from 'styled-components';
import { rgba } from 'polished';

import { LabelStyleSmall, Paragraph, ParagraphSmall } from '../../content/text';
import { LinkStyleProps, LinkStyles } from '../../content/link';
import { media } from '../../themes/media';

interface DisclosureProps {
  show: boolean;
}

export const FooterWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.primary};
  background: ${({ theme }) => theme.colors.footer_primary};
  padding: ${({ theme }) => theme.spacing.l} 0;

  @media print {
    display: none !important;
  }
`;

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 -${({ theme }) => theme.spacing.s};
  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
  `};
  ${media.large`
    grid-template-columns: 1.5fr 1fr 1fr;
  `};
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border_strong};
  margin: ${({ theme }) => theme.spacing.m} 0;
  opacity: 0.5;
`;

export const LegalTextGraph = styled.div`
  p {
    font-family: ${({ theme }) => theme.typography.primary};
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.text_label_inverse};
  }
  a {
    ${LinkStyles}
    ${ParagraphSmall}
    color: ${({ theme }) => theme.colors.text_label_inverse};
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.colors.text_label_inverse};
    }
  }
  text-align: justify;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  ${media.desktop`
    grid-column: 1/span 3;
  `};
`;

export const DisclosureToggle = styled.div<DisclosureProps>`
  position: ${({ show }) => (show ? 'relative' : 'absolute')};
  background: linear-gradient(
    180deg,
    ${({ theme }) => rgba(theme.colors.footer_primary, 0.1)} 20%,
    ${({ theme }) => rgba(theme.colors.footer_primary, 0.8)} 60%,
    ${({ theme }) => rgba(theme.colors.footer_primary, 0.9)} 80%
  );
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const DisclosureToggleText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text_header_inverse};
  padding: ${({ theme }) => theme.spacing.m};
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

export const LegalTextContainer = styled.div<DisclosureProps>`
  padding: ${({ theme }) => theme.spacing.xs} 0;
  overflow: hidden;
  max-height: ${({ show }) => (show ? '2000px' : '150px')};
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.text_label_inverse};
  }

  p {
    text-align: justify;
    line-height: 18px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text_label_inverse};
    padding: 0 0 ${({ theme }) => theme.spacing.m};
  }
  #footnote-read-more-2 {
    padding-top: ${({ theme }) => theme.spacing['4xl']} !important;
  }
  #footnote-read-more-3 {
    padding-top: ${({ theme }) => theme.spacing['4xl']};
  }
`;

export const BottomMenuWrapper = styled.div`
  a,
  p,
  button {
    margin-right: ${({ theme }) => theme.spacing.m};
    padding: ${({ theme }) => theme.spacing.s} 0 !important;
    display: inline-block;
    background: none !important;
  }
`;

export const CopyrightLine = styled.span`
  padding: ${({ theme }) => theme.spacing.l} 0 ${({ theme }) => theme.spacing.s};
  ${LabelStyleSmall}
`;

export const Copyright = styled.div`
  display: block;
  text-align: left;
  padding: ${({ theme }) => theme.spacing.m} 0;
  ${media.desktop`
    grid-column: 1/span 3;
  `};
  ${LabelStyleSmall}
  a, p {
    color: ${({ theme }) => theme.colors.text_label_inverse};
    &:hover {
      color: ${({ theme }) => theme.colors.text_label_inverse};
    }
  }
`;

export const MenuContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.s} 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  ${media.tablet`
    flex-wrap: nowrap;
  `};
  ${media.desktop`
    grid-column: 1/span 2;
  `};
`;

export const MenuSection = styled.div`
  width: 50%;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  ${media.desktop`
    margin-bottom:0;
  `};
  ${media.tablet`
    width: 25%;
  `};

  & > ul > li {
    line-height: 28px;
  }

  a,
  span {
    ${LinkStyles}
    ${ParagraphSmall}
    color: ${({ theme }) => theme.colors.text_secondary_paragraph_inverse};
    &:hover {
      color: ${({ theme }) => theme.colors.text_label_inverse};
    }
    padding-bottom: ${({ theme }) => theme.spacing.s};
    padding-top: ${({ theme }) => theme.spacing.s};
    display: inline-block;
    min-width: 30%;
    font-size: 16px;
    ${media.desktop`
      display: inline;
      padding-bottom: 0;
      padding-top: 0;
      margin-bottom: ${({ theme }) => theme.spacing.m};
      font-size: 14px;
    `}
    background-image: none;
  }
`;

export const MenuSectionTitle = styled(Paragraph)`
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

export const LogoHolder = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.s};
  img {
    height: 38px;
  }
  text-align: center;
  a {
    background: none;
    margin: ${({ theme }) => `0 ${theme.spacing.s} ${theme.spacing.m} ${theme.spacing.s}`};
    ${media.desktop`
      margin: ${({ theme }) => `0 0 ${theme.spacing.m} ${theme.spacing.s}`};
      display: inline-block;
    `};
    line-height: 0;
    padding: 0;
  }
  ${media.desktop`
    align-self: top;
    text-align: right;
  `};
`;

export const AddressBarHolder = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
  `};
`;

export const AddressContainer = styled.div`
  ${ParagraphSmall}
  width: 100%;
  p {
    margin: 0 0 ${({ theme }) => theme.spacing.s} 0;
    color: ${({ theme }) => theme.colors.text_label_inverse};
    ${media.desktop`
      margin: 0;
      display: inline-block;
    `};
  }
  a {
    color: ${({ theme }) => theme.colors.text_label_inverse};
    &:hover {
      color: ${({ theme }) => theme.colors.text_label_inverse};
    }
    ${media.desktop`
      margin: 0 0 0 ${({ theme }) => theme.spacing.m};
      display: inline-block;
    `};
  }
`;

export const ContentHolder = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.s};

  ${media.desktop`
    margin: 0;
    text-align: right;
  `};
`;

export const Email = styled.a`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.colors.text_label_inverse};

  &:hover {
    color: ${({ theme }) => theme.colors.text_label_inverse};
  }
  ${media.desktop`
    margin: 0;
  `};
`;

export const AppDownloadLinks = styled.div``;

export const SocialContainer = styled(Paragraph)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  display: flex;
  justify-content: center;
  a {
    color: ${({ theme }) => theme.colors.text_label_inverse};
    background: none;
    display: inline-block;
    margin: 0 ${({ theme }) => theme.spacing.s};
    font-size: 20px;

    svg {
      color: white;
    }
  }
  ${media.desktop`
    display: inline-block;
    width: auto;
    justify-content: space-between;
  `};
`;

export const LinkNoHref = styled.p<LinkStyleProps>`
  display: inline;
  ${LinkStyles}
`;
