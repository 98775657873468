import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import Lottie from 'react-lottie';
import { isString, isObject, find } from 'lodash';
import { ExternalLink } from '../../../content/link/ExternalLink';
import styled from 'styled-components';
import { friendlyMedia } from '../../../themes/media';
import {
  NavigationConfig,
  NavigationItem,
  NavigationItemProps,
} from '../../../types/components/navigation';

import { getNavigationValue, getSizeVariation, renderSizeVariation } from '../../header/common';

export const useNavItemProps = (props: NavigationItemProps) => {
  const { audiences, tier, userState, isMobile } = props;
  return useMemo<NavigationItemProps>(
    () => ({ audiences, tier, userState, isMobile }),
    [audiences, tier, userState, isMobile]
  );
};

export const getNavItemVisible = (item: NavigationItem, props: NavigationItemProps) => {
  const { userState, isMobile } = props;
  const loggedIn = userState && userState.loggedIn;
  const isAccredited = userState && userState.accreditedSignUp;
  const isAdmin = loggedIn && userState && userState.isSoyUser;

  if (
    (item.private && !loggedIn) ||
    (item.guestOnly && loggedIn) ||
    (item.adminOnly && !isAdmin) ||
    (loggedIn &&
      ((item.accreditedOnly && !isAccredited) || (item.nonAccreditedOnly && isAccredited)))
  ) {
    return false;
  }

  return getSizeVariation(getNavigationValue(item.visible || true, props, item), isMobile);
};

export const getNavItemIcon = (item: NavigationItem, props: NavigationItemProps) => {
  const icon = getNavigationValue(item.icon, props, item);

  if (isString(icon)) {
    return <NavigationIcon src={icon} />;
  }
  if (isObject(icon)) {
    return (
      <NavigationIconAnimated>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: icon,
          }}
          width={30}
          height={'auto'}
        />
      </NavigationIconAnimated>
    );
  }
  return null;
};

export const getNavItemTitle = (item: NavigationItem, props: NavigationItemProps) => {
  const title = getNavigationValue(item.title, props, item);
  return renderSizeVariation(title, renderTitle => {
    return item.icon ? (
      <NavigationIconWrapper>
        {getNavItemIcon(item, props)}
        {renderTitle}
      </NavigationIconWrapper>
    ) : (
      renderTitle
    );
  });
};

export const getNavItemRoute = (item: NavigationItem, props: NavigationItemProps) => {
  const { route } = item;
  return getNavigationValue(route, props, item);
};

export const getNavItemSubMenu = (item: NavigationItem, props: NavigationItemProps) => {
  const { items } = item;
  return getNavigationValue(items, props, item);
};

export const getNavItemSelected = (itemRoute?: string, currentPath?: string): boolean => {
  return itemRoute ? !!(itemRoute && currentPath) && currentPath.startsWith(itemRoute) : false;
};

export const findActiveNavItem = (
  items: NavigationConfig,
  props: NavigationItemProps,
  currentPath?: string
): NavigationItem | undefined => {
  if (!currentPath) {
    return;
  }
  let selectedItem = items.filter(item => {
    const itemRoute = getNavItemRoute(item, props);
    const itemSelected = getNavItemSelected(itemRoute, currentPath);
    const itemSubMenu = getNavItemSubMenu(item, props);
    const selectedSubItem = itemSubMenu && findActiveNavItem(itemSubMenu, props, currentPath);
    return itemSelected || selectedSubItem;
  });

  if (selectedItem.length > 1 && find(selectedItem, 'route')) {
    selectedItem = selectedItem.filter(item => {
      const itemRoute = getNavItemRoute(item, props);
      return itemRoute?.includes(currentPath);
    });
  }

  return selectedItem[0];
};

export interface NavigationInputProps extends NavigationItemProps {
  staticPage?: boolean;
  currentPath?: string;
  renderLink?: (props: NavigationLinkProps) => React.ReactElement | null;
}

export interface NavigationLinkProps extends NavigationInputProps {
  inverse?: boolean;
  navItem: NavigationItem;
  className?: string;
  raw?: boolean;
  title?: string;
  ariaLabel?: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
}

export const NavigationLink: React.FunctionComponent<NavigationLinkProps> = props => {
  const { inverse, children, navItem, staticPage, renderLink, className, raw, title, innerRef } =
    props;
  const { id, callback, forceExternal, newTab, staticRoute, queryParamsString } = navItem;

  const finalRoute = getNavItemRoute(navItem, props);
  const finalTitle = children || getNavItemTitle(navItem, props);

  const finalNavItem: NavigationItem = useMemo(
    () => ({
      ...navItem,
      title: finalTitle,
      route: `${finalRoute}${queryParamsString || ''}`,
    }),
    [finalRoute, finalTitle, navItem, queryParamsString]
  );

  const onClick: MouseEventHandler = useCallback(
    e => {
      !finalRoute && e.preventDefault();
      callback && callback(finalNavItem);
    },
    [finalRoute, callback, finalNavItem]
  );

  if (!finalTitle) {
    return null;
  }

  if (
    !forceExternal &&
    ((staticRoute && staticPage) || (!staticRoute && !staticPage)) &&
    renderLink &&
    renderLink({ ...props, navItem: finalNavItem })
  ) {
    return renderLink({ ...props, navItem: finalNavItem });
  } else {
    if (!finalRoute) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          ref={innerRef}
          id={id}
          data-cy={`navigation-${id}`}
          className={className}
          onClick={onClick}
        >
          {finalTitle}
        </a>
      );
    }

    const targetProps = newTab
      ? {
          target: '_blank',
          rel: 'noreferrer',
        }
      : {
          target: '_self',
          rel: '',
        };

    if (raw) {
      return (
        <a
          ref={innerRef}
          id={id}
          data-cy={`navigation-raw-${id}`}
          className={className}
          href={finalRoute}
          onClick={onClick}
          title={title}
          tabIndex={0}
          {...targetProps}
        >
          {finalTitle}
        </a>
      );
    }

    return (
      <ExternalLink
        id={`navigation-external-link-${id}`}
        inverse={inverse}
        className={className}
        href={finalRoute}
        onClick={onClick}
        rel={forceExternal ? 'noopener noreferrer' : ''}
        target={newTab ? '_blank' : ''}
      >
        {finalTitle}
      </ExternalLink>
    );
  }
};

const NavigationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NavigationIconAnimated = styled.div`
  display: flex;
  ${friendlyMedia.desktop`
    margin-right: 4px;
  `};
`;

const NavigationIcon = styled.img`
  margin-right: 10px;
  max-height: 32px;
`;
