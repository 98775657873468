import React, { useEffect, useState, FunctionComponent, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Heading } from '../../content/text/Heading';
import { Paragraph } from '../../content/text/Paragraph';
import { media } from '../../themes/media';
import { DesktopOnly, MobileOnly } from '../../utils/styles/devices';
import { TabsSection, TabsSectionProps } from '../submenu/TabsSubmenu';
import { Container } from '../../layout/container/Container';

import arrow from '../../assets/graphics/icons/arrow.svg';

interface TabUIProps {
  active: boolean;
  sticky?: boolean;
}

export interface TabProps {
  key: string;
  title: string;
  subTitle?: string;
}

export interface TabsProps {
  menu: TabProps[];
  activeKey: string;
  children: React.ReactElement<TabsSectionProps>[];
  sticky?: boolean;
  mobileStickyPx?: number;
  onChange?: (key: string) => void;
}

export const Tabs: FunctionComponent<TabsProps> = ({
  activeKey,
  children,
  menu,
  mobileStickyPx = 66,
  onChange,
  sticky,
}) => {
  const [internalActiveKey, setInternalActiveKey] = useState<string>(activeKey || menu[0].key);
  const [shouldStick, setSticky] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      setSticky(containerRef.current.getBoundingClientRect().top <= mobileStickyPx);
    }
  }, [mobileStickyPx]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  const getTab = useCallback(
    (tab: TabProps) => {
      return (
        <Tab
          sticky={sticky && shouldStick}
          active={tab.key === internalActiveKey}
          key={tab.key}
          onClick={() => {
            setInternalActiveKey(tab.key);
            onChange && onChange(tab.key);
          }}
        >
          <Heading type={5}>{tab.title}</Heading>
          <TabSubtitle small>{tab.subTitle}</TabSubtitle>
          <Arrow />
        </Tab>
      );
    },
    [sticky, shouldStick, internalActiveKey, onChange]
  );

  useEffect(() => {
    setInternalActiveKey(activeKey);
  }, [setInternalActiveKey, activeKey]);

  return (
    <>
      <TabRef ref={containerRef}>
        <TabHolder sticky={sticky && shouldStick} mobileStickyPx={mobileStickyPx}>
          <Container>
            <DesktopOnly>
              <TabContainer>{menu.map(tab => getTab(tab))}</TabContainer>
            </DesktopOnly>
            <MobileOnly>
              <TabContainer>
                {menu.map((tab, index) => {
                  if (
                    tab.key === internalActiveKey ||
                    (index && menu[index - 1].key === internalActiveKey)
                  ) {
                    return tab.key === internalActiveKey && index + 1 === menu.length
                      ? [getTab(tab), getTab(menu[0])]
                      : getTab(tab);
                  }
                })}
              </TabContainer>
            </MobileOnly>
          </Container>
        </TabHolder>
      </TabRef>

      <Container>
        {React.Children.map(children, section => {
          return React.isValidElement(section) && section.type === TabsSection
            ? section.props.menuKey === internalActiveKey && (
                <TabContentContainer>{section}</TabContentContainer>
              )
            : { section };
        })}
      </Container>
    </>
  );
};

const TabRef = styled.div`
  min-height: 86px;
`;

const TabHolder = styled.div<Pick<TabsProps, 'sticky' | 'mobileStickyPx'>>`
  ${props =>
    props.sticky
      ? css`
          position: fixed;
          top: ${props.mobileStickyPx}px;
          z-index: 100;
          width: 100%;
          box-shadow: ${props => props.theme.shadows.shadow_default};
          background: ${props => props.theme.colors.background_card};
        `
      : css`
          position: relative;
          top: auto;
          background: ${props => props.theme.colors.background_page};
        `}

  ${media.desktop`
    position: relative !important;
    top: auto;
    z-index: auto;
    width: auto;
    box-shadow: none;
    background: ${props => props.theme.colors.background_page};
  `}
`;

const TabContainer = styled.div`
  display: flex;
`;

const TabSubtitle = styled(Paragraph)``;

const Arrow = styled.div`
  display: inline-block;
  background: ${props => props.theme.colors.background_inverse};
  width: 20px;
  height: 20px;
  position: relative;
  animation: fadeIn ease 1s;
  &::after {
    display: block;
    background: url(${arrow}) no-repeat;
    content: '';
    position: absolute;
    width: 11px;
    height: 7px;
    z-index: 1;
    filter: invert(100%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    ${media.desktop`
      right: 30px;
    `}
  }
  ${media.desktop`
    display: none;
  `}
`;

const Tab = styled.div<TabUIProps>`
  background: ${props => props.theme.colors.background_card};
  padding: 16px 20px;
  flex: 1;
  ${props =>
    props.active
      ? css<TabUIProps>`
          padding-bottom: ${props => (props.sticky ? '0' : '10px')};
          ${media.desktop`
            padding-bottom: 10px;
          `}
        `
      : css<TabUIProps>`
          cursor: pointer;
          margin-bottom: ${props => (props.sticky ? '0' : '10px')};
          ${media.desktop`
            margin-bottom: 10px;
          `}
        `}

  &:not(:last-child) {
    margin-right: 10px;
    ${Arrow} {
      display: none;
    }
  }

  &:last-child {
    flex: 0;
    text-align: center;
    min-width: 30%;
    white-space: nowrap;
    position: relative;

    &:before {
      ${props =>
        props.sticky
          ? css`
              background: ${props.theme.colors.border_default};
              content: '';
              width: 1px;
              position: absolute;
              height: calc(100% - 20px);
              left: 0;
              top: 10px;
            `
          : ''};
    }
    ${TabSubtitle} {
      display: none;
    }
  }
  ${media.desktop`
    transition: all 0.5s ease;
    &:last-child {
      border: none;
      &:before {
        content: none;
      }
      width: auto;
      text-align: left;
      ${TabSubtitle} {
        display: block;
      }
    }
  `}
`;

const TabContentContainer = styled.div`
  padding: 40px 20px;
  background: ${props => props.theme.colors.background_card};
`;
