import { kebabCase } from 'lodash';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';

import Arrow from '../assets/arrow-round.svg';
import { Label, Paragraph } from '../../../content';
import { DesktopOnly, MobileOnly } from '../../../utils';
import { SliderInput } from '../../../form';
import { media } from '../../../themes';
import AmountSelector from './AmountSelector';

interface AmountSelectorProps {
  title: string;
  annualizedYield: number;
  image?: ReactNode;
  term: string;
  amount: number;
  step?: number;
  min?: number;
  max?: number;
  onChange?: (value: number | number[]) => void;
  onTouchMove?: () => void;
  onTouchEnd?: () => void;
}
const ExpandedAmountSelector = ({
  title,
  annualizedYield,
  term,
  min = 0,
  max = 100000,
  step = 1000,
  amount,
  onChange,
  onTouchMove,
  onTouchEnd,
}: AmountSelectorProps) => {
  const color = '#000';

  return (
    <>
      <MobileOnly>
        <AmountSelector
          title={title}
          annualizedYield={annualizedYield}
          term={term}
          amount={amount}
          step={step}
          min={min}
          max={max}
          onChange={onChange}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          color={color}
        />
      </MobileOnly>
      <DesktopOnly>
        <AmountSelectorHolder>
          <AmountTitle>
            <Paragraph semiBold>{title}</Paragraph>
          </AmountTitle>
          <LineValue>
            <Label>
              Target annualized yield<sup>3</sup>
            </Label>
            <Paragraph semiBold>
              <>{annualizedYield}%</>
            </Paragraph>
          </LineValue>
          <LineValue>
            <Label>
              Target term<sup>3</sup>
            </Label>
            <Paragraph semiBold>{term}</Paragraph>
          </LineValue>
          <AmountSliderHolder>
            <AmountHolder>
              <Label>Total investment</Label>
              <Paragraph semiBold>{numeral(amount).format('$0,0')}</Paragraph>
            </AmountHolder>

            <SliderContainer onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
              <SSliderInput
                label={kebabCase(title)}
                name="slider"
                min={min}
                max={max}
                value={amount}
                fullWidth
                onChange={value => onChange && onChange(value)}
                formatValue={() => ''}
                step={step}
                trackColor={color}
              />
            </SliderContainer>
          </AmountSliderHolder>
        </AmountSelectorHolder>
      </DesktopOnly>
    </>
  );
};

const AmountSelectorHolder = styled.div`
  background-color: ${props => props.theme.colors.background_card};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 15px 15px 0;
  justify-content: space-between;

  ${media.desktop`
    margin-bottom: 0;
    padding: 16px;
    flex-direction: row;
    align-items: center;
  `}
`;

const AmountHolder = styled.div`
  margin-right: 24px;
`;

const AmountTitle = styled.div`
  text-align: left;
  max-width: 25%;
  padding: 8px;
`;

const AmountSliderHolder = styled.div`
  display: flex;
  background: ${props => props.theme.colors.background_page};
  padding: 16px;
  border-radius: 4px;
`;

const LineValue = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin: 4px 0;
`;

const SliderContainer = styled.div`
  display: flex;
  min-width: 250px;
`;

const SSliderInput = styled(SliderInput)<{ trackColor?: string }>`
  margin-bottom: 0 !important;

  ${media.desktop`
    margin-top: auto !important;
  `}

  > span {
    display: none;
  }

  p {
    background-color: ${props => props.theme.colors.background_page};
    border-radius: 4px;
    padding: 6px 10px;
    ${media.desktop`
      display: block;
    `}
  }

  span {
    color: ${props => (props.trackColor ? props.trackColor : props.theme.colors.chart_10)};
  }

  .MuiSlider-root {
    margin-bottom: 0;

    .MuiSlider-track {
      transition: unset;
    }
    .MuiSlider-thumb {
      background: ${props => (props.trackColor ? props.trackColor : props.theme.colors.chart_10)};
      border-radius: 4px;
      height: 18px;
      position: relative;
      transition-property: height, width, box-shadow;
      transition-duration: 0.3s;

      width: 34px;

      &:hover {
        height: 23px;
        width: 39px;
      }

      &:before,
      &:after {
        background-image: url('${Arrow}');
        background-size: cover;
        content: '';
        height: 8px;
        position: absolute;
        bottom: auto;
        width: 5px;
      }

      &:before {
        left: 5px;
        transform: scaleX(-1);
      }

      &:after {
        left: auto;
        right: 5px;
      }
    }
  }
`;

export default ExpandedAmountSelector;
