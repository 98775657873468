import React, { FunctionComponent } from 'react';
import { Container, ContainerProps } from '../container';
import styled from 'styled-components';
import { GridProvider } from './grid/Provider';
import { RowProps } from './grid/Row';
import { PartialGridConfig } from './grid/common';

interface PageGridProps extends ContainerProps {
  children: React.ReactElement<RowProps> | React.ReactElement<RowProps>[];
  config?: PartialGridConfig;
}

const GridContainer = styled(Container)``;

export const PageGrid: FunctionComponent<PageGridProps> = ({ children, config, ...props }) => {
  return (
    <GridProvider config={config}>
      <GridContainer {...props}>{children}</GridContainer>
    </GridProvider>
  );
};
