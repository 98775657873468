export enum PaymentMethodType {
  ACH = 'ACH', // ACH Transfer
  CHK = 'CHK', // Physical Check
  WIRE = 'WIRE', // Wire Transfer
  SYNP = 'SYNP', // YS Wallet
  SHRS = 'SHRS', // Reinvested Shares
}

export enum PaymentMethodLabel {
  ACH = 'ACH Transfer',
  CHK = 'Physical Check',
  WIRE = 'Wire Transfer',
  SYNP = 'Yieldstreet Wallet',
  SHRS = 'Reinvested Shares',
}
