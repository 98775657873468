import styled, { css } from 'styled-components';
import { MaybePartialSizeMap, sizeCss } from '../../utils/sizes';
import { media } from '../../themes';

export type ContainerProps = {
  fluid?: MaybePartialSizeMap<boolean>;
  full?: MaybePartialSizeMap<boolean>;
};

export const Container = styled.div<ContainerProps>`
  overflow: hidden;
  word-break: break-word;
  margin: 0 auto;
  width: 100%;

  ${({ fluid }) =>
    sizeCss(
      ({ fluid }) =>
        !fluid &&
        css`
          max-width: 1250px;
        `,
      { fluid }
    )}

  ${({ full }) => sizeCss(({ full }) => !full && ContainerPadding, { full })}
`;

export const ContainerPadding = css`
  ${media.tablet`
    padding-left: 35px;
    padding-right: 35px;
  `};
  padding-left: 20px;
  padding-right: 20px;
`;
