import styled, { css } from 'styled-components';

import { media } from '../../themes/media';

export interface ButtonContainerProps {
  alignment?: 'left' | 'center' | 'right';
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  margin: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  a {
    padding: 0;
    background: none !important;
  }
  button,
  a {
    &:not(:last-child) {
      margin: 0 0 20px 0;
    }
    width: 100%;
    button {
      margin: 0;
    }
  }
  ${media.desktop`
      flex-direction: row;
      button, a {
          margin: 0;
          width: auto;
          &:not(:last-child) {
            margin: 0;
          }
      }
  `}
  ${props => {
    switch (props.alignment) {
      case 'right':
        return css`
          justify-content: flex-end;
          align-items: flex-end;
          ${media.desktop`
              a:not(:last-child) ,button:not(:last-child) {
                  margin-left: 20px;
              }
          `}
        `;
      case 'center':
        return css`
          justify-content: center;
          align-items: center;
          ${media.desktop`
              a:not(:last-child), button:not(:last-child) {
                  margin-right: 20px;
              }
          `}
        `;
      default:
        return css`
          justify-content: flex-start;
          align-items: flex-start;
          ${media.desktop`
              a:not(:last-child), button:not(:last-child) {
                  margin-right: 20px;
              }
          `}
        `;
    }
  }}
`;
