import { InvestorAllAccountsStatus } from './investor-account-status.model';

export const transformAllInvestorAccountsStatusResponse = (response: InvestorAllAccountsStatus) =>
  response.investorAccounts?.reduce((accountStatus, { id, status, type, typeDescription }) => {
    return {
      ...accountStatus,
      [id]: {
        ...status,
        id,
        type,
        typeDescription,
      },
    };
  }, {});
