import styled from 'styled-components';
import { media } from '../../themes/media';

export const ExternalProviderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  ${media.desktop`
    margin-bottom: 8px;
  `}
`;

export const ArrowWrapper = styled.div`
  padding-right: 4px;
`;

export const Arrow = styled.div`
  border: solid ${props => props.theme.colors.background_hero_shadow};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
