import styled, { css } from 'styled-components';
import blackArrowSmall from '../assets/blackArrowSmall.svg';

import { media } from '../../../themes/media';
import { Paragraph } from '../../../content/text';
import { Button } from '../../../content/button';

import {
  ArrowProps,
  MenuItemProps,
  NotificationIconProps,
  SubmenuItemProps,
} from './HeaderItemLoggedOut.model';

export const NotificationIndicator = styled.sup`
  display: inline-block;
`;

export const MenuItem = styled.div<MenuItemProps>`
  position: relative;
  ${props =>
    props.seperator
      ? css`
          &::after {
            content: ' ';
            position: absolute;
            border-right: 1px solid ${props.theme.colors.border_strong};
            right: -20px;
            margin: 30px 0;
            height: calc(100% - 60px);
          }
        `
      : ''}

  ${media.desktop`
    margin: 0 14px;
  `}

  ${media.larger`
    margin: 0 20px;
  `}
`;

export interface MenuLinkProps {
  selected?: boolean;
  active?: boolean;
  interactive?: boolean;
  isSubItem?: boolean;
  isSidebarItem?: boolean;
  isButton?: boolean;
  inverse?: boolean;
}

export const MenuLinkContainer = styled.div<MenuLinkProps>`
  ${props =>
    !props.isButton
      ? css`
          cursor: pointer;
          a {
            &:hover,
            &:active {
              text-decoration: underline;
              text-underline-offset: 3px;
            }
            &:active {
              opacity: 0.7;
            }
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
          }
          > a {
            font-family: ${props.theme.typography.secondary};
            font-weight: 300;
            color: ${props.inverse
              ? props.theme.colors.text_link_inverse
              : props.theme.colors.text_link};

            text-transform: none;
            display: inline-flex;
            align-items: center;
            font-size: 21px;
            height: inherit;
            ${media.desktop`
              font-size: 14px;
            `}
          }

          ${props.isSidebarItem
            ? css`
                align-items: center;
                display: flex;
                height: auto;
              `
            : !props.isSubItem &&
              css`
                height: 64px;
                display: flex;
                align-items: center;
              `};

          ${props.interactive &&
          css`
            > a {
              transition: background-size 0.2s ease-in-out;
              background-repeat: no-repeat;
              background-size: 225% 0;
              background-position: 100% 100%;
              background-image: linear-gradient(
                to right,
                ${props.theme.colors.active} 0%,
                ${props.theme.colors.active} 100%
              );
            }

            ${media.desktop`
                &:hover{
                  > a {

                      text-decoration: underline;
                      text-underline-offset: 3px;
                  }
                }
            `}
          `}
        `
      : ''}
`;

export const Title = styled.span<MenuItemProps>`
  font-size: 16px;
  padding: 0;
  display: inline-block;
  position: relative;
  width: 100%;

  ${props => `
    ${
      props.isSidebarItem &&
      css`
        padding: 16px 0;
        ${media.large`
          padding: 20px 0;
        `};
      `
    }
  `}

  ${media.large`
    white-space: nowrap;
  `};
`;

export const ArrowIcon = styled.span<ArrowProps>`
  width: 12px;
  height: 7px;
  background-image: url(${blackArrowSmall});
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-left: 10px;

  ${props => (props.menuOpen ? `transform: rotate(180deg);` : `transform: rotate(0deg);`)}

  ${media.large`
    background-image: url(${blackArrowSmall});
    background-position: center;
    width: 7px;
    height: 4px;
  `}
`;

export const SubMenu = styled.ul<MenuItemProps>`
  background: ${props => props.theme.colors.background_card};
  transition: height 0ms 200ms, opacity 200ms 0ms;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: relative;
  margin: 0 0 0 0;
  width: 100%;
  ${props =>
    props.menuOpen &&
    css`
      height: auto;
      opacity: 1;
      transition: height 0ms 0ms, opacity 200ms 0ms;
      padding: 10px 0;
    `}
  ${media.desktop`
    left: 50%;
    transform: translateX(-50%);
  `}
  li {
    padding: 10px 0;
    position: relative;
    ${media.large`
      border: none;
      padding: 10px 15px;
    `};
    a {
      display: inline-block;
      white-space: nowrap;
    }
  }

  ${props => css`
    ${!props.isSidebarItem &&
    css`
      z-index: 999;
      position: absolute;
      width: unset;
      right: unset;
      top: unset;
      box-shadow: none;
      ${props.desktopAlignment === 'left' ? 'left: 0' : 'right: -20px'};
      top: 25px;
      min-width: 140px;
      margin-top: 30px;
      margin-left: -1px;
      ${media.desktop`
          margin-top: 0;
          top: 0;
        `}
    `};
  `};

  ${media.large<MenuItemProps>`
    ${props => css`
      ${!props.isSidebarItem &&
      css`
        top: 64px;
        &::before {
          height: 35px;
          top: -35px;
        }
      `}
    `}
  `};
`;

export const SubMenuItem = styled.li<SubmenuItemProps>`
  cursor: pointer;
  list-style-type: none;
  &::before {
    ${media.large<SubmenuItemProps>`
      ${props =>
        props.seperator
          ? css`
              border-top: 1px solid ${props.theme.colors.border_extra_strong};
            `
          : ''};
    `};
    content: ' ';
    position: absolute;
    top: 0;
    padding: 0 10px;
    width: calc(100% - 50px);
  }
  ${media.large<SubmenuItemProps>`
    ${props =>
      props.seperator
        ? css`
            padding-top: 15px !important;
            margin-top: 5px;
          `
        : ''};
  `};

  a {
    display: block;
    list-style: none;
    font-weight: 500;
    font-size: 18px !important;
    ${media.desktop`
      font-size: 16px !important;
    `}
  }
`;

export const NotificationIcon = styled.div<NotificationIconProps>`
  width: ${props => (props.subMenu ? '18px' : '6px')};
  height: ${props => (props.subMenu ? '18px' : '6px')};
  padding: ${props => (props.subMenu ? '5px' : 0)};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.accent_strong};
  position: inherit;
  display: inline-block;
  margin-left: 6px;
  text-align: center;
  ${props =>
    props.isBigNumber &&
    `
    width: auto;
    border-radius: 40px;
  `}
`;

export const SParagraph = styled(Paragraph)`
  vertical-align: super;
  font-size: 10px;
  line-height: 15px;
  margin-top: -3px;
`;

export const SButton = styled(Button)`
  margin-top: 23px;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  background: ${props => props.theme.colors.input_button_nav_background};
  border: 1px solid ${props => props.theme.colors.input_button_nav_background};
  color: ${props => props.theme.colors.text_header_inverse} !important;
  &:hover,
  &:active {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;
