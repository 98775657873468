import React, { useState, useCallback, useMemo } from 'react';
import produce from 'immer';
import styled from 'styled-components';

import { media } from '../../themes/media';

import { Portfolio } from '@yieldstreet/tool-kit';
import Chart, { ColumnColors } from './Widgets/Chart';
import AmountSelector from './Widgets/AmountSelector';
import SwitchSelector from './Widgets/SwitchSelector';
import StrategySelector from './Widgets/StrategySelector';
import { Label, Heading, Paragraph } from '../../content';
import { DesktopOnly, MobileOnly } from '../../utils';

const Strategies = [
  {
    title: 'Income',
    key: 0,
    desc: 'Prioritize current income',
  },
  {
    title: 'Growth',
    key: 1,
    desc: 'Prioritize returns',
  },
  {
    title: 'Balanced',
    key: 2,
    desc: 'Blend income and growth',
  },
];

interface ChartProps {
  portfolioConfig: Portfolio[];
  initAmount: number[][];
}

const PortfolioChart = ({ portfolioConfig, initAmount }: ChartProps) => {
  const [showTotalInvestment, setShowTotalInvestment] = useState(false);
  const [strategy, setStrategy] = useState(0);
  const [amounts, setAmounts] = useState<number[][]>(initAmount);

  const investments = useMemo(() => {
    return portfolioConfig[strategy].investments;
  }, [strategy]);

  const onAmountChange = useCallback(
    (amount, index) => {
      setAmounts(
        produce(draft => {
          draft[strategy][index] = amount;
        })
      );
    },
    [strategy, produce, setAmounts]
  );

  return (
    <>
      <ChartContainer>
        <Chart
          investments={investments}
          amounts={amounts[strategy]}
          showTotalInvestment={showTotalInvestment}
        />
      </ChartContainer>
      <ControlsHolder>
        <DesktopOnly>
          <StrategySelector
            onClick={option => setStrategy(+option.key)}
            activeKey={strategy}
            options={Strategies}
          />
        </DesktopOnly>
        <MobileOnly>
          <SwitchContainer>
            <SwitchSelector
              tabs={Strategies}
              onClick={tab => setStrategy(+tab.key)}
              activeKey={strategy}
            />
            <StrategyDesc>{Strategies[strategy].desc}</StrategyDesc>
          </SwitchContainer>
        </MobileOnly>
        {investments.map((investment, index) => (
          <AmountSelector
            key={index}
            title={investment.name}
            annualizedYield={investment.rate}
            term={`${investment.term} ${investment.termSuffix}`}
            min={investment.min}
            max={investment.max}
            amount={amounts[strategy][index]}
            onChange={amount => onAmountChange(amount, index)}
            onTouchMove={() => setShowTotalInvestment(true)}
            onTouchEnd={() => setShowTotalInvestment(false)}
            color={ColumnColors[index]}
          />
        ))}
      </ControlsHolder>
    </>
  );
};

export const PortfolioSimulator = ({ portfolioConfig }: Pick<ChartProps, 'portfolioConfig'>) => {
  const initAmount = useMemo(
    () => portfolioConfig.map(s => s.investments.map(i => i.defaultAmount)),
    [portfolioConfig]
  );
  return (
    <Background>
      <Heading type={3}>Portfolio simulator </Heading>
      <Paragraph marginBottom marginTop>
        See how you can benefit from building a portfolio of alternative investments.
      </Paragraph>
      <PortfolioChart portfolioConfig={portfolioConfig} initAmount={initAmount} />
      <Disclaimer small>
        All investments involve risk, including loss of principal.{' '}
        <a href="/simulator-disclosures/">View Disclosures</a>
      </Disclaimer>
    </Background>
  );
};

const Background = styled.div`
  background: ${props => props.theme.colors.background_page};
`;

const ChartContainer = styled.div`
  background: ${props => props.theme.colors.background_card};
  border-radius: 8px;
  margin-top: 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  ${media.large`
    margin-top: 35px;
    position: static;
  `}
`;

const ControlsHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
  background: ${props => props.theme.colors.background_page};
  ${media.large`
    flex-direction: row;
    padding: 0;
  `}
`;

const StrategyDesc = styled(Label)`
  display: block;
  margin: 15px 0;
  text-align: center;
  ${media.large`
    display: none;
  `}
`;

const SwitchContainer = styled.div``;

const Disclaimer = styled(Label)`
  background: ${props => props.theme.colors.background_page};
  display: block;
  padding: 15px 15px 30px;
  ${media.large`
    padding: 20px 0 0 0;
  `}
`;
