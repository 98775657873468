/* eslint-disable no-nested-ternary */
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../themes/media';
import { MaybePartialSizeMap, sizeCss } from '../../utils';

import { Heading, Paragraph } from '../../content/text';

export interface PromoCardProps {
  testId?: string | number;
  image?: string;
  imageFull?: boolean;
  imageAlign?: string;
  imageJustify?: string;
  imageBackground?: string;
  imageBackgroundRepeat?: string;
  imageWidth?: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  cta?: React.ReactNode;
  onClick?: () => void;
  sideImage?: MaybePartialSizeMap<boolean>;
  sideCTA?: MaybePartialSizeMap<boolean>;
}

export const PromoCard: FunctionComponent<PromoCardProps> = ({
  testId,
  image,
  imageBackground,
  imageBackgroundRepeat,
  imageAlign,
  imageJustify,
  imageFull,
  imageWidth,
  title,
  description,
  cta,
  sideImage,
  sideCTA,
}) => {
  return (
    <CardContainer sideImage={sideImage} data-cy={`${testId ? testId : 'card'}`}>
      {image && (
        <ImageWrapper
          imageBackground={imageBackground}
          imageBackgroundRepeat={imageBackgroundRepeat}
          sideImage={sideImage}
          imageFull={imageFull}
          imageJustify={imageJustify}
          sideCTA={sideCTA}
        >
          <Image
            src={image}
            imageAlign={imageAlign}
            imageFull={imageFull}
            imageWidth={imageWidth}
          />
        </ImageWrapper>
      )}
      <CardContent sideCTA={sideCTA}>
        <CardText>
          <Title type={5}>{title}</Title>
          <Description sideImage={sideImage} secondary small>
            {description}
          </Description>
        </CardText>
        {cta && (
          <CTAHolder sideImage={sideImage} sideCTA={sideCTA}>
            {cta}
          </CTAHolder>
        )}
      </CardContent>
    </CardContainer>
  );
};

const CardContainer = styled.div<{
  sideImage?: MaybePartialSizeMap<boolean>;
}>`
  display: inline-flex;
  width: 100%;
  ${({ sideImage }) =>
    sizeCss(
      ({ sideImage }) => css`
        flex-direction: ${sideImage ? 'row' : 'column'};
      `,
      { sideImage }
    )}
  background: ${props => props.theme.colors.background_card};
  flex: 1;
  align-items: stretch;
  justify-content: space-between;
`;

const CardContent = styled.div<{ sideCTA?: MaybePartialSizeMap<boolean> }>`
  display: flex;
  flex: 1;
  ${({ sideCTA }) =>
    sizeCss(
      ({ sideCTA }) => css`
        padding: ${sideCTA ? 30 : 20}px;
        flex-direction: ${sideCTA ? 'row' : 'column'};
        align-items: ${sideCTA ? 'center' : 'initial'};
      `,
      { sideCTA }
    )}
  justify-content: space-between;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Heading)`
  margin-bottom: 5px;
`;

const Description = styled(Paragraph)<{
  sideImage?: MaybePartialSizeMap<boolean>;
}>`
  ${({ sideImage }) =>
    sizeCss(
      ({ sideImage }) => css`
        flex-grow: ${sideImage ? 0 : 1};
      `,
      { sideImage }
    )}
`;

const CTAHolder = styled.div<{
  sideCTA?: MaybePartialSizeMap<boolean>;
  sideImage?: MaybePartialSizeMap<boolean>;
}>`
  display: flex;
  margin: 0;
  ${({ sideImage, sideCTA }) =>
    sizeCss(
      ({ sideImage, sideCTA }) => css`
        margin-top: ${sideCTA ? 0 : 20}px;
        margin-left: ${sideCTA ? 45 : 0}px;
        align-items: ${sideCTA ? 'center' : 'flex-start'};
        button {
          white-space: nowrap;
          width: ${sideImage || sideCTA ? 'auto' : '100%'};
          ${media.tablet`
            width: auto;
          `}
        }
      `,
      { sideImage, sideCTA }
    )}
`;

const ImageWrapper = styled.div<{
  sideCTA?: MaybePartialSizeMap<boolean>;
  sideImage?: MaybePartialSizeMap<boolean>;
  imageFull?: boolean;
  imageJustify?: string;
  imageBackground?: string;
  imageBackgroundRepeat?: string;
}>`
  display: inline-flex;

  background-image: url(${props => props.imageBackground});
  background-repeat: ${props => props.imageBackgroundRepeat || 'initial'};

  ${({ sideImage, sideCTA, imageFull, imageJustify }) =>
    sizeCss(
      ({ sideImage, sideCTA, imageFull, imageJustify }) => css`
        padding: ${imageFull ? 0 : sideCTA ? 30 : 20}px;
        justify-content: ${imageJustify || 'initial'};
        align-items: ${sideCTA ? 'center' : 'flex-start'};
        ${sideImage && !imageFull ? `padding-right: 10px;` : `padding-bottom: 0;`}
      `,
      { sideImage, sideCTA, imageFull, imageJustify }
    )}
`;

const Image = styled.img<{
  imageFull?: boolean;
  imageAlign?: string;
  imageWidth?: string;
}>`
  ${props =>
    props.imageAlign &&
    css`
      align-self: ${props.imageAlign};
    `};
  ${props =>
    !props.imageFull &&
    !props.imageWidth &&
    css`
      width: 50px;
    `};
  ${props =>
    props.imageWidth &&
    css`
      width: ${props.imageWidth};
    `};
`;
