import React, { FC, useState, useEffect, useRef } from 'react';
import { isString } from 'lodash';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import LogoImage from '../../assets/logos/logo-ys-2022.png';

import Plus from './assets/plus.svg';
import { BackgroundWrapperProps, PageSplitProps } from './PageSplit.model';
import {
  Arrow,
  BackNavigation,
  ContentHeader,
  ContentHolder,
  ContentLeft,
  ContentRight,
  FooterContentLeft,
  FooterContentRight,
  Label,
  LeftPageContent,
  Logo,
  LogoSecondary,
  LogosHolder,
  PlusHolder,
  RightPageContent,
  SPageSplit,
  Title,
} from './PageSplit.style';

const BackgroundWrapper: FC<BackgroundWrapperProps> = ({ backgroundImage, children }) => (
  <>{backgroundImage && !isString(backgroundImage) ? backgroundImage(children) : children}</>
);

export const PageSplit: FC<PageSplitProps> = ({
  showLogo,
  secondLogo,
  backNavigation,
  backNavigationText,
  backNavigationCallback,
  title,
  titleTagType,
  label,
  sideContent,
  footerContent,
  backgroundColor,
  hideBackgroundImageMobile,
  backgroundImage,
  middleSplit,
  children,
  className,
}) => {
  const [headerHeight, setHeight] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      (showLogo || backNavigation) &&
      elementRef?.current?.offsetHeight &&
      headerHeight !== elementRef.current.offsetHeight
    ) {
      setHeight(elementRef?.current?.offsetHeight);
    }
  }, [backNavigation, elementRef, headerHeight, showLogo]);

  return (
    <SPageSplit backgroundColor={backgroundColor} data-cy="page-split" className={className}>
      <LeftPageContent middleSplit={middleSplit} className="page-split-left-content">
        <ContentHolder>
          <ContentHeader ref={elementRef}>
            {showLogo ? (
              <LogosHolder
                className="page-split-logos-holder"
                data-cy="page-split-logo"
                center={!secondLogo}
              >
                <Logo>
                  <a href="/">
                    <img width="177" src={LogoImage} />
                  </a>
                </Logo>
                {secondLogo ? (
                  <>
                    <PlusHolder>
                      <img src={Plus} />
                    </PlusHolder>
                    <LogoSecondary>{secondLogo}</LogoSecondary>
                  </>
                ) : null}
              </LogosHolder>
            ) : null}
            {backNavigation ? (
              <BackNavigation
                data-cy="page-split-back-navigation"
                onClick={e => {
                  e.preventDefault();
                  backNavigationCallback && backNavigationCallback();
                }}
              >
                <Arrow icon={faChevronLeft} />
                {backNavigationText}
              </BackNavigation>
            ) : null}
          </ContentHeader>
          <ContentLeft>
            {label ? <Label data-cy="page-split-label">{label}</Label> : null}
            {title ? (
              <Title data-cy="page-split-title" type={3} tagType={titleTagType}>
                {title}
              </Title>
            ) : null}
            {sideContent}
          </ContentLeft>
        </ContentHolder>
        {footerContent && <FooterContentLeft>{footerContent}</FooterContentLeft>}
      </LeftPageContent>
      <BackgroundWrapper backgroundImage={backgroundImage}>
        <RightPageContent
          backgroundImage={backgroundImage}
          hideBackgroundImageMobile={hideBackgroundImageMobile}
          middleSplit={middleSplit}
          headerHeight={headerHeight}
        >
          <ContentRight>{children}</ContentRight>
        </RightPageContent>
      </BackgroundWrapper>
      {footerContent && <FooterContentRight>{footerContent}</FooterContentRight>}
    </SPageSplit>
  );
};
