import styled from 'styled-components';

import { Container } from '../container';

export const PageContainerWrapper = styled.div<{ light: boolean }>`
  background: ${({ light, theme }) =>
    light ? theme.colors.background_default : theme.colors.background_page};
`;

export const PageContainerContent = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 35px;
  padding-bottom: 35px;

  &:last-child {
    padding-bottom: 65px;
  }
`;
