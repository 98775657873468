import { DistributionOption } from '../../../../enums/investment/distribution-option';

export enum FundBalanceCalculationType {
  InvestedMinusCapital = 'INVESTED_MINUS_CAPITAL',
}

export enum FundCapitalBalanceReductionType {
  CapitalDistributed = 'CAPITAL_DISTRIBUTED',
  IncomeDistributed = 'INCOME_DISTRIBUTED',
}

export interface FundConfig {
  balanceCalculationType: FundBalanceCalculationType | null;
  capitalBalanceReductionType: FundCapitalBalanceReductionType | null;
  fees: {};
  feesBalanceProviders: {};
  positionsEffectiveDistributionImpact?: string | null;
}

export interface PortfolioSummaryFundBreakdown {
  funded: number;
  total: number;
  unfunded: number;
}

export interface PortfolioSummaryFund {
  canToggleDistributionOption: boolean;
  commitmentBreakdown: PortfolioSummaryFundBreakdown | null;
  dividendDistributionOption: DistributionOption;
  fundCode: string;
  fundConfig: FundConfig;
  fundId: string;
  fundInvestmentStatus?: string | null;
  investorAccountId: string;
  nav: number;
  navDate: string;
  noteUrlHash: string;
  sharesCount: number;
  total: number;
}
