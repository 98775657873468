import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content/text';

export interface ArticleCardProps {
  testId?: string | number;
  image?: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  cta?: React.ReactNode;
  onClick?: () => void;
  mini?: boolean;
}

export const ArticleCard: FunctionComponent<ArticleCardProps> = ({
  testId,
  image,
  title,
  description,
  cta,
  mini,
}) => {
  return (
    <CardContainer mini={mini} data-cy={`${testId ? testId : 'card'}`}>
      {image && (
        <ImageWrapper mini={mini}>
          <Image image={image} mini={mini} />
        </ImageWrapper>
      )}
      <CardContent>
        <CardText>
          <Title type={mini ? 5 : 4}>{title}</Title>
          <Description mini={mini} small>
            {description}
          </Description>
          {cta && <CTAHolder>{cta}</CTAHolder>}
        </CardText>
      </CardContent>
    </CardContainer>
  );
};

const CardContainer = styled.div<{ mini?: boolean }>`
  display: inline-flex;
  width: 100%;
  flex-direction: ${props => (props.mini ? 'row' : 'column')};
  background: ${props => props.theme.colors.background_card};
  flex: 1;
  align-items: stretch;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Heading)`
  margin-bottom: 5px;
`;

const Description = styled(Paragraph)<{ mini?: boolean }>`
  flex-grow: ${props => (props.mini ? 0 : 1)};
  margin-bottom: ${props => (props.mini ? 10 : 40)}px;
`;

const CTAHolder = styled.div`
  button {
    width: 100%;
    ${media.tablet`
      width: auto;
    `};
  }
`;

const ImageWrapper = styled.div<{ mini?: boolean }>`
  display: inline-block;
  padding: 20px;
  ${props => (props.mini ? `padding-right: 0;` : `padding-bottom: 0;`)}
  overflow: hidden;
  min-width: ${props => (props.mini ? `140px` : `none`)};
  min-height: ${props => (props.mini ? `none` : `140px`)};
  width: ${props => (props.mini ? `140px` : `auto`)};
  height: ${props => (props.mini ? `auto` : `140px`)};
`;

const Image = styled.div<{ image?: string; mini?: boolean }>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;
