import React from 'react';
import styled, { css } from 'styled-components';
import { Label } from '../../../content';

interface Tab {
  title?: string;
  icon?: React.ReactNode;
  key: string | number;
}

interface SwitchSelectorProps {
  tabs: Tab[];
  activeKey?: string | number;
  onClick?: (tab: Tab) => void;
}
const SwitchSelector = ({ tabs, activeKey, onClick }: SwitchSelectorProps) => {
  return (
    <OuterContainer>
      {tabs?.map((tab, key) => (
        <TabButton
          key={key}
          active={tab.key === activeKey}
          onClick={() => {
            onClick && onClick(tab);
          }}
        >
          {tab.icon ? tab.icon : ''}
          {tab.title ? <SLabel semiBold>{tab.title}</SLabel> : ''}
        </TabButton>
      ))}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background: ${props => props.theme.colors.background_progress_bar};
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const TabButton = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  text-align: center;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
  ${props =>
    props.active
      ? css`
          box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.2);
          background: ${props.theme.colors.background_default};
        `
      : ''};
`;

const SLabel = styled(Label)`
  word-break: normal;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

export default SwitchSelector;
