import { Components, CssVarsTheme } from '@mui/material';

import { NEUTRALS } from '../palette/neutrals';

export const MuiLink: Components<CssVarsTheme>['MuiLink'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundImage:
        'linear-gradient( to right,#1A1B1D 45%,transparent 45%,transparent 55%,#1A1B1D 55% )',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '225% 0.1em',
      backgroundPosition: '100% 100%',
      textDecoration: 'none',
      padding: '1px',
      '&:hover': {
        'background-position': '0 100%',
        'transition-property': 'background-position',
        'transition-duration': '0.4s',
        'transition-timing-function': 'ease-in-out',
        [theme.getColorSchemeSelector('light')]: {
          color: theme.vars.palette.Button?.contained?.primary?.color || NEUTRALS.black[50],
        },
        [theme.getColorSchemeSelector('dark')]: {
          color: theme.vars.palette.Button?.contained?.primary?.colorInverse || NEUTRALS.white[50],
        },
      },
    }),
  },
};
