import React from 'react';
import styled from 'styled-components';

import { Button } from '../../content/button';
import { media } from '../../themes/media';

import { HeaderItem } from './HeaderItem';
import { HeaderViewProps } from './HeaderView';
import { userNav, HelpCenter } from './navigationConfig';
import { LinkStyleProps } from '../../content/link';
import { NavigationLink } from '../common';
import { NavigationItem } from '../../types/components/navigation';
import { MobileOnly, DesktopOnly } from '../../utils';
import { HeaderItemLoggedOut } from './HeaderItemLoggedOut';

interface LoginAreaProps extends HeaderViewProps {
  activeItem?: NavigationItem;
  activeSubItem?: NavigationItem;
  hideLogin?: boolean;
  hideSignup?: boolean;
  hideUserNav?: boolean;
  customSignUpButton?: React.ReactNode;
}

export const LoginArea: React.FunctionComponent<LoginAreaProps> = props => {
  const {
    activeItem,
    activeSubItem,
    hideLogin,
    hideSignup,
    hideUserNav,
    userState,
    customSignUpButton,
  } = props;

  return (
    <Container id="login-area" data-cy="login-area">
      {!hideUserNav && (
        <>
          <DesktopOnly>
            <UserMenuContainer>
              {userNav.map(item => (
                <HeaderItem
                  key={item.key}
                  activeItem={activeItem}
                  activeSubItem={activeSubItem}
                  menuItem={item}
                  desktopAlignment="right"
                  {...props}
                />
              ))}
            </UserMenuContainer>
          </DesktopOnly>
          <MobileOnly>
            <UserMenuContainer>
              {userNav.map(item => (
                <HeaderItem
                  key={item.key}
                  activeItem={activeItem}
                  activeSubItem={activeSubItem}
                  menuItem={item}
                  desktopAlignment="right"
                  isMobile
                  {...props}
                />
              ))}
            </UserMenuContainer>
          </MobileOnly>
        </>
      )}

      {!userState?.loggedIn ? (
        <SignupHolder>
          <DesktopOnly>
            <HeaderItemLoggedOut
              key="help-center"
              activeItem={activeItem}
              activeSubItem={activeSubItem}
              menuItem={HelpCenter}
              isSidebarItem
              {...props}
            />
          </DesktopOnly>
          {!hideLogin && (
            <DesktopOnly>
              <LoginLinkWrapper hideSignup={hideSignup}>
                <NavigationLink
                  {...props}
                  navItem={{
                    id: 'header-login',
                    key: 'login',
                    route: '/login',
                    title: 'Log in',
                  }}
                  raw
                >
                  <Button
                    buttonType="secondary"
                    small
                    trackingId="header-login"
                    data-cy="header-login"
                    tabIndex={-1}
                  >
                    Login
                  </Button>
                </NavigationLink>
              </LoginLinkWrapper>
            </DesktopOnly>
          )}
          {!hideSignup && (
            <NavigationLink
              {...props}
              navItem={{
                id: 'header-signup-link',
                key: 'signup',
                route: '/get-started/',
              }}
              raw
            >
              <Button
                buttonType="basic"
                small
                data-cy="header-signup-link"
                trackingId="header-signup"
                tabIndex={-1}
              >
                Sign up
              </Button>
            </NavigationLink>
          )}
          {customSignUpButton}
        </SignupHolder>
      ) : (
        ''
      )}
    </Container>
  );
};

const Container = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 16px;
`;

const UserMenuContainer = styled.div`
  display: flex;
  align-content: center;
`;

const SignupHolder = styled.div`
  align-items: center;
  position: relative;
  display: inline-flex;
  font-size: 14px;
  height: 100%;
  ${media.large`
    padding: 10px 15px;
  `};
`;

const LoginLinkWrapper = styled.span<LinkStyleProps & { hideSignup?: boolean }>`
  display: ${props => (props.hideSignup ? 'inline-block' : 'none')};
  margin: 0 10px 0 20px;
  ${media.phoneLandscape`
    display: inline-block;
  `};
`;
