import { Cadence, PaymentMethodType } from '@yieldstreet/platform-kit';

export type CadenceCopy = {
  cadence?: string;
  email: string;
  tooltip: string;
  pizzaTracker: string;
  investmentDetails: string;
};

export type SupportedPaymentMethodTypes =
  | PaymentMethodType.WIRE
  | PaymentMethodType.ACH
  | PaymentMethodType.SYNP;

export type CadenceCopies = {
  [key in PaymentMethodType]: {
    [key in Cadence]: CadenceCopy;
  };
};

const SynpCopy = {
  [Cadence.DAILY]: {
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments will be confirmed as soon as all relevant action items have been completed.',
    pizzaTracker:
      'We have received your investment request and will start processing it as soon as we pull the funds from your Yieldstreet Wallet.',
    investmentDetails: 'This investment will start processing as soon as we receive your funds.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.BIWEEKLY]: {
    cadence: 'twice per week',
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments are confirmed twice per week if all relevant action items have been completed.',
    pizzaTracker:
      'We have received your investment request and will start processing it as soon as we pull the funds from your Yieldstreet Wallet. Please note that investments in this offering are processed {{cadence}}.',
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.WEEKLY]: {
    cadence: 'once per week',
    email: 'investments@yieldstreet.com',
    tooltip: 'Investments are confirmed weekly if all relevant action items have been completed.',
    pizzaTracker:
      'We have received your investment request and will start processing it as soon as we pull the funds from your Yieldstreet Wallet. Please note that investments in this offering are processed {{cadence}}.',
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.MONTHLY]: {
    cadence: 'at the end of the month',
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments are confirmed at month end if all relevant action items have been completed',
    pizzaTracker:
      'We have received your investment request and will start processing it as soon as we pull the funds from your Yieldstreet Wallet. Please note that investments in this offering are processed {{cadence}}.',
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.PAUSED]: {
    email: 'investments@yieldstreet.com',
    tooltip:
      'You can still submit an investment request, and we will process your investment as soon as possible if all relevant action items have been completed. Please contact {{email}} with any questions.',
    pizzaTracker:
      'We have received your investment request, but investment processing is currently paused for this offering. We will resume processing investments as soon as possible. If you have any questions in the meantime, please contact {{email}}.',
    investmentDetails:
      'Investment processing is currently paused for this offering We will resume processing investments as soon as possible.',
    contactMessage: 'If you have any questions in the meantime, please contact {{email}}.',
  },
};

const AchCopy = {
  [Cadence.DAILY]: {
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments will be confirmed as soon as all relevant action items have been completed.',
    pizzaTracker:
      "We have received your investment request and will start processing it after we've received your funds from your connected bank account.",
    investmentDetails: 'This investment will start processing as soon as we receive your funds.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.BIWEEKLY]: {
    cadence: 'twice per week',
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments are confirmed twice per week if all relevant action items have been completed.',
    pizzaTracker:
      "We have received your investment request and will start processing it based on the confirmation schedule for this offering and after we've received your funds from your connected bank account.  Please note that investments in this offering are processed {{cadence}}.",
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.WEEKLY]: {
    cadence: 'once per week',
    email: 'investments@yieldstreet.com',
    tooltip: 'Investments are confirmed weekly if all relevant action items have been completed.',
    pizzaTracker:
      "We have received your investment request and will start processing it based on the confirmation schedule for this offering and after we've received your funds from your connected bank account.  Please note that investments in this offering are processed {{cadence}}.",
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.MONTHLY]: {
    cadence: 'at the end of the month',
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments are confirmed at month end if all relevant action items have been completed',
    pizzaTracker:
      "We have received your investment request and will start processing it based on the confirmation schedule for this offering and after we've received your funds from your connected bank account. Please note that investments in this offering are processed {{cadence}}.",
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.PAUSED]: {
    email: 'investments@yieldstreet.com',
    tooltip:
      'You can still submit an investment request, and we will process your investment as soon as possible if all relevant action items have been completed. Please contact {{email}} with any questions.',
    pizzaTracker:
      'We have received your investment request, but investment processing is currently paused for this offering. We will resume processing investments as soon as possible. If you have any questions in the meantime, please contact {{email}}.',
    investmentDetails:
      'Investment processing is currently paused for this offering We will resume processing investments as soon as possible.',
    contactMessage: 'If you have any questions in the meantime, please contact {{email}}.',
  },
};

const WireCopy = {
  [Cadence.DAILY]: {
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments will be confirmed as soon as all relevant action items have been completed.',
    pizzaTracker:
      "We have received your investment request and will start processing it after we've received your funds.",
    investmentDetails: 'This investment will start processing as soon as we receive your funds.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.BIWEEKLY]: {
    cadence: 'twice per week',
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments are confirmed twice per week if all relevant action items have been completed.',
    pizzaTracker:
      "We have received your investment request and will start processing it based on the confirmation schedule for this offering and after we've received your funds. Please note that investments in this offering are processed {{cadence}}.",
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.WEEKLY]: {
    cadence: 'once per week',
    email: 'investments@yieldstreet.com',
    tooltip: 'Investments are confirmed weekly if all relevant action items have been completed.',
    pizzaTracker:
      "We have received your investment request and will start processing it based on the confirmation schedule for this offering and after we've received your funds.Please note that investments in this offering are processed {{cadence}}.",
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.MONTHLY]: {
    cadence: 'at the end of the month',
    email: 'investments@yieldstreet.com',
    tooltip:
      'Investments are confirmed at month end if all relevant action items have been completed',
    pizzaTracker:
      "We have received your investment request and will start processing it based on the confirmation schedule for this offering and after we've received your funds. Please note that investments in this offering are processed at the {{cadence}}.",
    investmentDetails: 'Investments in this offering are processed {{cadence}}.',
    contactMessage: 'If you have any questions, please contact {{email}}',
  },
  [Cadence.PAUSED]: {
    email: 'investments@yieldstreet.com',
    tooltip:
      'You can still submit an investment request, and we will process your investment as soon as possible if all relevant action items have been completed. Please contact {{email}} with any questions.',
    pizzaTracker:
      'We have received your investment request, but investment processing is currently paused for this offering. We will resume processing investments as soon as possible. If you have any questions in the meantime, please contact {{email}}.',
    investmentDetails:
      'Investment processing is currently paused for this offering We will resume processing investments as soon as possible.',
    contactMessage: 'If you have any questions in the meantime, please contact {{email}}.',
  },
};

export const cadenceCopies: Partial<CadenceCopies> = {
  [PaymentMethodType.WIRE]: WireCopy,
  [PaymentMethodType.ACH]: AchCopy,
  [PaymentMethodType.SYNP]: SynpCopy,
};
